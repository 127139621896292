@import "resources/sass/variables.scss";

.policy {
  ul, ol {
    padding-left: 2em;

    li:not(:last-child) {
      margin-bottom: .5em;
    }
  }
}

h1.about-us-heading {
  font-family: $bold;
}

h2.about-us-heading {
  font-family: $semibold;
}

.about-us-image-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.about-us-image img {
  height: 15rem;
  width: auto;
  vertical-align: bottom;

  @media (min-width: $muism) {
    height: 17rem;
  }

  @media (min-width: $muimd) {
    height: 20rem;
  }

  @media (min-width: $muilg) {
    height: 25rem;
  }

  @media (min-width: $muixl) {
    height: 30rem;
  }
}

.faqs {
  background-color: $white;
  h1 {
    text-align: center;
    font-family: $bold !important;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  h2 {
    font-family: $bold !important;
    margin-bottom: 0.5rem;
    color: $navy;
  }

  h3 {
    font-family: $semibold !important;
    color: $navy;
  }

  .faqs-image-container {
    img {
      width: auto;
      position: absolute;
      bottom: 0;

      @media (min-width: $muism) {
        height: 450px;
      }

      @media (min-width: $muimd) {
        height: 600px;
      }
      
      @media (min-width: $muilg) {
        height: 700px;
      }
    }
  }
}

.faq-title {
  svg {
    fill: $secondary;
    transform: rotate(90deg);
    width: 1.5rem;
    height: 1.5rem;
  }
}

.faq-answer {
  font-family: $semibold !important;
}

.learning-center-wrapper {
  padding: 0 !important;
}

.learning-center-container {
  display: flex !important;
  justify-content: center;
  background-color: $white;
  padding-left: 1rem;
  padding-right: 1rem;

  &:nth-of-type(2n) {
    background-color: $grey;
  }

  h1 {
    font-family: $bold;
    padding-top: 2rem;
  }

  h2 {
    color: $navy;
    padding-top: 1rem;
    font-family: $semibold;

    &.learning-center-subheading {
      padding-top: 0;
    }
  }

  picture {
    align-self: flex-end;

    @media (max-width: $muism) {
      align-self: center;
    }
  }

  img {
    height: 450px;
    width: auto;
    vertical-align: bottom;

    @media (max-width: $muimd) {
      height: 350px;
    }

    @media (max-width: $muism) {
      height: 250px;
    }
  }
}

