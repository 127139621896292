@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.relationship-repeater {
  margin: 0 0 48px;
  width: 100%;
  @media (max-width: 374px) {
    margin: 0 16px 16px;
    width: auto;
  }
  fieldset + & {
    margin-top: 48px;
  }
  + fieldset:not(.relationship-repeater) {
    border-top-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &--empty {
    background-color: $white;
    padding: 28px 32px 32px;
    border-radius: 4px;
    border: 1px solid $greyXLight;
    box-sizing: border-box;
    @media (max-width: 767px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media (max-width: 374px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    &:nth-child(odd) & {
      background-color: $tertiary;
    }
    &:nth-child(even) & {
      background-color: $white;
    }
  }
  &__empty {
    font-family: $semibold;
    font-size: 18px;
    line-height: 25px;
    color: $text;
    margin: 0 0 13px;
    @media (max-width: 920px) {
      font-size: 17px;
      line-height: 24px;
    }
  }
  &__list {
    margin-bottom: 16px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    div {
      margin-right: 10px;
    }
  }
}
