@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.plan-selector-plans-item {
  &__button {
    position: relative;
    height: 64px;
    padding: 0 32px;
    min-width: 100px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid $primary;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    margin: auto 14px 0;
    border-radius: 32px;
    transition: background-color 0.5s;
    @include no-user-select;
    @media (max-width: 767px) {
      height: 50px;
      margin: 0;
    }
    span {
      display: flex;
      align-items: center;
      color: $text;
      font-family: $bold;
      font-size: 20px;
      line-height: 27px;
      width: 100%;
      justify-content: center;
      position: relative;
      transition: color 0.5s;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      display: block;
      box-sizing: border-box;
      border: 1px solid transparent;
      top: -6px;
      left: -6px;
      border-radius: 37px;
      transition: border-color 0.5s;
    }
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      top: -2px;
      right: -2px;
      background-image: url("/static/images/form/icons/button-tick.svg");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
      opacity: 0;
    }
    .plan-selector-plans-item--disabled & {
      pointer-events: none;
      border-color: $disabled;
      @include no-user-select;
      span {
        color: $disabledXDark;
      }
    }
    &:focus {
      background-color: $primaryXLight;
      &::before {
        border-color: $primary;
      }
      &::after {
        border: 3px solid $white;
        top: -5px;
        right: -5px;
      }
      span {
        text-decoration: underline;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryXLight;
      }
    }
    .plan-selector-plans-item--selected & {
      background-color: $primaryLight;
      &::after {
        opacity: 1;
      }
      &:focus {
        background-color: $primaryLight;
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: $primaryLight;
        }
      }
    }
  }
}
