@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.icon {
  flex-shrink: 0;
  &--default {
    *:nth-child(1) {
      stroke: $primary;
      transition: all 0.5s;
    }
    &.icon--disabled {
      *:nth-child(1) {
        stroke: $disabledDarkSolid;
      }
      > * {
        fill: $greyXXDark;
      }
    }
    &.icon--selected {
      *:nth-child(1) {
        stroke: $primary;
      }
    }
    &.icon--invalid {
      *:nth-child(1) {
        stroke: $alert;
      }
    }
    :global(.platform-desktop) & {
      &.icon--hover {
        *:nth-child(1) {
          stroke: $primary;
        }
      }
      &.icon--invalid {
        &.icon--hover {
          *:nth-child(1) {
            stroke: $alertDark;
          }
        }
      }
    }
  }
  &--male {
    *:nth-child(1),
    *:nth-child(2),
    *:nth-child(3),
    *:nth-child(4),
    *:nth-child(5),
    *:nth-child(6) {
      fill: transparent;
      transition: all 0.5s;
    }
    *:nth-child(7) {
      stroke: $primaryXDark;
      transition: all 0.5s;
    }
    *:nth-child(8) {
      fill: $primaryXDark;
      transition: all 0.5s;
    }
    &.icon--disabled {
      *:nth-child(7) {
        stroke: $disabledDarkSolid;
      }
      *:nth-child(8) {
        fill: $disabledDarkSolid;
      }
    }
    &.icon--selected {
      *:nth-child(1) {
        fill: $primary;
      }
      *:nth-child(2),
      *:nth-child(3),
      *:nth-child(6) {
        fill: $tertiaryLight;
      }
      *:nth-child(4),
      *:nth-child(5) {
        fill: $primaryLight;
      }
      *:nth-child(7) {
        stroke: $primaryXDark;
      }
      *:nth-child(8) {
        fill: $primaryXDark;
      }
    }
    &.icon--invalid {
      *:nth-child(7) {
        stroke: $alert;
      }
      *:nth-child(8) {
        fill: $alert;
      }
    }
    :global(.platform-desktop) & {
      &.icon--hover:not(.icon--selected) {
        *:nth-child(1),
        *:nth-child(2),
        *:nth-child(3),
        *:nth-child(4),
        *:nth-child(5),
        *:nth-child(6) {
          fill: transparent;
        }
      }
      &.icon--invalid {
        &.icon--hover {
          *:nth-child(7) {
            stroke: $alertDark;
          }
          *:nth-child(8) {
            fill: $alertDark;
          }
        }
      }
    }
  }
  &--female {
    *:nth-child(1),
    *:nth-child(2),
    *:nth-child(4),
    *:nth-child(5),
    *:nth-child(6) {
      fill: transparent;
      transition: all 0.5s;
    }
    *:nth-child(3) {
      stroke: $primaryXDark;
      transition: all 0.5s;
    }
    *:nth-child(7) {
      fill: $primaryXDark;
      transition: all 0.5s;
    }
    &.icon--disabled {
      *:nth-child(3) {
        stroke: $disabledDarkSolid;
      }
      *:nth-child(7) {
        fill: $disabledDarkSolid;
      }
    }
    &.icon--selected {
      *:nth-child(1) {
        fill: $primary;
      }
      *:nth-child(5),
      *:nth-child(6) {
        fill: $tertiaryLight;
      }
      *:nth-child(2),
      *:nth-child(4) {
        fill: $primaryLight;
      }
      *:nth-child(3) {
        stroke: $primaryXDark;
      }
      *:nth-child(7) {
        fill: $primaryXDark;
      }
    }
    &.icon--invalid {
      *:nth-child(3) {
        stroke: $alert;
      }
      *:nth-child(7) {
        fill: $alert;
      }
    }
    :global(.platform-desktop) & {
      &.icon--hover:not(.icon--selected) {
        *:nth-child(1),
        *:nth-child(2),
        *:nth-child(4),
        *:nth-child(5),
        *:nth-child(6) {
          fill: transparent;
        }
      }
      &.icon--invalid {
        &.icon--hover {
          *:nth-child(3) {
            stroke: $alertDark;
          }
          *:nth-child(7) {
            fill: $alertDark;
          }
        }
      }
    }
  }
  &--female-and-male {
    *:nth-child(1),
    *:nth-child(6),
    *:nth-child(2),
    *:nth-child(10),
    *:nth-child(11),
    *:nth-child(3),
    *:nth-child(4),
    *:nth-child(7),
    *:nth-child(9) {
      fill: transparent;
      transition: all 0.5s;
    }
    *:nth-child(8) {
      stroke: $primaryXDark;
      transition: all 0.5s;
    }
    *:nth-child(5),
    *:nth-child(12) {
      fill: $primaryXDark;
      transition: all 0.5s;
    }
    &.icon--disabled {
      *:nth-child(1),
      *:nth-child(6),
      *:nth-child(2),
      *:nth-child(10),
      *:nth-child(11),
      *:nth-child(3),
      *:nth-child(4),
      *:nth-child(7),
      *:nth-child(9) {
        fill: transparent;
      }
      *:nth-child(8) {
        stroke: $disabledDarkSolid;
      }
      *:nth-child(5),
      *:nth-child(12) {
        fill: $disabledDarkSolid;
      }
    }
    &.icon--selected {
      *:nth-child(1),
      *:nth-child(6) {
        fill: $primary;
      }
      *:nth-child(2),
      *:nth-child(10),
      *:nth-child(11) {
        fill: $tertiaryLight;
      }
      *:nth-child(3),
      *:nth-child(4),
      *:nth-child(7),
      *:nth-child(9) {
        fill: $primaryLight;
      }
      *:nth-child(8) {
        stroke: $primaryXDark;
      }
      *:nth-child(5),
      *:nth-child(12) {
        fill: $primaryXDark;
      }
    }
    &.icon--invalid {
      *:nth-child(8) {
        stroke: $alert;
      }
      *:nth-child(5),
      *:nth-child(12) {
        fill: $alert;
      }
    }
    :global(.platform-desktop) & {
      &.icon--hover:not(.icon--selected) {
        *:nth-child(1),
        *:nth-child(6),
        *:nth-child(2),
        *:nth-child(10),
        *:nth-child(11),
        *:nth-child(3),
        *:nth-child(4),
        *:nth-child(7),
        *:nth-child(9) {
          fill: transparent;
        }
      }
      &.icon--invalid {
        &.icon--hover {
          *:nth-child(8) {
            stroke: $alertDark;
          }
          *:nth-child(5),
          *:nth-child(12) {
            fill: $alertDark;
          }
        }
      }
    }
  }
  &--male-and-female {
    *:nth-child(1),
    *:nth-child(3),
    *:nth-child(2),
    *:nth-child(6),
    *:nth-child(7),
    *:nth-child(13),
    *:nth-child(4),
    *:nth-child(5),
    *:nth-child(8),
    *:nth-child(9),
    *:nth-child(11) {
      fill: transparent;
      transition: all 0.5s;
    }
    *:nth-child(10) {
      stroke: $primaryXDark;
      transition: all 0.5s;
    }
    *:nth-child(12),
    *:nth-child(14) {
      fill: $primaryXDark;
      transition: all 0.5s;
    }
    &.icon--disabled {
      *:nth-child(10) {
        stroke: $disabledDarkSolid;
      }
      *:nth-child(12),
      *:nth-child(14) {
        fill: $disabledDarkSolid;
      }
    }
    &.icon--selected {
      *:nth-child(1),
      *:nth-child(3) {
        fill: $primary;
      }
      *:nth-child(2),
      *:nth-child(6),
      *:nth-child(7),
      *:nth-child(13) {
        fill: $primaryLight;
      }
      *:nth-child(4),
      *:nth-child(5),
      *:nth-child(8),
      *:nth-child(9),
      *:nth-child(11) {
        fill: $tertiaryLight;
      }
      *:nth-child(10) {
        stroke: $primaryXDark;
      }
      *:nth-child(12),
      *:nth-child(14) {
        fill: $primaryXDark;
      }
    }
    &.icon--invalid {
      *:nth-child(10) {
        stroke: $alert;
      }
      *:nth-child(12),
      *:nth-child(14) {
        fill: $alert;
      }
    }
    :global(.platform-desktop) & {
      &.icon--hover:not(.icon--selected) {
        *:nth-child(1),
        *:nth-child(3),
        *:nth-child(2),
        *:nth-child(6),
        *:nth-child(7),
        *:nth-child(13),
        *:nth-child(4),
        *:nth-child(5),
        *:nth-child(8),
        *:nth-child(9),
        *:nth-child(11) {
          fill: transparent;
        }
      }
      &.icon--invalid {
        &.icon--hover {
          *:nth-child(10) {
            stroke: $alertDark;
          }
          *:nth-child(12),
          *:nth-child(14) {
            fill: $alertDark;
          }
        }
      }
    }
  }
}
