@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.collapsable-card {
  position: relative;
  background-color: $white;
  padding: 20px 32px 32px;
  border: 1px solid $greyXLight;
  @include no-user-select;
  @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
    padding: 16px 24px 24px;
  }
  @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
    padding: 16px 20px 20px;
  }
  @media (max-width: 767px) {
    margin: 0 -16px 8px;
  }
  @media (max-width: 374px) {
    margin: 0 -20px 8px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &--primary {
    background-color: $primary;
    color: $white;

    .collapsable-card__title {
      color: $white;
    }

    .collapsable-card__toggle {
      svg * {
        fill: $white;
      }
      
      &:hover {
        svg * {
          fill: $greyXLight !important;
        }
        
      }
    }
    
  }

  &__row {
    display: flex;
    align-items: center;
    margin: 0 0 22px;
    @media (max-width: 374px) {
      padding: 0 20px;
    }
    &:last-child {
      margin: 0;
    }
    &:only-child {
      margin-bottom: -12px;
    }
    > div[role="button"]:not(:last-child) {
      margin-right: auto;
      padding: 0 22px;
      font-family: $semibold;
    }
  }
  &__title {
    font-size: 24px;
    line-height: 33px;
    font-family: $title;
    color: $text;
    padding-right: 24px;
    @include no-user-select;
    @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
      font-size: 20px;
      line-height: 27px;
    }
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  &__notification {
    position: absolute;
    height: 26px;
    width: 26px;
    background-color: $orange;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-family: $bold;
    font-size: 15px;
    line-height: 26px;
    margin: 0px;
    right: 82px;
    top: 50%;
    transform: translateY(-50%);
  }
  &__toggle {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid $greyLight;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: background-color 0.5s;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      height: 32px;
      width: 32px;
    }
    svg {
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        width: 14px;
        height: 14px;
      }
      * {
        fill: $primary;
        transition: all 0.5s;
      }
    }
    &:focus {
      background-color: transparentize($greyLight, 0.9);
      &::before {
        content: "";
        position: absolute;
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        top: -6px;
        left: -6px;
        box-sizing: border-box;
        border-style: solid;
        border-width: 1px;
        border-color: $greyLight;
        border-radius: 50%;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: transparentize($greyLight, 0.9);
        svg * {
          fill: $primaryDark;
        }
      }
    }
  }
}
