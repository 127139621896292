@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.list {
  padding: 0 !important;
  overflow: auto;
  max-height: 300px;

  li {
    list-style-type: none;
    padding: 0 20px;
    cursor: pointer;

    &:hover {
      background-color: $greyXXLight;
    }
    
    &:not(:last-child) .item {
      border-bottom: solid 1px $greyXXLight;
    }
    
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 14px 0;
      
      .name {
        color: $greyXXXDark;
      }
      
      .dob {
        margin-left: 30px;
        font-size: small;
        color: $greyXXDark;
      }
    }
  }
}
