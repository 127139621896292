@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard-products-item-history-item {
  display: flex;
  background-color: $white;
  border-bottom: 1px solid $tertiaryXDark;
  box-sizing: border-box;
  &:last-child {
    border-bottom: 0;
  }
  &:nth-child(even) {
    background-color: $tertiaryDark;
  }
  &__col {
    padding: 15px 20px;
    text-align: left;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1210px) {
      padding: 11px 12px;
    }
    &:nth-child(1) {
      width: 40%;
      padding-left: 23px;
      @media (max-width: 1210px) {
        padding-left: 15px;
      }
    }
    &:nth-child(2) {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
  &__date {
    font-size: 15px;
    line-height: 20px;
    font-family: $bold;
    color: $text;
    margin: 0 0 2px;
    @media (max-width: 1210px) {
      font-size: 14px;
    }
  }
  &__action {
    font-size: 15px;
    line-height: 20px;
    font-family: $regular;
    color: $text;
    margin: 0 0 2px;
    @media (max-width: 1210px) {
      font-size: 14px;
    }
  }
}
