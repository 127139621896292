@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.selector-option {
  margin: 16px 8px 0;
  flex-basis: calc(50% - 8px);
  max-width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: 1px solid $greyXLight;
  padding: 0 16px 24px;
  box-sizing: border-box;
  position: relative;
  flex-grow: 1;
  border-radius: 4px;
  @media (max-width: 767px) {
    order: 2;
    flex-basis: 100%;
    max-width: 100%;
    margin: 0 0 24px;
    padding: 0 24px 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &--recommended {
    border-top-color: transparent;
    margin-top: -2px;
    @media (max-width: 767px) {
      order: 1;
    }
    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: 1px;
      height: 4px;
      width: calc(100% - 2px);
      background-color: $white;
      z-index: 1;
    }
    .selector-option::before {
      opacity: 1 !important;
    }
    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      pointer-events: none;
      box-sizing: border-box;
      border: 2px solid $primary;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      z-index: 0;
    }
  }
  &:nth-last-child(3n + 0):first-child,
  &:nth-last-child(3n + 3):first-child ~ #{&} {
    &:last-child,
    &:first-child {
      .selector-option::before {
        content: "";
        height: 1px;
        width: 2px;
        background-color: $primary;
        position: absolute;
        top: -2px;
        opacity: 0;
      }
    }
    &:first-child {
      .selector-option::before {
        left: -1px;
      }
    }
    &:last-child {
      .selector-option::before {
        right: -1px;
      }
    }
  }
  &__info {
    height: 175px;
    padding: 27px 0 0;
    box-sizing: border-box;
    @media (max-width: 767px) {
      height: auto;
      margin-bottom: 28px;
    }
    .selector-option--recommended & {
      height: 200px;
      padding: 21px 0 0;
      @media (max-width: 767px) {
        height: auto;
        margin-bottom: 28px;
        padding: 0;
      }

      &.short--title {
        margin-top: 19px;
      }
    }
    &.short--title {
      height: 115px !important;
    }
  }
  &__title {
    color: $text;
    font-family: $semibold;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    padding-top: 12px;
    @include no-user-select;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 27px;
    }
    .selector-option--recommended & {
      font-size: 22px;
      line-height: 30px;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 27px;
      }
    }
    .selector-option--disabled & {
      color: $disabledDark;
    }
  }
  &__price {
    font-family: $bold;
    text-align: center;
    font-size: 28px;
    line-height: 38px;
    margin: 0 0 8px;
    @include no-user-select;
    .selector-option--recommended & {
      font-size: 36px;
      line-height: 49px;
      margin: 0 0 3px;
      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 38px;
      }
    }
    &--neutral {
      color: $primary;
    }
    &--positive {
      color: $successDark;
    }
    &--negative {
      color: $alertDark;
    }
    .selector-option--disabled & {
      color: $disabledDark;
    }
  }
  &__description-in-body {
    margin-top: -20px;
    margin-bottom: 40px;
    padding: 0 20px;
  }
  &__discount {
    font-family: $regular;
    color: $greyXXDark;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    @include no-user-select;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
    .selector-option--recommended & {
      font-size: 16px;
      line-height: 22px;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .selector-option--disabled & {
      color: $disabledDark;
    }
  }
  &__tooltip {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;

    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
      top: 32px;
    }
    .selector-option--recommended & {
      @media (max-width: 767px) {
        top: 4px;
      }
    }
    .selector-option--disabled & {
      pointer-events: none;
      *:nth-child(2),
      *:nth-child(3) {
        fill: $disabled;
      }
      *:nth-child(1) {
        fill: transparent;
      }
    }
    *:nth-child(2),
    *:nth-child(3) {
      fill: $primary;
      transition: all 0.5s;
    }
    *:nth-child(1) {
      fill: transparent;
      transition: all 0.5s;
    }
    &:focus {
      *:nth-child(2),
      *:nth-child(3) {
        fill: $primaryXDark;
      }
      *:nth-child(1) {
        fill: transparent;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        *:nth-child(2),
        *:nth-child(3) {
          fill: $primaryXDark;
        }
        *:nth-child(1) {
          fill: transparent;
        }
      }
    }
  }
}
