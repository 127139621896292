@import "resources/sass/variables.scss";
@import "../../styles/grid.module.scss";
@import "../../index.scss";

.no-lawyer-elimination {
  position: relative;
  background-color: $navy;
  padding: 1.5rem;

  & > div {
    display: flex;
  }

  img {
    position: absolute;
    width: auto;
    height: 400px;
    bottom: 0;
  }

  &__info {
    padding-left: 20rem;
  }

  h2 {
    color: $white;
    font-family: $title;
    font-size: 2rem;
  }

  p {
    color: $secondary;
    font-size: 1.25rem;
    font-family: $semibold;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    & > li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > span {
        font-family: $bold;
        color: $secondary;
        font-size: 4rem;
        line-height: 4rem;
      }

      & > h3 {
        font-family: $bold;
        padding: 0.75rem 0;
        color: $white;
        font-size: 1.75rem;
      }

      & > p {
        color: $white;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: $xl) {
    display: none;
  }
}