@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.pagination-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0px 0px 0px;
  @media (max-width: 1240px) {
    margin-top: 24px;
  }
  @media (max-width: 374px) {
    padding: 0 20px;
  }
  &__text {
    font-family: $semibold;
    font-size: 14px;
    line-height: 20px;
    color: $greyXXDark;
    margin: 20px 0px -6px 0px;

    &--white {
      color: $white;
    }
  }
  &__pages {
    margin: 0 -4px;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  &__ellipses {
    font-family: $semibold;
    font-size: 18px;
    color: $primary;
    margin: 0 3px;
  }
  &__next,
  &__previous {
    display: flex;
    align-items: center;
    svg {
      height: auto;
    }
    > div[role="button"] {
      height: 28px;
      width: 28px;
      border-width: 2px !important;
    }

    &--white {
      > div[role="button"] {
        background-color: $grey !important;
        border-color: $grey !important;

        &:hover {
          background-color: $white !important;
          border-color: $white !important;
        }
      }

      &.pagination-control__next--disabled, &.pagination-control__previous--disabled {
        opacity: 0.6;
      }
    }
  }
  &__next {
    padding-left: 24px;
  }
  &__previous {
    padding-right: 24px;
  }
  &__page {
    &--active {
      div[role="button"] {
        background-color: $primaryLight;
        height: 40px;
        width: 40px;
        padding: 0;
        margin: 0 4px;
        color: $primary;
        :global(.platform-desktop) & {
          &:hover {
            background-color: $primaryLight;
          }
        }
      }
    }
    &--white {
      div[role="button"] {
        background-color: $grey;
        border-color: $grey;
        color: $navy !important;
        opacity: 0.5;

        &:hover {
          background-color: $white;
          border-color: $white;
          color: $navy !important;
          opacity: 0.7;
        }
      }

      &.pagination-control__page--active {
        div[role="button"] {
          opacity: 1 !important;

          &:hover {
            opacity: 1 !important;
          }
        }
      }
    }
    div[role="button"] {
      height: 40px;
      width: 40px;
      padding: 0;
      margin: 0 4px;
      color: $primary;
      :global(.platform-desktop) & {
        &:hover {
          color: $primaryDark;
        }
      }
    }
  }
}
