@import "resources/sass/variables.scss";

.medicaid-lead-summary-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: $sm) {
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

.medicaid-lead-summary-main-action {
  margin-top: 20px;
}

.medicaid-lead-summary-back-button {
  margin-top: 20px;
}