@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.other_input {

  &--large_parent {
    max-width: 75%;
  }

  &--fullwidth_parent {
    max-width: 50%;
  }

  &__label {
    padding-top: 1em;
  }
}