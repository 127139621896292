@import "resources/sass/variables.scss";

.landing__container {
  width: 100%;
}

.landing__container-100 {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1220px;

  &--narrow {
    max-width: 1080px;
  }

  &--ultra-narrow {
    max-width: 850px;
  }
}