@import "resources/sass/variables.scss";

.progress-archive-warning {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  background: #fefcf7;
  border: 2px dashed $orange;

  @media (max-width: $md) {
    order: 2;
  }
}