@import "resources/sass/variables.scss";

.consumer-medicaid-lead-item-status {
  font-size: 24px;
  line-height: 33px;
  font-family: $bold;
  color: $primary;
  margin: 0 0 15px;
  @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
    font-size: 20px;
    line-height: 26px;
  }
}
