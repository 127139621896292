@import "resources/sass/variables.scss";

.header-account-modal {
  visibility: hidden;
  width: 282px;
  position: absolute;
  top: 63px;
  right: 39px;
  z-index: 5;
  transform: scale(0);
  -ms-transform: scale(0);
  opacity: 0;
  transform-origin: calc(100% - 20px) 0;
  -ms-transform-origin: calc(100% - 20px) 0;
  transition: all 0.2s cubic-bezier(0.55, 0, 0.55, 0.2);
  &--open {
    visibility: visible;
    transform: scale(1);
    -ms-transform: scale(1);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
  &__arrow {
    width: 19px;
    height: 9px;
    top: -6px;
    position: relative;
    transform: translateZ(0);
    &-container {
      position: absolute;
      width: 19px;
      height: 8px;
      top: -7px;
      right: 10px;
      z-index: 1;
      overflow: hidden;
    }
  }
  &__container {
    background-color: $white;
    border: 1px solid $greyXLight;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.3);
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
  }
  &__information {
    background-color: $secondaryLight;
    border-bottom: 1px solid $greyXLight;
    padding: 19px 16px 21px 90px;
    font-size: 18px;
    position: relative;
    &-avatar {
      width: 58px;
      height: 58px;
      top: 50%;
      left: 16px;
      margin-top: -29px;
      position: absolute;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;
      &-img {
        background-image: url("/static/images/avatar/placeholder-avatar.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $primaryDark;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        height: 58px;
        width: 58px;
        border: 3px solid $greyXXLight;
        box-sizing: border-box;
        transition: all ease-out 0.075s;
        &::before {
          content: "Edit";
          visibility: hidden;
          font-size: 16px;
          line-height: 20px;
          color: $white;
          font-family: $regular;
          padding-top: 2px;
          position: relative;
          z-index: 1;
          transition: all ease-out 0.075s;
        }
        &::after {
          content: "";
          position: absolute;
          visibility: hidden;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          transition: all ease-out 0.075s;
        }
      }
      &-upload {
        position: absolute;
        top: -22px;
        left: -16px;
        width: 280px;
        height: 93px;
        border: 0;
        border: 0;
        outline: 0;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        :global(.platform-mobile) & {
          + .header-account-modal__information-avatar-img {
            transition: none;
            &::before,
            &::after {
              transition: none;
            }
          }
        }
        &:focus {
          + .header-account-modal__information-avatar-img {
            border-color: $primary;
            &::before {
              visibility: visible;
            }
            &::after {
              background-color: rgba(51, 51, 51, 0.7);
              visibility: visible;
            }
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            + .header-account-modal__information-avatar-img {
              border-color: $greyXXLight;
              &::before {
                visibility: visible;
              }
              &::after {
                background-color: rgba(51, 51, 51, 0.7);
                visibility: visible;
              }
            }
          }
          &:active {
            + .header-account-modal__information-avatar-img {
              border-color: $primaryXDark;
              &::before {
                visibility: visible;
              }
              &::after {
                background-color: rgba(51, 51, 51, 0.7);
                visibility: visible;
              }
            }
          }
        }
        :global(.platform-mobile) & {
          &:active {
            + .header-account-modal__information-avatar-img {
              border-color: $primaryXDark;
              &::before {
                visibility: visible;
              }
              &::after {
                background-color: rgba(51, 51, 51, 0.7);
                visibility: visible;
              }
            }
          }
        }
      }
    }
    &-name {
      line-height: 25px;
      font-family: $bold;
    }
    &-position {
      line-height: 22px;
      font-family: $regular;
    }
  }
  &__list {
    padding-bottom: 5px;
  }
  &__logout {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 16px;
      height: 1px;
      width: calc(100% - 32px);
      background-color: $greyXLight;
    }
  }
  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 24px;
    height: 24px;
    width: 24px;
    position: relative;
    &::after,
    &:before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &--user {
      &::after {
        background-image: url("/static/images/icons/user.svg");
      }
    }
    &--message {
      &::after {
        background-image: url("/static/images/icons/message.svg");
      }
    }
    &--meeting {
      &::after {
        background-image: url("/static/images/icons/meeting.svg");
      }
    }
    &--users {
      &::after {
        background-image: url("/static/images/icons/users.svg");
      }
    }
    &--logout {
      &::after {
        background-image: url("/static/images/icons/logout.svg");
      }
    }
  }
  &__link {
    font-size: 18px;
    color: $text;
    font-family: $regular;
    position: relative;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease-out 0.075s;
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover, &:active {
        background-color: $primaryLight;
        text-decoration: none;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $primaryLight;
        text-decoration: none;
      }
    }
    &-text {
      padding-left: 12px;
      flex-basis: calc(100% - 36px);
      max-width: calc(100% - 36px);
    }
    &-alert {
      font-family: $bold;
      font-size: 16px;
      line-height: 20px;
      color: $white;
      &-container {
        flex-grow: 0;
        flex-shrink: 0;
        height: 20px;
        width: 30px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        background-color: $primary;
      }
    }
  }
  &__button {
    font-size: 18px;
    color: $alert;
    font-family: $regular;
    position: relative;
    padding: 18px 16px;
    display: flex;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    transition: all ease-out 0.075s;
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $alertPastelXXLight;
        text-decoration: none;
      }
      &:active {
        background-color: $alertPastelXLight;
        text-decoration: none;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $alertPastelXLight;
        text-decoration: none;
      }
    }
    &-text {
      padding-left: 12px;
      flex-basis: calc(100% - 36px);
      max-width: calc(100% - 36px);
    }
  }
}
