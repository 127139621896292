@import "resources/sass/variables.scss";

.questionnaire {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  &__title {
    flex-basis: 100%;
    @media (max-width: 767px) {
      order: 3;
    }
  }
  &__content {
    flex-basis: calc(100% - #{map-get($sidebar-left-margin, "default") + map-get($sidebar-width, "default")});
    max-width: calc(100% - #{map-get($sidebar-left-margin, "default") + map-get($sidebar-width, "default")});
    @media (max-width: 1240px) {
      flex-basis: calc(100% - #{map-get($sidebar-left-margin, 1240) + map-get($sidebar-width, 1240)});
      max-width: calc(100% - #{map-get($sidebar-left-margin, 1240) + map-get($sidebar-width, 1240)});
    }
    @media (max-width: 920px) {
      flex-basis: calc(100% - #{map-get($sidebar-left-margin, 920) + map-get($sidebar-width, 920)});
      max-width: calc(100% - #{map-get($sidebar-left-margin, 920) + map-get($sidebar-width, 920)});
    }
    @media (max-width: 767px) {
      flex-basis: 100%;
      max-width: initial;
      order: 3;
      width: calc(100%);
      position: relative;
    }
    @media (max-width: 374px) {
      width: calc(100% + 40px);
      left: -20px;
    }
    &_full_width {
      width: 100%;
      max-width: 100%;
    }
  }
}