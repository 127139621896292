@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.button {
  height: 40px;
  padding: 0 32px;
  box-sizing: border-box;
  @include no-user-select;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  svg path {
    transition: all ease-out 0.075s;
  }
  &--in--progress {
    background-color: #2df788!important;
    border: #0dd768!important;
    color: #2b0071!important;
  }
  &--lawyer--action--required {
    background-color: #09093a!important;
    border: black!important;
    color: white;
  }
  &--client--action--required {
    background-color: #09093a!important;
    border: black!important;
    color: white;
  }
  &--completed {
    background-color: #e1f5ff!important;
    border: #c1d5d5!important;
    color: black!important;
  }
  &--rejected {
    background-color: #181818!important;
    color: white!important;
  }
  &--small {
    height: 30px;
  }
  &--icon {
    width: 40px;
    padding: 0 8px !important;
    
    &--bordered {
      svg {
        width: 26px;
        height: 26px;
        padding: 8px;
        border-radius: 50%;
        border: 1px solid $greyLight;
        box-sizing: border-box;
        background-color: transparent;
        transition: background-color 0.5s;
      }
    }
  }
  &--icon-label {
    padding: 0 24px;
    svg {
      margin-right: 11px;
      margin-left: -3px;
    }
  }
  &:focus {
    text-decoration: underline;
    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      top: -6px;
      left: -6px;
      box-sizing: border-box;
      border-radius: 12px;
      border-style: solid;
      border-width: 1px;
      border-color: transparent;
      transition: all ease-out 0.075s;
    }
  }
  :global(.platform-desktop) & {
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
  :global(.platform-mobile) & {
    &:active {
      text-decoration: none;
    }
  }
  &--primary {
    border: 2px solid $primary;
    background-color: $primary;
    color: $white;
    font-family: $bold;
    font-size: 18px;
    transition: all ease-out 0.075s;
    @media (max-width: 920px) {
      font-size: 16px;
    }
    svg path {
      fill: $white;
    }
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      border-color: $primaryDark;
      background-color: $primaryDark;
      &::before {
        border-color: $primaryDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $primaryDark;
        background-color: $primaryDark;
      }
      &:active {
        border-color: $primary;
        background-color: $primary;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        border-color: $primary;
        background-color: $primary;
      }
    }
    &.button--positive {
      color: $black;
      border-color: $successDark;
      background-color: $success;
      svg path {
        fill: $black;
      }
      &:focus {
        border-color: $successDark;
        background-color: $successDark;
        &::before {
          border-color: $successDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $successDark;
          background-color: $successDark;
        }
        &:active {
          border-color: $successDark;
          background-color: $success;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          border-color: $successDark;
          background-color: $success;
        }
      }
    }
    &.button--negative {
      background-color: $alert;
      border-color: $alert;
      &:focus {
        border-color: $alertDark;
        background-color: $alertDark;
        &::before {
          border-color: $alertDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $alertDark;
          background-color: $alertDark;
        }
        &:active {
          border-color: $alertXDark;
          background-color: $alertXDark;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          border-color: $alertXDark;
          background-color: $alertXDark;
        }
      }
    }
    &.button--cta {
      background-color: $secondary;
      border-radius: 9999px;
      border-width: 1px;
      text-transform: uppercase;
      color: $primaryXDark;
      font-size: 0.9rem;
      border-color: white;
      transition: background-color .1s, border-color .1s;

      &:hover {
        border-color: $secondary;
        background-color: white;
      }
      &:active {
        border-color: $secondaryDark;
        background-color: $white;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &.button--disabled {
        cursor: default;
        pointer-events: none;
        border-color: $disabled;
        background-color: $disabled;
        color: $disabledDark;
        &:focus {
          text-decoration: none;
        }
        svg path {
          fill: $greyXXDark;
        }
      }
    }
  }
  &--secondary {
    border: 2px solid $primary;
    color: $primary;
    font-family: $bold;
    font-size: 18px;
    transition: all ease-out 0.075s;
    @media (max-width: 920px) {
      font-size: 16px;
    }
    svg path {
      fill: $primary;
    }
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      color: $primaryDark;
      border-color: $primaryDark;
      background-color: $primaryXLight;
      svg path {
        fill: $primaryDark;
      }
      &::before {
        border-color: $primaryDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $primaryDark;
        border-color: $primaryDark;
        background-color: $primaryXLight;
        svg path {
          fill: $primaryDark;
        }
      }
      &:active {
        color: $primaryXDark;
        border-color: $primaryXDark;
        background-color: transparent;
        svg path {
          fill: $primaryXDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $primaryXDark;
        border-color: $primaryXDark;
        background-color: transparent;
        svg path {
          fill: $primaryXDark;
        }
      }
    }
    &.button--positive {
      border-color: $success;
      color: $success;
      svg path {
        fill: $success;
      }
      &:focus {
        color: $successDark;
        border-color: $successDark;
        background-color: $success;
        svg path {
          fill: $successDark;
        }
        &::before {
          border-color: $successDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $successDark;
          border-color: $successDark;
          background-color: $success;
          svg path {
            fill: $successDark;
          }
        }
        &:active {
          color: $successXDark;
          border-color: $successXDark;
          background-color: transparent;
          svg path {
            fill: $successXDark;
          }
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $successXDark;
          border-color: $successXDark;
          background-color: transparent;
          svg path {
            fill: $successXDark;
          }
        }
      }
    }
    &.button--negative {
      border-color: $alert;
      color: $alert;
      svg path {
        fill: $alert;
      }
      &:focus {
        color: $alertDark;
        border-color: $alertDark;
        background-color: $alertPastelLight;
        svg path {
          fill: $alertDark;
        }
        &::before {
          border-color: $alertDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $alertDark;
          border-color: $alertDark;
          background-color: $alertPastelLight;
          svg path {
            fill: $alertDark;
          }
        }
        &:active {
          color: $alertXDark;
          border-color: $alertXDark;
          background-color: transparent;
          svg path {
            fill: $alertXDark;
          }
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $alertXDark;
          border-color: $alertXDark;
          background-color: transparent;
          svg path {
            fill: $alertXDark;
          }
        }
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &.button--disabled {
        cursor: default;
        pointer-events: none;
        border: 1px solid $grey;
        background-color: transparent;
        color: $disabledDark;
        svg path {
          fill: $greyXXDark;
        }
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
  &--neutral {
    border: 2px solid rgba($text, 0.2);
    color: $text;
    font-family: $bold;
    background-color: transparent;
    font-size: 18px;
    transition: all ease-out 0.075s;
    @media (max-width: 920px) {
      font-size: 16px;
    }
    svg path {
      fill: $text;
    }
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      border-color: rgba($text, 0.4);
      background-color: rgba($text, 0.1);
      svg path {
        fill: rgba($text, 0.4);
      }
      &::before {
        border-color: $primaryDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: rgba($text, 0.4);
        background-color: rgba($text, 0.1);
        svg path {
          fill: rgba($text, 0.4);
        }
      }
      &:active {
        border-color: $text;
        background-color: transparent;
        svg path {
          fill: $text;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        border-color: $text;
        background-color: transparent;
        svg path {
          fill: $text;
        }
      }
    }
  }
  span {
    text-align: center;
    white-space: nowrap;
  }

  &__tooltip {
    font-size: 10pt;
    line-height: 1.5em;
    display: block;
    text-align: center;
  }
}
