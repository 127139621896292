@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard-products-item {
  padding: 32px;
  background-color: $tertiary;
  border: 1px solid $tertiaryXDark;
  margin: 0 0 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  @include no-user-select;
  @media (max-width: 1240px) {
    border-radius: 0;
  }
  @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
    padding: 24px;
  }
  @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
    padding: 20px;
  }
  + div[role="button"] {
    margin-top: 8px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  > div[role="button"] {
    height: 50px;
  }
  &__header {
    align-items: flex-start !important;
  }
  &__state {
    width: 20px;
    height: 20px;
    padding: 1em;
    border-radius: 50%;
    background-color: $tertiaryLight;
    font-family: $bold;
    display: flex;
    justify-content: center;
    color: $disabledXDark;
    margin-left: 16px;
  }
  &__title {
    align-self: center;
    flex: 1;
    font-size: 20px;
    line-height: 27px;
    font-family: $title;
    color: $primaryDark;
    @include user-select;
    @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
      font-size: 18px;
      line-height: 25px;
    }
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__subtitle {
    color: $greyXXXDark;

    &__state {
      font-family: $bold;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    &:first-child {
      margin-bottom: 24px;
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        margin-bottom: 16px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        margin-bottom: 16px;
      }
    }
    &:nth-child(2) {
      @media (max-width: 1240px) {
        flex-wrap: wrap;
      }
    }
  }
  &__col {
    padding-right: 12px;
    box-sizing: border-box;
    @media (max-width: 1040px) {
      padding-right: 20px;
    }
    &:nth-child(1) {
      flex-grow: 1;
      @media (max-width: 920px) and (min-width: 768px), (max-width: 540px) {
        flex-basis: 100%;
      }
    }
    &:nth-child(2) {
      flex-basis: 33.33%;
      max-width: 33.33%;
      @media (max-width: 1040px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        flex-basis: auto;
        max-width: initial;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        flex-basis: 50%;
        max-width: 50%;
      }
      @media (max-width: 350px) {
        flex-grow: 1;
        flex-basis: auto;
        max-width: initial;
      }
    }
    &:nth-child(3) {
      flex-basis: 16.667%;
      max-width: 16.667%;
      @media (max-width: 1040px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        flex-basis: auto;
        max-width: initial;
        padding-right: 0;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        flex-basis: 50%;
        max-width: 50%;
      }
      @media (max-width: 350px) {
        flex-basis: auto;
        max-width: initial;
      }
    }
    &:nth-child(2) > &__value,
    &:nth-child(3) > &__value {
      color: $orange;
      .client-dashboard-products-item--complete & {
        color: $successDark;
      }
    }
    &__heading {
      font-size: 14px;
      line-height: 19px;
      font-family: $bold;
      color: $text;
      margin: 0 0 4px;
      @include no-user-select;
      @media (max-width: 1240px) {
        margin: 0 0 1px;
      }
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        font-size: 13px;
        line-height: 18px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
    &__value {
      font-size: 24px;
      line-height: 33px;
      font-family: $bold;
      color: $primary;
      margin: 0 0 15px;
      @include no-user-select;
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        font-size: 20px;
        line-height: 28px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  &__icon {
    height: 64px;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 16px 0 0;
    flex-shrink: 0;
    @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
      height: 48px;
      width: 48px;
    }
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      height: 40px;
      width: 40px;
      margin: 0 12px 0 0;
    }
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      top: 0;
      left: 0;
      background-color: $primaryLight;
      z-index: 0;
      opacity: 0.4;
    }
    svg {
      position: relative;
      z-index: 1;
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        height: 26px;
        width: 29px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        height: 22px;
        width: 25px;
      }
      > * {
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          fill: $primary;
        }
        &:nth-child(1),
        &:nth-child(3) {
          fill: $primaryLight;
          opacity: 0.4;
        }
        &:nth-child(2) {
          fill: $primaryLight;
          opacity: 0.4;
        }
      }
    }
    .client-dashboard-products-item--complete & {
      &::before {
        background-color: $secondary;
        opacity: 1;
      }
      svg {
        > * {
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            fill: $primaryXDark;
          }
          &:nth-child(1),
          &:nth-child(3) {
            fill: $primaryLight;
            opacity: 1;
          }
          &:nth-child(2) {
            fill: $white;
            opacity: 1;
          }
        }
      }
    }
  }
  &__expiring {
    margin: 16px 0 0 0;
    font-size: 18px;
    line-height: 25px;
    font-family: $bold;
    border-radius: 8px;
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
    background: #fefcf7;
    border: 2px dashed $orange;
  }
}
