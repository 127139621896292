@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.add-partner-documents {
  display: flex;
  width: 100%;
  margin: 0 0 30px;
  @media (max-width: 920px) {
    flex-direction: column;
  }
  @media (max-width: 374px) {
    margin: 0 -16px;
    position: relative;
    width: auto;
  }
  &__col {
    > span {
      @media (max-width: 920px) {
        flex-direction: row;
      }
    }
    &:first-child {
      background: $secondary;
      border-radius: 5px 0 0 5px;
      padding: 26px 36px 22px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 1240px) {
        padding: 24px 32px 21px;
      }
      @media (max-width: 920px) {
        flex-direction: row;
        padding: 19px 16px 16px;
        border-radius: 5px 5px 0 0;
      }
    }
    &:last-child {
      background: $white;
      border-radius: 0 5px 5px 0;
      border-style: solid;
      border-color: $greyLight;
      border-width: 1px 1px 1px 0;
      flex-grow: 1;
      padding: 22px 28px 26px;
      @media (max-width: 1240px) {
        padding: 20px 24px 24px;
      }
      @media (max-width: 920px) {
        padding: 16px 16px 20px;
        border-radius: 0 0 5px 5px;
        border-width: 0 1px 1px 1px;
      }
    }
  }
  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 920px) {
      justify-content: center;
    }
    svg {
      flex-shrink: 0;
      @media (max-width: 920px) {
        height: 50px;
        width: auto;
        margin-right: 12px;
      }
      &:first-child {
        margin-bottom: 6px;
        @media (max-width: 920px) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__price {
    text-align: center;
    font-size: 26px;
    line-height: 35px;
    font-family: $bold;
    margin: 12px 0 0;
    @media (max-width: 1240px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 920px) {
      margin: 0;
    }
  }
  &__discount {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-family: $regular;
    margin: 2px 0 0;
    opacity: 0.7;
    @media (max-width: 1240px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  &__title {
    font-size: 28px;
    line-height: 34px;
    font-family: $title;
    margin: 0 0 5px;
    @media (max-width: 1240px) {
      font-size: 22px;
      line-height: 27px;
    }

    @media (max-width: 920px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__description {
    font-size: 18px;
    line-height: 24px;
    font-family: $regular;
    margin: 0 0 17px;
    opacity: 0.7;
    @media (max-width: 1240px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 920px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
  div[role="button"] {
    @media (max-width: 920px) {
      font-size: 17px;
      padding: 0 16px;
    }
    @media (max-width: 374px) {
      font-size: 16px;
    }
  }
}
