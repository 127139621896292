@import "resources/sass/variables.scss";

.landing-button {
  height: 45px;
  width: fit-content;
  padding: 0px 14px;
  color: $white;
  background-color: $navy;
  border-color: $navy;
  border-radius: 0;
  margin: 6px;

  &:hover {
    background-color: $navyDark !important;
    border-color: $navyDark !important;

    svg {
      transform: translate(10%);
    }
  }

  & svg {
    height: 50%;
    width: auto;
    fill: black;
    path {
      fill: black !important;
    }
    transition: transform 0.1s;
  }

  &.chevron--right {
    & svg {
      margin-left: 4px;
      margin-right: 0px;
    }
  }

  &.chevron--navy svg polygon {
    fill: $navy
  }

  &.chevron--green svg polygon {
    fill: $secondary
  }

  &.chevron--white svg polygon {
    fill: $white
  }

  &.size--xsmall {
    height: 25px;
    padding: 0 4px;
    font-size: 0.6rem;
  }

  &.size--small {
    height: 30px;
    padding: 0 8px;
    font-size: 0.875rem;
  }

  &.size--large {
    height: 60px;
    padding: 0 22px;
    font-size: 1.5rem;
  }
}