@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.fieldset {
  background-color: $white;
  margin: 0;
  padding: 40px 32px 48px;
  border-style: solid;
  border-color: $greyXLight;
  border-width: 0 1px 1px 1px;
  @media (max-width: 1240px) {
    padding: 32px 24px 40px;
  }
  @media (max-width: 1024px) {
    padding: 32px 24px 40px;
  }
  @media (max-width: 374px) {
    border-width: 0 0 1px 0;
    padding-left: 20px;
    padding-right: 20px;
    padding: 32px 20px 36px;
  }
  &:first-child {
    border-top-width: 1px;
  }
  &__legend {
    width: 100%;
    margin: 0 0 15px;
    float: left;
    &:first-child {
      margin-top: -8px;
    }
    &:last-child {
      margin-bottom: -10px;
    }
    + .fieldset__description {
      position: relative;
      top: -10px;
      margin: 0 0 10px;
    }
    + * {
      clear: both;
    }
  }
  &__title {
    color: $text;
    font-family: $title;
    font-size: 28px;
    line-height: 38px;
    max-width: 600px;
    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 30px;
    }
    @media (max-width: 920px) {
      font-size: 20px;
      line-height: 27px;
    }
  }
  &__description {
    color: rgba($text, 0.7);
    font-family: $regular;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 20px;
    clear: both;
    @media (max-width: 920px) {
      font-size: 15px;
      line-height: 18px;
    }
    &:first-child {
      margin-top: -3px;
    }
    &:last-child {
      margin-bottom: -15px;
    }
  }
  &__row {
    margin: 0 -4px;
    display: flex;
    flex-wrap: wrap;
    clear: both;
    &:last-child {
      margin-bottom: -25px;
    }
    > div[role="button"]:first-child {
      margin-left: 4px;
    }
    > div[role="button"] + div[role="button"] {
      margin-left: 8px;
    }
  }
}
