@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.textarea {
  position: relative;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  height: auto;
  width: 100%;
  textarea {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 12px 50px 12px 18px;
    margin: 0px 0px 0px 0px;
    width: 100%;
    height: auto;
    font-family: $regular;
    color: $text;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    letter-spacing: normal;
    background: white;
    border: 1px solid $grey;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    background-image: none !important; // Remove 3rd party extension icons
    @media (max-width: 920px) {
      font-size: 16px;
    }
    &::-ms-clear {
      display: none;
      width: 0px;
      height: 0px;
    }
    &::-ms-reveal {
      display: none;
      width: 0px;
      height: 0px;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &:-webkit-autofill {
      background: white;
      -webkit-box-shadow: 0px 0px 0px 100px white inset !important;
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: white;
    }
    &:invalid {
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      text-transform: inherit !important;
      color: $grey !important;
      @include no-user-select;
    }
    &:-moz-placeholder {
      text-transform: inherit !important;
      color: $grey !important;
      opacity: 1 !important;
      @include no-user-select;
    }
    &::-moz-placeholder {
      text-transform: inherit !important;
      color: $grey !important;
      opacity: 1 !important;
      @include no-user-select;
    }
    &:-ms-input-placeholder {
      text-transform: inherit !important;
      color: $grey !important;
      @include no-user-select;
    }
    &::placeholder {
      text-transform: inherit !important;
      color: $grey !important;
      @include no-user-select;
    }
    &:focus {
      background-color: $white;
      border-color: $primary;
      &::-webkit-input-placeholder {
        color: $greyXDark !important;
      }
      &:-moz-placeholder {
        color: $greyXDark !important;
      }
      &::-moz-placeholder {
        color: $greyXDark !important;
      }
      &:-ms-input-placeholder {
        color: $greyXDark !important;
      }
      &::placeholder {
        color: $greyXDark !important;
      }
    }
    &:disabled {
      pointer-events: none;
      border-color: $disabled !important;
      color: $disabled !important;
      @include no-user-select;
      &::-webkit-input-placeholder {
        color: $disabled !important;
      }
      &:-moz-placeholder {
        color: $disabled !important;
      }
      &::-moz-placeholder {
        color: $disabled !important;
      }
      &:-ms-input-placeholder {
        color: $disabled !important;
      }
      &::placeholder {
        color: $disabled !important;
      }
    }
  }
  &--disabled {
    pointer-events: none;
  }
  &--valid {
    textarea {
      padding: 12px 50px 12px 18px;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("/static/images/form/icons/input-valid.svg");
      background-size: cover;
      background-repeat: no-repeat;
      right: 20px;
      top: 17px;
      width: 16px;
      height: 16px;
      pointer-events: none;
    }
  }
  &--invalid {
    textarea {
      border: 2px solid $alert;
      color: $alert;
      &::-webkit-input-placeholder {
        color: $alertPastel !important;
      }
      &:-moz-placeholder {
        color: $alertPastel !important;
      }
      &::-moz-placeholder {
        color: $alertPastel !important;
      }
      &:-ms-input-placeholder {
        color: $alertPastel !important;
      }
      &::placeholder {
        color: $alertPastel !important;
      }
      &:focus {
        color: $alert;
        border-color: $alertDark;
        &::-webkit-input-placeholder {
          color: $alertPastelDark !important;
        }
        &:-moz-placeholder {
          color: $alertPastelDark !important;
        }
        &::-moz-placeholder {
          color: $alertPastelDark !important;
        }
        &:-ms-input-placeholder {
          color: $alertPastelDark !important;
        }
        &::placeholder {
          color: $alertPastelDark !important;
        }
      }
    }
  }
  :global(.platform-desktop) & {
    &.textarea--invalid {
      textarea {
        &:hover {
          border-color: $alertDark;
          color: $alertDark;
          &:focus {
            border-color: $alertDark;
          }
          &::-webkit-input-placeholder {
            color: $alertPastelDark !important;
          }
          &:-moz-placeholder {
            color: $alertPastelDark !important;
          }
          &::-moz-placeholder {
            color: $alertPastelDark !important;
          }
          &:-ms-input-placeholder {
            color: $alertPastelDark !important;
          }
          &::placeholder {
            color: $alertPastelDark !important;
          }
        }
        &:focus {
          border-color: $alertDark;
        }
      }
    }
    textarea {
      &:hover {
        border-color: $greyDark;
        &:focus {
          border-color: $primary;
        }
        &::-webkit-input-placeholder {
          color: $greyXDark !important;
        }
        &:-moz-placeholder {
          color: $greyXDark !important;
        }
        &::-moz-placeholder {
          color: $greyXDark !important;
        }
        &:-ms-input-placeholder {
          color: $greyXDark !important;
        }
        &::placeholder {
          color: $greyXDark !important;
        }
      }
    }
  }
}
