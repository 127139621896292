@import "resources/sass/variables.scss";

.accepted-medicaid-lead-preview-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.accepted-medicaid-lead-preview {
  display: flex;
  justify-content: space-around;

  h3 {
    font-family: "F37Sonic Bold";
  }

  &__attribute-heading {
    color: $greyXXDark;
    font-size: 14px;
  }

  &__attribute-value {
    font-family: $semibold;
  }

  li {
    margin-top: 5px;
  }

  &__section {
    width: fit-content;
  }

  &__divider {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}