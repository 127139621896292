@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.question-share-distribution-shares {
  flex-basis: calc(100% - 8px);
  max-width: calc(100% - 8px);
  margin: 0 4px 25px;
  &__error {
    margin: -26px 4px;
    flex-basis: calc(100% - 8px);
    max-width: calc(100% - 8px);
    > p {
      color: $alert;
      font-size: 16px;
      line-height: 20px;
      font-family: $regular;
      margin: 0 0 20px;
      width: 100%;
      @include no-user-select;
    }
  }
  &__empty {
    border-style: solid;
    background-color: $tertiary;
    border-color: $greyXLight;
    border-width: 1px;
    padding: 10px 24px;
    border-radius: 4px;
    position: relative;
    margin: 0 0 8px;
    width: 100%;
    height: 72px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      padding-right: 16px;
    }
    form > *:nth-child(even) &,
    form > div > div > *:nth-child(even) & {
      background-color: $white;
    }
    > p {
      font-family: $bold;
      font-size: 18px;
      line-height: 25px;
    }
  }
  &-item {
    border-style: solid;
    background-color: $tertiary;
    border-color: $greyXLight;
    border-width: 1px 1px 1px 0;
    padding: 8px 24px 8px 16px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    margin: 0 0 8px;
    @media (max-width: 1024px) {
      padding-right: 16px;
    }
    form > *:nth-child(even) & {
      background-color: $white;
    }
    &--invalid {
      form > *:nth-child(even) & {
        background-color: $alertPastelXXXLight;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 4px;
      height: calc(100% + 2px);
      background-color: $primary;
    }
    &--disabled {
      &::before {
        background-color: $disabledDark;
      }
    }
    &__input {
      > div {
        &::after {
          content: none;
        }
        &::before {
          right: 13px !important;
        }
        > input {
          padding-right: 37px !important;
          max-width: 103px;
        }
        > span {
          padding-right: 37px !important;
          max-width: 101px;
        }
      }
    }
    &--invalid {
      background-color: $alertPastelXXXLight;
      &::before {
        background-color: $alert;
      }
      .question-share-distribution-shares-item {
        &__info {
          color: $alert;
        }
        &__input {
          > div {
            &::before {
              background-image: url("/static/images/form/icons/percentage-invalid.svg");
            }
            > input {
              border-color: $alert;
              color: $alert;
            }
            :global(.platform-desktop) & {
              &:hover {
                &::before {
                  background-image: url("/static/images/form/icons/percentage-invalid-hover.svg");
                }
                > input {
                  border-color: $alertDark;
                  color: $alertDark;
                }
              }
            }
          }
        }
      }
    }
    &__input {
      margin-left: auto;
      display: flex;
      flex-shrink: 0;
      padding-left: 30px;
      @media (max-width: 1280px) {
        padding-left: 18px;
      }
      @media (max-width: 374px) {
        padding-left: 12px;
      }
    }
    &__row {
      display: flex;
      align-items: center;
      > svg {
        height: 46px;
        width: 46px;
        flex-shrink: 0;
        @media (max-width: 920px) {
          height: 40px;
          width: 40px;
        }
        @media (max-width: 374px) {
          height: 36px;
          width: 36px;
        }
      }
    }
    &__col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -4px;
      margin-bottom: -5px;
      padding-left: 16px;
      @media (max-width: 374px) {
        padding-left: 12px;
      }
      .question-share-distribution-shares-item--disabled & {
        color: $disabled;
      }
    }
    &__name {
      font-size: 20px;
      line-height: 27px;
      color: $text;
      font-family: $bold;
      @media (max-width: 1240px) {
        font-size: 19px;
        line-height: 26px;
      }
      @media (max-width: 920px) {
        font-size: 16px;
        line-height: 22px;
      }
      .question-share-distribution-shares-item--disabled & {
        color: $disabled;
      }
    }
    &__info {
      font-size: 14px;
      line-height: 20px;
      color: $primary;
      font-family: $bold;
      margin-top: 3px;
      @media (max-width: 920px) {
        font-size: 13px;
        line-height: 18px;
      }
      &:last-child {
        margin-bottom: 3px;
      }
      .question-share-distribution-shares-item--disabled & {
        color: $disabled;
      }
    }
    &__label {
      font-family: $semibold;
      color: $greyXXDark;
      .question-share-distribution-shares-item--disabled & {
        color: $disabled;
      }
    }
  }
}
