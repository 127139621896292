@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.modal-form {
  display: flex;
  flex-direction: column;
  will-change: transform;
  background-color: $white;
  border-radius: 8px 8px 4px 8px;
  overflow: hidden;
  form > div {
    display: block;
    > div {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  &::after {
    content: "";
    position: absolute;
    border-radius: 8px 8px 4px 8px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($primaryXDark, 0.4);
    opacity: 0;
    z-index: 3;
    pointer-events: none;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
  &.modal-form-close::after {
    pointer-events: auto;
    opacity: 0.5;
  }
  &.modal-form-busy {
    pointer-events: none;
    @include no-user-select;
  }
  &.modal-form-busy::after {
    pointer-events: auto;
    opacity: 0.5;
  }
  &__focus-guard {
    position: fixed;
    overflow: hidden;
    padding: 0px;
    top: 1px;
    left: 1px;
    width: 1px;
    height: 0px;
  }
  &__header {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    height: 60px;
    align-items: center;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid $greyXLight;
    @include no-user-select;
    @media (max-width: 767px) {
      height: auto;
      flex-wrap: wrap;
    }
    > div[role="button"] {
      height: 100%;
      border-radius: 0;
      border: 0;
      @media (max-width: 767px) {
        height: 60px;
        flex-basis: 50%;
        &:nth-child(1) {
          order: 1;
          color: $alertDark;
          background-color: $alertPastelLight;
          :global(.platform-desktop) & {
            &:hover {
              color: $alertDark;
              background-color: rgba($alertPastel, 0.2) !important;
              svg path {
                fill: $alertDark;
              }
            }
            &:active {
              color: $alertXDark;
              border-color: $alertXDark;
              background-color: transparent !important;
              svg path {
                fill: $alertXDark;
              }
            }
          }
          :global(.platform-mobile) & {
            &:active {
              color: $alertXDark;
              border-color: $alertXDark;
              background-color: transparent !important;
              svg path {
                fill: $alertXDark;
              }
            }
          }
        }
        &:nth-child(3) {
          order: 2;
        }
      }
      &:focus {
        &::before {
          content: none;
        }
      }
    }
    .modal-form-close & > div[role="button"]:first-child {
      pointer-events: none;
      z-index: 4;
    }
    .modal-form-busy & > div[role="button"]:last-child {
      pointer-events: none;
      color: transparent;
      z-index: 4;
      &:after {
        position: absolute;
        content: "";
        width: 26px;
        height: 26px;
        top: 50%;
        left: 50%;
        margin-top: -13px;
        margin-left: -13px;
        background-repeat: no-repeat;
        background-size: 26px 26px;
        background-position: 50%;
        background-image: url("/static/images/form/icons/spinner-positive.gif") !important;
      }
    }
  }
  &__title {
    font-family: $title;
    font-size: 20px;
    line-height: 27px;
    color: $text;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    flex: 1;
    text-align: center;
    @media (max-width: 767px) {
      order: 3;
      flex-basis: 100%;
      padding: 12px 0;
    }
    &:first-child {
      margin-top: -8px;
    }
    + .modal__buttons {
      margin-top: 15px;
    }
  }
  &__content {
    flex: 1 1 auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: $primary $primaryXLight;
    min-height: 100px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 4px;
    > form > fieldset,
    > form > div > div > fieldset {
      border-left: none;
      border-right: none;
      &:first-child {
        border-top: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
      &:last-of-type {
        border-bottom: none;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    :global(.platform-desktop) & {
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 8px;
        margin-right: 10px;
      }
      &::-webkit-scrollbar-track-piece {
        background-color: $primaryXLight;
      }
      &::-webkit-scrollbar-thumb:vertical {
        background-color: $primary;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-clip: padding-box;
      }
    }
    .modal-form-close & {
      pointer-events: none;
    }
  }
  &__popup {
    position: absolute;
    top: 55px;
    left: 30px;
    z-index: 10;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    transform-origin: calc(0% + 29px) 0;
    -ms-transform-origin: calc(0% + 29px) 0;
    transform: scale(0);
    -ms-transform: scale(0);
    opacity: 0;
    padding: 20px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px) {
      right: 30px;
    }
    @media (max-width: 400px) {
      left: 16px;
      right: 16px;
    }
    @media (max-width: 374px) {
      padding: 20px;
    }
    .modal-form-close & {
      transform: scale(1);
      -ms-transform: scale(1);
      opacity: 1;
    }
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 14px 16px 14px;
      border-color: transparent transparent $white transparent;
      top: -11px;
      left: 15px;
    }
    &-message {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      max-width: 270px;
      margin: 0 0 14px;
      @media (max-width: 767px) {
        font-size: 17px;
        line-height: 22px;
      }
    }
    &-buttons {
      display: flex;
      @media (max-width: 600px) {
        flex-direction: column;
        width: 100%;
      }
    }
    div[role="button"] {
      flex-shrink: 0;
      padding: 0 16px;
      font-family: $semibold;
      @media (max-width: 600px) {
        width: 100%;
      }
      &:last-child {
        margin-left: 8px;
        @media (max-width: 600px) {
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }
}
