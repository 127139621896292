@import "resources/sass/variables.scss";

.state-select-handle {
  display: flex;
  justify-content: center;
  color: $black;
  font-family: $bold;
  background-color: $secondary;
  padding: 12px;
  margin: 0 12px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .1s;
  border: 2px solid $white;

  &:hover {
    background-color: $secondaryDark;
  }
  &:active {
    background-color: $secondary;
  }
}

.state-select-handle-read-only {
  display: flex;
  justify-content: center;
  color: $white;
  font-family: $bold;
  background-color: $primary;
  padding: 12px;
  margin: 0 12px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .1s;
  border: 2px solid $white;

  &:hover, &:active {
    background-color: $primaryDark;
  }
  &:hover, &:active {
    background-color: $primaryDark;
  }
}
