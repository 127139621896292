@import "resources/sass/variables.scss";

.medicaid-lead-preview-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.medicaid-lead-preview {
  display: flex;
  width: fit-content;
  justify-content: space-around;
  & > ol {
    margin: 20px;

    & > li {
      margin: 5px;
    }

    & > h3 {
      margin: 5px;
      font-family: "F37Sonic Bold"
    }
  }

  &__attribute-heading {
    color: $greyXXDark;
  }

  &__attribute-value {
    line-height: 25px;
    font-weight: bold;
  }

  &__divider {
    height: 80%;
    margin: 0;
  }
}