@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.plan-selector-addons-item {
  display: flex;
  align-items: center;
  height: 97px;
  padding: 0 80px;
  border-bottom: 1px solid $greyXLight;
  position: relative;
  @media (max-width: 1150px) {
    padding: 0 60px;
  }
  &:nth-child(odd) {
    background-color: $white;
  }
  &:nth-child(even) {
    background-color: $tertiary;
  }
  &:last-child {
    border-bottom: 0;
  }
  &-tooltip {
    position: absolute;
    left: 79px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    cursor: pointer;
    @media (max-width: 1150px) {
      left: 59px;
    }
    *:nth-child(2),
    *:nth-child(3) {
      fill: $primary;
      transition: all 0.5s;
    }
    *:nth-child(1) {
      fill: transparent;
      transition: all 0.5s;
    }
    &:focus {
      *:nth-child(2),
      *:nth-child(3) {
        fill: $primaryXDark;
      }
      *:nth-child(1) {
        fill: transparent;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        *:nth-child(2),
        *:nth-child(3) {
          fill: $primaryXDark;
        }
        *:nth-child(1) {
          fill: transparent;
        }
      }
    }
    .plan-selector-addons-item--disabled & {
      pointer-events: none;
      *:nth-child(2),
      *:nth-child(3) {
        fill: $disabledDark;
      }
      *:nth-child(1) {
        fill: transparent;
      }
      :global(.platform-desktop) & {
        &:hover {
          *:nth-child(2),
          *:nth-child(3) {
            fill: $disabledDark;
          }
          *:nth-child(1) {
            fill: transparent;
          }
        }
      }
    }
  }
  &-addon {
    flex-grow: 1;
    padding: 0 48px 0 47px;
  }
  &-add {
    width: 165px;
    text-align: center;
    margin-right: 50px;
    flex-shrink: 0;
    @include no-user-select;
    @media (max-width: 1150px) {
      margin-right: 8px;
    }
    .plan-selector-addons-item--disabled & {
      pointer-events: none;
    }
  }
  &-name {
    font-size: 18px;
    line-height: 25px;
    font-family: $semibold;
    color: $text;
    margin: 0 0 5px;
    @include no-user-select;
    .plan-selector-addons-item--disabled & {
      color: $disabledDark;
    }
  }
  &-duration {
    font-size: 16px;
    line-height: 22px;
    font-family: $regular;
    color: rgba($text, 0.6);
    @include no-user-select;
    .plan-selector-addons-item--disabled & {
      color: $disabledDark;
    }
  }
  &-total {
    width: 135px;
    flex-shrink: 0;
    text-align: center;
    font-size: 24px;
    line-height: 33px;
    font-family: $bold;
    color: rgba($text, 0.3);
    @include no-user-select;
    transition: color 0.5s, opacity 0.5s;
    .plan-selector-addons-item--selected & {
      color: $success;
    }
    .plan-selector-addons-item--disabled & {
      color: $disabledDark;
      font-size: 18px;
    }
  }
  &-toggle {
    width: 56px;
    height: 32px;
    border-radius: 32px;
    display: inline-block;
    border: 1px solid $primary;
    box-sizing: border-box;
    transition: border-color 0.5s;
    cursor: pointer;
    position: relative;
    @include no-user-select;
    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: $primary;
      border-radius: 50%;
      top: 3px;
      left: 3px;
      transform: translateX(0);
      -ms-transform: translateX(0);
      transition: border-color 0.5s, transform 0.5s, -ms-transform 0.5s;
    }
    .plan-selector-addons-item--selected & {
      border-color: $success;
      &::before {
        background-color: $success;
        transform: translateX(24px);
        -ms-transform: translateX(24px);
      }
    }
    .plan-selector-addons-item--disabled & {
      border-color: $disabledDark;
      &::before {
        background-color: $disabledDark;
      }
    }
  }
}
