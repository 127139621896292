@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (max-width: 374px) {
    margin: 0 -20px;
  }
  > div {
    display: flex;
    flex-direction: column;
    &:first-child {
      flex-grow: 1;
      margin-right: 32px;
      @media (max-width: 1240px) {
        margin-right: 24px;
      }
      @media (max-width: 920px) {
        margin-right: 12px;
      }
      @media (max-width: 767px) {
        margin: 0 0 8px;
      }
    }
    &:last-child {
      max-width: 370px;
      flex-basis: 370px;
      flex-shrink: 0;
      @media (max-width: 1240px) {
        max-width: 320px;
        flex-basis: 320px;
      }
      @media (max-width: 920px) {
        max-width: 280px;
        flex-basis: 280px;
      }
      @media (max-width: 767px) {
        flex-basis: auto;
        max-width: 100%;
      }
    }
  }
}
