@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.main-container {
  background-color: $grey;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.main {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1240px;
  min-width: 320px;
  margin: 0 auto;
  padding: 32px 32px 60px;
  box-sizing: border-box;
  @media (max-width: 1240px) {
    padding: 24px 24px 48px;
  }
  @media (max-width: 1024px) {
    padding: 24px 24px 48px;
  }
  @media (max-width: 374px) {
    padding: 24px 20px 40px;
  }

  &.about-us, &.faqs {
    background-color: white;
  }
}
.main--full-width {
  max-width: none;
  padding: 0 !important;
}
.main--no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
