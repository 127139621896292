@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.product-selector-item-small {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  &__name {
    color: $primaryXDark;
    font-size: 1.33rem;
    font: $bold;
    font-weight: 800;
    margin-bottom: 0.75rem;
    .product-selector-item-small--disabled & {
      color: $disabledDark;
    }
    svg {
      width: 16px;
      height: 16px;
    }

    svg path {
      fill: $secondary !important;
    }
  }

  &__price {
    font-size: 1rem;
    padding-left: 22px;
  }

  &__description {
    .product-selector-item-small--disabled & {
      color: $disabledDark;
    }
  }
  &__button-container {
    > div[role="button"] {
      line-height: 1rem;
      font-size: 1rem;
      background-color: $secondary;
      border: none;
      color: black;
      border-radius: 9999px;
      text-transform: uppercase;
      padding-right: 38px;
      max-height: 1.75em;
    }

    > div[role="button"]::after {
      background-image: url("/static/images/form/icons/chevron-black.svg");
      color: black;
      content: "";
      position: absolute;
      right: 10px;
      top: 0px;
      background-size: cover;
      background-repeat: no-repeat;
      width: 26px;
      height: 26px;
      transform: rotate(-90deg);
    }

    > div[role="button"]:hover {
      background-color: $secondaryDark;
    }
  }
}
