@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.search {
  flex: 1;
  width: 100%;
  margin: auto;
  background-color: $white;
  padding: 12px;
  color: $primary;
  font-size: 18px;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__title {
    font-family: $bold;
    margin-bottom: 4px;
  }

  &__wrapper {
    display: flex;
  }

  &__submit {
    outline: none;
    height: 50px;
    width: 50px;
    flex-shrink: 0;
    padding: 12px !important;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    svg {
      outline: none;
      width: 100%;
      height: 100%;
    }

    &:focus::before {
      display: none;
    }
  }
}
