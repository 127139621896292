.engagement-agreement {

  header {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }
  
  h2, label {
    font-size: large;
    font-weight: bold;
    margin: 1em 1em .75em 0;
  }

  p {
    margin: 1em 0;
  }

  sup {
    font-size: xx-small;
  }

  &__price {
    font-weight: bold;
    margin-right: 10px;
  }
}

.client-info {
  margin-bottom: 1em;
}