@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.product-selector-item {
  padding-right: 0.75rem;
  padding-bottom: 3rem;

  &__name {
    color: $primaryXDark;
    font-size: 2rem;
    font: $bold;
    font-weight: 800;
    margin-bottom: 0.75rem;

    .product-selector-item--disabled & {
      color: $disabledDark;
    }
  }

  &__price {
    font-size: 1rem;
  }

  &__description {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    font-family: $bold;
    font-weight: 700;
    line-height: 2rem;
    .product-selector-item--disabled & {
      color: $disabledDark;
    }
  }
  &__button-container {
    @media (min-width: 767px) {
      position: float;
      left: 24px;
      bottom: 24px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      width: calc(100%);
    }

    > div[role="button"] {
      font-size: 1rem;
      background-color: $secondary;
      border: none;
      color: black;
      border-radius: 9999px;
      text-transform: uppercase;
      padding-right: 38px;

      &:first-child {
        @media (max-width: 767px) {
          width: 100%;
          font-size: 0.75rem;
        }
      }
    }

    > div[role="button"]::after {
      background-image: url("/static/images/form/icons/chevron-black.svg");
      color: black;
      content: "";
      position: absolute;
      right: 10px;
      top: 5px;
      background-size: cover;
      background-repeat: no-repeat;
      width: 28px;
      height: 28px;
      transform: rotate(-90deg);
    }

    > div[role="button"]:hover {
      background-color: $secondaryDark;
    }
  }
}
