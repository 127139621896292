@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.question-share-distribution {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 920px) {
    display: block;
  }
  &-bar {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 2px solid $grey;
    height: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 0 21px;
    &--disabled {
      border-color: $disabledDark;
    }
    &__bar {
      height: 10px;
      border-radius: 8px;
      background-color: $alert;
      transition: all 0.5s ease-in-out;
      .question-share-distribution-bar--invalid & {
        background-color: $alert;
      }
      .question-share-distribution-bar--valid & {
        background-color: $success;
      }
      .question-share-distribution-bar--disabled & {
        background-color: transparent;
      }
    }
    &__controls {
      flex: 1;
      text-align: right;
    }
    > p {
      @include no-user-select;
      position: absolute;
      top: 15px;
      font-size: 14px;
      line-height: 18px;
      font-family: $semibold;
      color: $alert;
      @at-root .question-share-distribution-bar--invalid#{&} {
        color: $alert;
      }
      @at-root .question-share-distribution-bar--valid#{&} {
        color: $text;
      }
      @at-root .question-share-distribution-bar--disabled#{&} {
        color: $disabled;
      }
    }
  }
  + div {
    margin: 27px 0 -4px;
  }
}
