@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.product-recommendation {
  display: block;
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $primary;
    border-radius: 4px;
    padding: 36px 0;
    background-color: $white;
    @media (max-width: 1024px) {
      padding: 30px 0;
    }
    @media (max-width: 767px) {
      padding: 18px 0;
    }
  }
  &__list {
    // margin: 0 -8px 47px;
    margin: 0 -8px;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
      margin: 0;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 36px;
  }
  &__title {
    font-size: 28px;
    line-height: 38px;
    font-family: $title;
    color: $text;
    max-width: 500px;
    text-align: center;
    @include no-user-select;
    @media (max-width: 1240px) {
      font-size: 24px;
      line-height: 34px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      padding: 0 24px;
    }
    @media (max-width: 374px) {
      padding: 0 20px;
    }
  }
  &__description {
    font-size: 18px;
    line-height: 28px;
    font-family: $regular;
    color: rgba($text, 0.7);
    max-width: 540px;
    text-align: center;
    @include no-user-select;
  }
  &__addons {
    margin-top: 42px;
  }
}
