@import "resources/sass/variables.scss";

.Slider {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  * {
    cursor: default;
  }

  &--error {
    .Label__Icon {
      > svg { fill: $alert; }
    }
    .Label__Text {
      color: $alert;
    }
    .Slider__Extremes__Min.empty {
      color: $alert;
    }
    .Slider__Extremes__Max {
      color: $alert;
    }
    .Bar {
      background-color: $grey;
    }
    .Bar--empty .Bar__Handle > div {
      background-color: $alert;
    }
    &.Slider--empty .Slider__Percentage {
      color: $alert;
    }
  }

  &--warning {
    .Label__Icon {
      > svg { fill: $orange; }
    }
    .Label__Text {
      color: $orange;
    }
    .Slider__Extremes__Min.empty {
      color: $orange;
    }
    .Slider__Extremes__Max {
      color: $orange;
    }
    .Bar {
      background-color: $orange;
    }
    .Bar--empty .Bar__Handle > div {
      background-color: $orange;
    }
    &.Slider--empty .Slider__Percentage {
      color: $orange;
    }
  }

  &__PercentageWrapper {
    position: relative;
    width: 1px;
    display: flex;
    justify-content: center;
  }

  &__Percentage {
    color: $primary;
    position: relative;
    top: 20px;
    font-size: 30px;
    line-height: 50px;
    font-family: $bold;
  }

  &__Extremes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 8px;

    &__Min {
      color: $primary;
    }

    &__Max {
      &.empty {
        display: none;
      }
    }
  }

  &__Labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 3px;

    .Label {
      flex-flow: column;
      white-space: nowrap;

      &__Icon {
        display: inline-block;
        overflow: hidden;
        max-width: calc(min(calc(100% - 16px), 1px) * 3000);
        
        > svg {
          width: 16px;
          height: 16px;
          position: relative;
          top: 3px;
        }
      }

      &__Text {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        
        > span {
          white-space: nowrap;
        }
      }
    }

    .Label__Protected {
      text-align: left;

      .Label__Icon {
        > svg { fill: $primary; }
      }

      .Label__Text {
        max-width: calc(min(calc(100% - 85px), 1px) * 3000);
        margin-left: 3px;
        color: $primary;
      }
    }

    .Label__AtRisk {
      text-align: right;

      .Label__Text {
        max-width: calc(min(calc(100% - 60px), 1px) * 3000);
        margin-right: 3px;
      }
    }
  }
}

.Bar {
  height: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 1px;
  
  &__Fill {
    background-color: $primary;
    height: 6px;
  }
  
  &__Handle {
    height: 14px;
    width: 14px;
    position: relative;
    top: -1px;
    left: -1px;
    overflow: visible;

    > div {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}
