@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.label {
  display: inline-block;
  color: $text;
  font-size: 16px;
  line-height: 20px;
  font-family: $regular;
  margin: 0 30px 7px 0;
  @include no-user-select;
  cursor: pointer;
  transition: color 0.5s;
  @media (max-width: 920px) {
    font-size: 15px;
    line-height: 18px;
  }
  &--invalid {
    color: $alert;
  }
  &--disabled {
    color: $disabled !important;
    pointer-events: none;
    @include no-user-select;
    &::selection {
      color: $disabled !important;
    }
  }
}
