@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.partner-detail {
  max-width: 250px;
  font-family: $regular;

  header {
    padding: 0px 20px 10px 0;
    border-bottom: solid 1px $greyXLight;
    margin-bottom: 10px;

    h1 {
      font-size: 14px;
      font-weight: bold;
      flex: 1;
      margin-right: 5px;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;

    &__icon {
      margin-right: 8px;
      height: 16px;
      width: 16px;

      path {
        fill: $primary;
      }
    }
  }

  &__address {
    font-size: 14px;
    margin: 12px 0;
  }

  &__contact {
    font-size: 14px;
    margin: 12px 0;
  }

  &__actions {
    margin: 8px 0 0;
    display: flex;
    flex-direction: row;

    * {
      font-size: 14px;
    }

    .partner-detail__action {
      margin-left: 16px;
      &:first-child {
        margin-left: 0;
      }

      button {
        text-transform: uppercase;
        font-size: 12px;
        text-decoration: underline;
      }

      button:hover {
        text-decoration: none;
      }

      &__primary {
        button {
          display: flex;
          align-items: center;
        }
      }

      &__icon {
        margin-left: 5px;
        height: 14px;
        width: 9px;

        path {
          fill: $secondary;
        }
      }
    }
  }
}
