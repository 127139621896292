.slider-description {
  margin-top: 20px;
}

.asset-protection-chart-container {
  display: flex;
  justify-content: flex-start;
}

.asset-protection-chart {
  flex-basis: 100%;
}

.asset-protection-plan-immediate-protection-description {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}