@import "resources/sass/variables.scss";

@import "resources/sass/reset.scss";
@import "resources/sass/fonts.scss";
@import "resources/sass/html.scss";

@import "./themes/default.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
  flex: 1
}
