@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard-products-item-action {
  display: flex;
  align-items: center;
  flex-direction: column;
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
    > div[role="button"] {
      max-width: calc(50% - 4px);
      flex-basis: calc(50% - 4px);
      height: 50px;
      @media (max-width: 1240px) {
        font-size: 17px;
      }
      @media (max-width: 1024px) {
        max-width: 100%;
        flex-basis: 100%;
        margin-top: 8px;
      }
      &:nth-child(1) {
        margin-right: 8px;
        display: flex;
        font-size: 16px;
        @media (max-width: 1240px) {
          font-size: 17px;
        }
        @media (max-width: 1024px) {
          margin: 0;
        }
        > span {
          order: 1;
          position: relative;
        }
        > svg {
          order: 2;
          margin: 0 0 0 17px;
          rect {
            fill: $primary;
          }
        }
      }
      &:only-child {
        flex-basis: 100%;
        max-width: 100%;
        margin: 0;
        height: 50px;
      }
    }
    :global(.platform-desktop) & {
      > div[role="button"]:hover > svg rect {
        fill: $primaryDark;
      }
    }
  }
  &__prompt {
    font-size: 18px;
    line-height: 25px;
    font-family: $bold;
    border-radius: 8px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border: 2px dashed $grey;
    background: $white;
    .client-dashboard-products-item-action--valid & {
      background: #f7fefb;
      border-color: $success;
    }
    .client-dashboard-products-item-action--warning & {
      background: #fefcf7;
      border-color: $orange;
    }
    .client-dashboard-products-item-action--error & {
      background: #fef7f7;
      border-color: $alert;
    }
    > p {
      text-align: center;
      margin: -5px 0 0;
    }
    > div {
      margin-top: 13px;
      display: flex;
      > div[role="button"] {
        &:only-child {
          width: 100%;
          height: 50px;
          span {
            order: 1;
            position: relative;
          }
          svg {
            order: 2;
            margin: 0 0 0 17px;
            * {
              fill: $success;
              transition: all ease-out 0.075s;
            }
          }
          :global(.platform-desktop) & {
            &:hover svg * {
              fill: $successDark;
            }
            &:active svg * {
              fill: $successXDark;
            }
          }
          :global(.platform-mobile) & {
            &:active svg * {
              fill: $successXDark;
            }
          }
        }
      }
    }
  }
}
