@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.wrapper {
  max-width: 780px;
  ul,
  ol,
  p {
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 10px;
    margin-top: 40px;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: lower-latin;
  }
}
