@import "resources/sass/variables.scss";

.PeoplePicker__picker {
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 20px;
  }

  > span {
    font-size: medium;
  }

  &--empty {
    color: $disabled;
  }
}