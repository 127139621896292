.consumer-medicaid-leads-actions {
  margin-top: 24px;
  margin-bottom: 24px;
  @media (max-width: 374px) {
    padding: 0 20px;
  }
  div[role="button"] {
    @media (max-width: 1024px) {
      width: 100%;
      height: 45px;
    }
  }
}