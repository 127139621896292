@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.party-list-item {
  border-style: solid;
  background-color: $tertiary;
  border-color: $greyXLight;
  border-width: 1px 1px 1px 0;
  padding: 16px 16px 16px 16px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  margin: 0 0 8px;
  form > *:nth-child(even) &,
  form > div > div > *:nth-child(even) & {
    background-color: $white;
  }
  > .party-list-item__row {
    @media (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 4px;
    height: calc(100% + 2px);
    background-color: $primary;
  }
  &__buttons {
    margin-left: auto;
    display: flex;
    flex-shrink: 0;
    padding-left: 18px;
    @media (max-width: 450px) {
      margin: 12px 0 0 0;
      padding-left: 0;
    }
    > * {
      margin-left: 12px;
      padding: 0 24px;
      &:first-child {
        margin-left: 0;
      }
      @media (max-width: 1072px) {
        padding: 0 14px;
        > svg:not(:only-child) {
          margin-right: 7px;
        }
      }
    }
  }
  &__row {
    display: flex;
    align-items: center;
    > svg {
      height: 46px;
      width: 46px;
      flex-shrink: 0;
      @media (max-width: 920px) {
        height: 40px;
        width: 40px;
      }
      @media (max-width: 374px) {
        height: 36px;
        width: 36px;
      }
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -4px;
    margin-bottom: -5px;
    padding-left: 15px;
    @media (max-width: 374px) {
      padding-left: 10px;
    }
  }
  &__name {
    font-size: 20px;
    line-height: 27px;
    color: $text;
    font-family: $bold;
    @media (max-width: 1240px) {
      font-size: 19px;
      line-height: 26px;
    }
    @media (max-width: 920px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  &__info {
    font-size: 14px;
    line-height: 20px;
    color: $primary;
    font-family: $bold;
    margin: 3px 0 0;
    @media (max-width: 920px) {
      font-size: 13px;
      line-height: 19px;
    }
    &:last-child {
      margin-bottom: 3px;
    }
  }
  &__label {
    font-family: $semibold;
    color: $greyXXDark;
  }
  &--disabled {
    &::before {
      background-color: $disabledDark;
    }
    .party-list-item__name {
      color: $disabledDark;
    }
    .party-list-item__info {
      color: $disabledDark;
    }
    .party-list-item__label {
      color: $disabledDark;
    }
  }

   &--incomplete {
    background-color: $alertPastelXXLight !important;

    &::before {
      background-color: $alert;
    }
  }

  &__handle {
    content: "....";
    width: 10px;
    height: 20px;
    display: inline-block;
    overflow: hidden;
    line-height: 5px;
    padding: 3px 4px 3px 1px;
    cursor: move;
    vertical-align: middle;
    margin-top: -0.7em;
    margin-right: 0.3em;
    font-size: 12px;
    font-family: sans-serif;
    letter-spacing: 2px;
    color: #cccccc;
    text-shadow: 1px 0 1px black;
    &::after {
      content: ".. .. .. ..";
    }
  }
}
