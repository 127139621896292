@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.header-mobile-navigation {
  display: flex;
  width: 100%;
  &--open {
    .header-mobile-navigation__button {
      &::after {
        left: -3px;
        right: -4px;
        height: 40px;
        margin-top: -20px;
      }
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: -3px;
          left: 5px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 20px;
          left: 5px;
        }
      }
    }
  }
  > div[role="button"]:not(.header-mobile-navigation__button) {
    border-color: rgba($white, 0.5);
    margin-left: auto;
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      border-color: $white;
      background-color: rgba($white, 0.1);
      &::before {
        border-color: $white;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $white;
        background-color: rgba($white, 0.1);
      }
      &:active {
        background-color: $white;
        color: $primary;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $white;
        color: $primary;
      }
    }
  }
  &__nav {
    visibility: hidden;
    position: fixed;
    min-width: 320px;
    top: 57px;
    left: 0;
    width: 100%;
    height: calc(100% - 57px);
    background-color: $white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.4, 0.8), visibility 0s 0.2s;
    .header-mobile-navigation--open & {
      @media (max-width: 767px) {
        visibility: visible;
        transform: translateX(0);
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.4, 0.8);
      }
    }
    > ul {
      height: calc(100% - 63px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 24px;
    height: 24px;
    width: 24px;
    position: relative;
    &::after,
    &:before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &::after {
      background-image: url("/static/images/icons/arrow-fat.svg");
      fill: $primary;
    }
    &--login {
      &::after {
        background-image: url("/static/images/icons/login.svg");
      }
    }
    &--user {
      &::after {
        background-image: url("/static/images/icons/user.svg");
      }
    }
    &--users {
      &::after {
        background-image: url("/static/images/icons/users.svg");
      }
    }
    &--logout {
      &::after {
        background-image: url("/static/images/icons/logout.svg");
      }
    }
  }
  &__link {
    font-size: 18px;
    color: $text;
    font-family: $regular;
    position: relative;
    padding: 15px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease-out 0.075s;
    border-bottom: 1px solid $greyXLight;
    @media (max-width: 767px) {
      font-size: 17px;
    }
    @media (max-width: 374px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryXLight;
        text-decoration: none;
      }
      &:active {
        background-color: $primaryLight;
        text-decoration: none;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $primaryLight;
        text-decoration: none;
      }
    }
    &-text {
      padding-left: 12px;
      flex-basis: calc(100% - 36px);
      max-width: calc(100% - 36px);
    }
    &-alert {
      font-family: $bold;
      font-size: 16px;
      line-height: 20px;
      color: $white;
      &-container {
        flex-grow: 0;
        flex-shrink: 0;
        height: 20px;
        width: 30px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        background-color: $primary;
      }
    }
  }
  &__logout {
    height: 63px;
    font-size: 17px;
    color: $alert;
    background: none;
    border: none;
    margin-top: auto;
    border-top: 1px solid $greyXLight;
    box-sizing: border-box;
    font-family: $semibold;
    position: relative;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 0;
    transition: all ease-out 0.075s;
    @media (max-width: 374px) {
      padding: 12px 20px;
    }
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      border-color: $greyXLight;
      background-color: $page;
      &::before {
        content: none;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $page;
        border-color: $greyXLight;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        border-color: $greyXLight;
        background-color: $page;
      }
    }
    span {
      display: inline-flex;
      align-items: center;
      &::before {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        background-image: url("/static/images/icons/logout.svg");
      }
    }
  }
  &__button {
    width: 32px;
    height: 19px;
    position: relative;
    margin-left: auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    &:focus {
      &::after {
        border: 2px solid $primaryLight;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        &::after {
          content: none;
        }
        span {
          background-color: darken($white, 10%);
        }
      }
    }
    :global(.platform-mobile) &,
    :global(.platform-desktop) & {
      &:active {
        &::after {
          content: none;
        }
        span {
          background-color: darken($white, 20%);
        }
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 33px;
      top: 50%;
      margin-top: -17px;
      left: -7px;
      right: -7px;
      border: 2px solid transparent;
      box-sizing: border-box;
      pointer-events: none;
      transition: all ease-in-out 0.25s;
      :global(.platform-mobile) & {
        transition: none;
      }
    }
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $white;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: top 0.25s ease-in-out, transform 0.25s ease-in-out, left 0.25s ease-in-out, opacity 0.25s ease-in-out,
        width 0.25s ease-in-out;
      transform-origin: left center;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2) {
        top: 8px;
      }
      &:nth-child(3) {
        top: 16px;
      }
    }
  }
}
