@font-face {
  font-family: "F37Sonic Light";
  src: url("/static/fonts/F37Sonic-Light.woff2") format("woff2"), url("/static/fonts/F37Sonic-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37Sonic Regular";
  src: url("/static/fonts/F37Sonic-Regular.woff2") format("woff2"), url("/static/fonts/F37Sonic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37Sonic Medium";
  src: url("/static/fonts/F37Sonic-Medium.woff2") format("woff2"), url("/static/fonts/F37Sonic-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37Sonic Bold";
  src: url("/static/fonts/F37Sonic-Bold.woff2") format("woff2"), url("/static/fonts/F37Sonic-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
