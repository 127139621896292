@import "resources/sass/variables.scss";

.why-guidr-map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.landing-pin {
  fill: $white !important;
}