@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.card {
  @include no-user-select;
  margin-top: 29px;
  @media (max-width: 1024px) and (min-width: 768px) {
    margin-top: 24px;
  }
  @media (max-width: 767px) {
    padding: 0 24px;
  }
  @media (max-width: 374px) {
    padding: 0 20px;
  }
  &:nth-child(1) {
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__title {
    font-size: 25px;
    line-height: 35px;
    font-family: $title;
    margin: -8px 0 10px;
    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 30px;
    }
    @media (max-width: 767px) {
      margin: 0 0 6px;
    }
  }
  > p {
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 22px;
      margin: -7px 0 14px;
    }
    @media (max-width: 767px) {
      margin: 0 0 14px;
    }
  }
  &__actions {
    margin-top: 12px;
    > div[role="button"] {
      height: 40px;
      @media (max-width: 440px) {
        width: 100%;
      }
    }
  }
}
