@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.shopping-cart {
  background-color: $white;
  border: 1px solid $greyXLight;
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 374px) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
  &__col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 31px;
    border-top: 1px solid $greyXLight;
    background-color: $tertiary;
    flex-grow: 1;
    &:last-child {
      margin-left: auto;
      align-items: center;
      border-left: 1px solid $greyXLight;
      background-color: $tertiaryXDark;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  &__title {
    color: $primary;
    font-family: $title;
    font-size: 24px;
    line-height: 33px;
    padding: 24px 32px 0 32px;
    @media (max-width: 1240px) {
      padding: 24px 24px 0 24px;
    }
    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 30px;
      padding: 24px 24px 0 24px;
    }
    @media (max-width: 920px) {
      font-size: 20px;
      line-height: 27px;
    }
  }
  &__info {
    margin-top: 1em;
  }
  &__group-title {
    font-family: $regular;
    color: $greyXXXDark;
    font-size: 14px;
    line-height: 40px;
  }
  &__sub-group-title {
    font-family: $regular;
    color: $greyXXDark;
    font-size: 14px;
    line-height: 40px;
  }
  &__summary {
    padding: 23px 32px 43px;
    @media (max-width: 1240px) {
      padding: 16px 24px 32px;
    }
    @media (max-width: 1024px) {
      padding: 8px 24px 24px;
    }
    &-item {
      display: flex;
      color: $text;
      font-size: 14px;
      line-height: 19px;
      color: rgba($text, 0.6);
      &:first-child {
        font-size: 24px;
        line-height: 33px;
        color: $text;
        @media (max-width: 1024px) {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
    &-name {
      font-family: $regular;
      margin: 0 0 7px;
      .shopping-cart__summary-item:first-child & {
        font-family: $semibold;
        margin: 9px 0 12px;
        @media (max-width: 1024px) {
          margin-bottom: 8px;
        }
      }
    }
    &-price {
      font-family: $semibold;
      margin: 0 0 7px auto;
      .shopping-cart__summary-item:first-child & {
        font-family: $bold;
        color: $primary;
        margin: 9px 0 12px auto;
        @media (max-width: 1024px) {
          margin-bottom: 8px;
        }
      }
    }
  }
  &__deposit {
    display: flex;
    &-title {
      color: $text;
      font-family: $semibold;
      font-size: 20px;
      line-height: 27px;
      margin: 1px 0 0;
    }
    &-amount {
      font-family: $bold;
      color: $primary;
      font-size: 24px;
      line-height: 33px;
    }
  }
}

.group-header {
  border-bottom: solid 1px $greyXLight;
  border-top: solid 1px $greyXLight;
}
