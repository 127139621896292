@import "resources/sass/variables.scss";

.medicaid-lead-preview-item {
  display: flex;
  position: relative;
  width: 100%;
  padding: 6px 32px;
  background-color: $grey;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 6px;

  border: solid 1px $greyXDark;

  &__date {
    position: absolute;
    bottom: 0;
    right: 0;
    color: $greyXXXDark;
    padding: 8px;
    font-size: 14px;
  }

  &__client-data {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 16px;
    }

    &__value {
      font-family: $bold;
      color: $primary;
      font-size: 24px;
    }
  }
}