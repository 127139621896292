@import "resources/sass/variables.scss";
@import "../../styles/grid.module.scss";
@import "../../index.scss";

.hero {
  position: relative;
  background-color: $grey;
  color: $navy;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (min-width: $md) {
    padding-top: 1.75rem;
  }

  &__img img {
    
    @media (max-width: $xl) {
      height: 500px;
      width: auto;
    }

    @media (max-width: $lg) {
      height: 400px;
      width: auto;
    }

    @media (max-width: $md) {
      height: 250px;
      width: auto;
    }

    @media (max-width: 530px) {
      height: 200px;
    }

    @media (max-width: 410px) {
      height: 140px;
    }

    @media (max-width: 360px) {
      height: 100px;
    }
  }

  & > div {
    position: relative;
  }

  .random-arrows {
    position: absolute;
    top: -115px;
    right: 35px;
    width: 600px;
    height: auto;

    @media (max-width: $xl) {
      display: none;
    }
  }

  &__actions {
    margin-left: -6px;
    max-width: 600px;

    @media (max-width: $xl) {
      display: none;
    }
  }

  &__get-started {

    & > div[role="button"] {
      background-color: $navy;
      border-color: $navy;
      color: $white;
      height: 90px;
      padding: 0 50px;
      margin: 0;
      font-family: $title;
      font-size: 26px;
      z-index: 15;

      &:hover {
        background-color: $navyDark !important;
        border-color: $navyDark !important;
      }

      @media (max-width: $lg) {
        height: 75px;
        font-size: 20px;
        padding: 0 35px;
      }

      @media (max-width: $md) {
        height: 50px;
        font-size: 16px;
        padding: 0 26px;
      }

      @media (max-width: 530px) {
        height: 35px;
        font-size: 12px;
        padding: 0 14px;
      }

      @media (max-width: 360px) {
        height: 20px;
        font-size: 10px;
        padding: 0 10px;
      }
    }
  }

  &__grid-container {
    display: flex;
    padding-top: 2.5rem;

    & > div {
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: right;

    h3 {
      @media (max-width: $lg) {
        font-size: 0.85rem;
        line-height: 1.25rem;
      }
  
      @media (max-width: $md) {
        font-size: 0.75rem;
        line-height: 1rem;
      }
  
      @media (max-width: $sm) {
        font-size: 0.6rem;
        line-height: 0.75rem;
      }
  
      @media (max-width: 530px) {
        display: none;
      }
    }
  }

  &__info-heading {
    font-family: "F37Sonic Bold";
    max-width: 600px;
    color: $navy;
    font-weight: 600;
    font-size: 3.4rem;
    line-height: 1;
    margin-bottom: 0.75rem;

    @media (max-width: $xl) {
      text-align: right;
    }

    @media (max-width: $lg) {
      font-size: 2.25rem;
    }

    @media (max-width: $md) {
      font-size: 1.75rem;
      margin-bottom: 0.25rem;
    }

    @media (max-width: $sm) {
      font-size: 1.25rem;
    }

    @media (max-width: 360px) {
      font-size: 1rem;
    }

  }

  &__info-subheading {
    font-family: "F37Sonic Bold";
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 600;
    margin-top: 1.75rem;

    @media (max-width: $md) {
      font-size: 1rem;
      margin-top: 1rem;
    }

    @media (max-width: $sm) {
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }

    @media (max-width: 410px) {
      margin-top: 0.25rem;
    }

    @media (max-width: 360px) {
      font-size: 0.6rem;
      line-height: 0.75rem;
    }
  }

  &__img-container {
    width: 100%;
    flex: 1 1 0%;

    @media (min-width: $sm) {
      width: auto;
    }
  }
}

.hero-container {
  display: flex;
  justify-content: space-between;
  height: 540px;

  @media (max-width: $xl) {
    height: 470px;
  }

  @media (max-width: $lg) {
    height: 360px;
  }

  @media (max-width: $md) {
    height: 230px;
  }

  @media (max-width: 530px) {
    height: 180px;
  }

  @media (max-width: 410px) {
    height: 120px;
  }

  @media (max-width: 360px) {
    height: 80px;
  }
}