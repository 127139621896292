@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard-products-item-breakdown {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0;
  width: 100%;
  @media (max-width: 1240px) {
    margin: 0;
  }
  &__row {
    display: flex;
  }
  &__list {
    margin-top: 24px;
    border: 1px solid $tertiaryXDark;
    @media (max-width: 1240px) {
      position: relative;
    }
    &::before {
      @media (max-width: 1240px) {
        content: "";
        position: absolute;
        width: 2px;
        height: calc(100% + 2px);
        top: -1px;
        left: -1px;
        background-color: $primary;
        z-index: 2;
      }
    }
  }
  &__item {
    display: flex;
    background-color: $white;
    box-sizing: border-box;
    min-height: 42px;
    @media (max-width: 1240px) {
      min-height: auto;
      flex-wrap: wrap;
    }
    &:first-child {
      @media (max-width: 1240px) {
        display: none;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid $tertiaryXDark;
    }
    &-col {
      padding: 15px 20px;
      text-align: left;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 1210px) {
        padding: 11px 12px;
      }
      &:nth-child(1) {
        flex-grow: 1;
        flex-shrink: 1;
        padding-left: 24px;
        @media (max-width: 1210px) {
          padding-left: 16px;
        }
      }
      &:nth-child(2) {
        width: 160px;
        @media (max-width: 1210px) {
          width: 136px;
        }
      }
      &:nth-child(3) {
        width: 94px;
      }
      &:nth-child(4) {
        width: 40px;
        padding-right: 24px;
        @media (max-width: 1210px) {
          padding-right: 16px;
        }
      }
    }
    &-title {
      font-family: $bold;
      color: $text;
      margin: 0;
      white-space: nowrap;
      font-size: 15px;
      line-height: 20px;
      @media (max-width: 1210px) {
        font-size: 14px;
      }
    }
  }
}
