@import "resources/sass/variables.scss";

.documents-list {
  list-style-type: none !important;
  padding: 0 !important;
  
  > li {
    padding: 10px;

    &::before {
      content: "\026AC";
      color: $primary;
      margin-right: 12px;
    }
  }

  &--empty {
    color: $disabledXDark !important;
  }
}

.documents-render-status {

  li {
    display: flex
  }

  &__name {
    flex: 2;
  }

  &__status {
    flex: 1;
    margin-left: 12px;
  }

  &__failure {

    > .documents-render-status__status{
      color: $alert;
      font-weight: bold;
    }
  }

}