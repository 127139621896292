@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.progress {
  @include no-user-select;

  @media (max-width: $md) {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 25px;
    line-height: 35px;
    color: $text;
    font-family: $title;
    margin: -7px 0 10px;
    @media (max-width: $lg) {
      font-size: 22px;
      line-height: 30px;
      margin: -6px 0 4px;
    }
    @media (max-width: $md) {
      display: none;
    }
  }
  &__bar {
    width: 100%;
    height: 8px;
    position: relative;
    border-radius: 19px;
    overflow: hidden;
    margin: 0 0 7px;
    @media (max-width: $lg) {
      margin: 8px 0 8px;
    }
    @media (max-width: $md) {
      height: 32px;
      border-radius: 0;
      margin: 0;
    }
    &-container {
      @media (max-width: $md) {
        display: none;
      }
    }
    &-current {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $successDark;
      width: 0;
      height: 100%;
      z-index: 1;
      border-radius: 19px;
      transition: width 0.35s;
      @media (max-width: $md) {
        border-radius: 0;
        overflow: hidden;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $grey;
      opacity: 0.4;
      z-index: 0;
    }
  }
  &__button-container {
    display: none;
    @media (max-width: $md) {
      display: block;
      margin: -24px 0 17px;
      left: -24px;
      width: calc(100% + 48px);
      height: 32px;
      position: relative;
      z-index: 12;
      background-color: $page;
    }
    @media (max-width: 374px) {
      left: -20px;
      width: calc(100% + 40px);
    }
    > div[role="button"] {
      @media (max-width: $md) {
        border: none;
        background: none;
        padding: 0;
        height: 100%;
        width: 100%;
        border-radius: 0;
        &:focus {
          &::before {
            content: none;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            background-color: transparent;
            border-color: transparent;
          }
        }
        &:active {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }
  &__text {
    &-container {
      @media (max-width: $md) {
        display: flex;
        margin: 0 0 8px;
      }
    }
    &-steps {
      display: none;
      @media (max-width: $md) {
        display: block;
        font-size: 11px;
        line-height: 15px;
        text-transform: uppercase;
        color: $greyXXDark;
        font-family: $bold;
      }
    }
    &-percentage {
      font-family: $bold;
      color: rgba($text, 0.7);
      font-size: 16px;
      line-height: 22px;
      margin: 0 0 2px;
      @media (max-width: $lg) {
        font-size: 15px;
        line-height: 21px;
        margin: 0 0 -2px;
      }
      @media (max-width: $md) {
        display: none;
      }
    }
    &-percentage,
    &-button {
      &--white {
        @media (max-width: $md) {
          display: block;
          font-family: $bold;
          color: $white;
          font-size: 12px;
          line-height: 16px;
          position: absolute;
          white-space: nowrap;
        }
        svg * {
          @media (max-width: $md) {
            fill: $white !important;
          }
        }
        span::after {
          @media (max-width: $md) {
            border: 1px solid $white;
          }
        }
      }
      &--black {
        @media (max-width: $md) {
          display: block;
          font-family: $bold;
          color: $black;
          font-size: 12px;
          line-height: 16px;
          position: absolute;
          white-space: nowrap;
        }
        svg * {
          @media (max-width: $md) {
            fill: $black !important;
          }
        }
        span::after {
          @media (max-width: $md) {
            border: 1px solid rgba($black, 0.3);
          }
        }
      }
      &--white,
      &--black {
        @media (max-width: $md) {
          top: 3px;
          line-height: 26px;
          svg {
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
          }
          span {
            position: relative;
            display: inline-flex;
            align-items: center;
            padding-right: 8px;
            &::after {
              content: "";
              height: 24px;
              width: 24px;
              position: relative;
              display: block;
              box-sizing: border-box;
              border-radius: 50%;
              right: -8px;
            }
          }
        }
      }
    }
    &-percentage {
      &--white {
        @media (max-width: $md) {
          left: 24px;
        }
        @media (max-width: 374px) {
          left: 20px;
        }
      }
      &--black {
        @media (max-width: $md) {
          left: 25px;
        }
        @media (max-width: 374px) {
          left: 21px;
        }
      }
    }
    &-button {
      &--white {
        @media (max-width: $md) {
          left: calc(100vw + -24px);
          transform: translateX(-100%);
          :global(.has-scrollbar.platform-windows) & {
            left: calc(100vw + -40px);
          }
        }
        @media (max-width: 374px) {
          left: calc(100vw + -20px);
          transform: translateX(-100%);
          :global(.has-scrollbar.platform-windows) & {
            left: calc(100vw + -36px);
          }
        }
        @media (max-width: 336px) {
          :global(.has-scrollbar.platform-windows) & {
            left: 300px;
          }
        }
        @media (max-width: 320px) {
          left: 300px;
        }
      }
      &--black {
        @media (max-width: $md) {
          right: 24px;
        }
        @media (max-width: 374px) {
          right: 20px;
        }
      }
    }
  }
  &__text-remaining {
    font-family: $regular;
    color: $primary;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: $md) {
      margin-left: auto;
      font-family: $bold;
      font-size: 11px;
      line-height: 15px;
      text-transform: uppercase;
      color: $greyXXDark;
    }
  }
  &__text-percentage {
    font-family: $bold;
  }
  &__track {
    margin-top: 21px;
    padding-left: 39px;
    position: relative;
    @media (max-width: $lg) {
      margin-top: 18px;
    }
    @media (max-width: $md) {
      margin-top: 12px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      height: calc(100% - 4px);
      width: 4px;
      background-color: $grey;
      top: 2px;
      left: 12px;
      z-index: 1;
      @media (max-width: $md) {
        height: calc(100% - 17px);
      }
    }
    &-modal {
      @media (max-width: $md) {
        visibility: hidden;
        position: fixed;
        min-width: 320px;
        top: 89px;
        left: 100%;
        width: 100%;
        height: calc(100% - 89px);
        background-color: $white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        transform: translateY(-100%);
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.4, 0.8), visibility 0s 0.2s, left 0s 0.2s;
      }
      .progress--open & {
        @media (max-width: $md) {
          left: 0;
          visibility: visible;
          transform: translateY(0);
          transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.4, 0.8);
        }
      }
      > div > div[role="button"] {
        display: none;
        @media (max-width: $md) {
          display: flex;
          flex-shrink: 0;
          border-radius: 0;
          background-color: transparent;
          border: none;
          color: $black;
          font-family: $semibold;
          font-size: 17px;
          line-height: 23px;
          height: 64px;
          justify-content: center;
          border-top: 1px solid $greyXLight;
          &:focus {
            border-color: $greyXLight;
            background-color: $page;
            &::before {
              content: none;
            }
          }
          :global(.platform-desktop) & {
            &:hover {
              background-color: $page;
              border-color: $greyXLight;
            }
          }
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            &:active {
              border-color: $greyXLight;
              background-color: $page;
            }
          }
        }
        @media (max-width: $md) {
          svg {
            position: relative;
            width: 12px;
            height: 12px;
            order: 2;
            right: 7px;
            position: relative;
            * {
              fill: $black;
            }
          }
        }
        @media (max-width: $md) {
          span {
            position: relative;
            display: inline-flex;
            align-items: center;
            order: 1;
            &::after {
              content: "";
              height: 24px;
              width: 24px;
              position: relative;
              display: block;
              box-sizing: border-box;
              border-radius: 50%;
              right: -8px;
              border: 1px solid $greyXXXDark;
            }
          }
        }
      }
      &-inner {
        @media (max-width: $md) {
          overflow-y: scroll;
          overflow-x: hidden;
          height: calc(100vh - 153px);
          padding-bottom: 40px;
          box-sizing: border-box;
        }
      }
    }
    &-container {
      @media (max-width: $md) {
        padding: 0 24px;
      }
      @media (max-width: 374px) {
        padding: 0 20px;
      }
    }
    > span {
      position: relative;
      z-index: 1;
    }
    &-link {
      color: $disabledXDark;
      font-family: $semibold;
      position: relative;
      display: inline-block;
      z-index: 1;
      cursor: pointer;
      transition: color ease-out 0.075s;
      &:focus {
        text-decoration: underline;
        color: $disabledXXDark;
      }
      :global(.platform-mobile) & {
        transition: none;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $disabledXXDark;
        }
        &:active {
          color: $disabledXXDark;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $disabledXXDark;
        }
      }
      .progress__track-stage--current & {
        color: $primary;
      }
      .progress__track-stage--valid & {
        color: $successDark;
        &:focus {
          color: $successXDark;
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $successXDark;
          }
          &:active {
            color: $successXXDark;
          }
        }
        :global(.platform-mobile) & {
          &:active {
            color: $successXXDark;
          }
        }
      }
    }
    &-stage {
      font-size: 16px;
      line-height: 20px;
      color: $disabledXDark;
      font-family: $regular;
      margin: 0 0 12px -2px;
      position: relative;
      @media (max-width: $lg) and (min-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: $md) {
        width: calc(100% + 41px + 32px);
        left: -55px;
        padding-left: 55px;
        height: 31px;
        border-bottom: 1px solid $greyXLight;
      }
      &:last-child {
        margin-bottom: 0;
        > * > span {
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 4px;
            background-color: $page;
            top: 18px;
            left: -25px;
            height: calc(100% - 20px);
            @media (max-width: $md) {
              left: 30px;
            }
          }
        }
        &.progress__track-stage--valid {
          > * > span {
            &::before {
              top: 17px;
              left: -25px;
              height: calc(100% - 18px);
              @media (max-width: $md) {
                left: 30px;
              }
            }
          }
        }
        &.progress__track-stage--current {
          > * > span {
            &::before {
              top: 24px;
              left: -25px;
              height: calc(100% - 25px);
              @media (max-width: $md) {
                left: 30px;
              }
            }
          }
        }
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -31px;
        top: 2px;
        height: 16px;
        width: 16px;
        background-color: $white;
        border: 4px solid $disabled;
        box-sizing: border-box;
        border-radius: 50%;
        z-index: 2;
        @media (max-width: $md) {
          left: 24px;
        }
      }
      &--valid {
        &::before {
          border: none;
          background-image: url("/static/images/form/icons/input-valid.svg");
          background-size: cover;
          background-repeat: no-repeat;
        }
        &::after {
          content: "";
          width: 4px;
          background-color: $successDark;
          position: absolute;
          left: -25px;
          height: 100%;
          top: 15px;
          z-index: 2;
          @media (max-width: $md) {
            left: 30px;
          }
        }
        &:last-child::after {
          content: none;
        }
      }
      &--current {
        color: $primary;
        font-family: $bold;
        margin-top: 14px;
        &:first-child {
          margin-top: 0;
        }
        &::before {
          left: -37px;
          top: -4px;
          height: 28px;
          width: 28px;
          background-image: none;
          background-color: $white;
          border: 2px solid $primary;
          box-sizing: border-box;
          @media (max-width: $md) {
            left: 18px;
          }
        }
        > span > span::after,
        > span::before,
        > span::after {
          content: "";
          position: absolute;
          background-color: $primary;
          width: 4px;
          height: 4px;
          top: 8px;
          left: -25px;
          z-index: 2;
          border-radius: 50%;
          left: 0;
          transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          animation: pulse 0.66s ease-in-out infinite alternate;
          -webkit-animation: pulse 0.66s ease-in-out infinite alternate;
          @media (max-width: $md) {
            left: 18px;
          }
        }
        > span::before {
          left: -31px;
          @media (max-width: $md) {
            left: 24px;
          }
        }
        > span > span::after {
          left: -25px;
          animation-delay: 0.22s;
          -webkit-animation-delay: 0.22s;
          @media (max-width: $md) {
            left: 30px;
          }
        }
        > span::after {
          left: -19px;
          animation-delay: 0.44s;
          -webkit-animation-delay: 0.44s;
          @media (max-width: $md) {
            left: 36px;
          }
        }
      }
      &.progress__track-stage--valid {
        color: $successDark;
        &::before {
          border-color: $successXDark;
          background-color: $successDark;
        }
        > span > span::after,
        > span::before,
        > span::after {
          background-color: $white;
          animation-name: pulseFullOpacity;
          -webkit-animation-name: pulseFullOpacity;
        }
      }
    }
    &-inner {
      margin: 9px 0 -1px;
      display: none;
      position: relative;
      padding-left: 28px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        height: calc(100% - 4px);
        width: 4px;
        background-color: $grey;
        top: 2px;
        left: 7px;
      }
      .progress__track-stage--current & {
        display: block;
      }
    }
  }
}
@keyframes pulse {
  0% {
    transform: scale(1.25);
    -ms-transform: scale(1.25);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    -ms-transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    -ms-transform: scale(1);
    opacity: 0.75;
  }
}

@keyframes pulseFullOpacity {
  0% {
    transform: scale(1.25);
    -ms-transform: scale(1.25);
  }
  50% {
    transform: scale(1);
    -ms-transform: scale(1);
  }
  100% {
    transform: scale(1);
    -ms-transform: scale(1);
  }
}
