@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.link {
  display: inline-block;
  color: $primary;
  font-size: 16px;
  line-height: 20px;
  font-family: $regular;
  @include no-user-select;
  cursor: pointer;
  transition: color 0.5s;
  @media (max-width: 920px) {
    font-size: 15px;
  }
  &--disabled {
    color: $disabled !important;
    pointer-events: none;
    @include no-user-select;
  }
}
