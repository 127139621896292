@import "resources/sass/variables.scss";

.medicaid-client-details {
  display: flex;
  flex-direction: column;
  padding-left: 5px;

  &__attribute-heading {
    color: $greyXXDark;
    font-size: 14px;
  }

  &__attribute-value {
    font-family: $semibold;
  }
}