.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/static/images/form/icons/spinner.gif") !important;

  &--x-small {
    width: 20px;
    height: 20px;
  }

  &--small {
    width: 30px;
    height: 30px;
  }
}