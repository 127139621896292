@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.plan-selector-summary {
  background-color: $white;
  border: 1px solid $greyXLight;
  padding: 0 80px 67px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-width: 0;
  margin: 0 0 24px;
  @media (max-width: 1150px) {
    padding: 0 60px 67px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: $tertiary;
    border: 1px solid $tertiaryXDark;
    border-radius: 4px;
    padding: 64px 80px;
    @media (max-width: 1150px) {
      padding: 64px 60px;
    }
  }
  &__breakdown {
    font-size: 20px;
    line-height: 27px;
    font-family: $regular;
    color: $text;
    padding-right: 24px;
    @include no-user-select;
    .plan-selector-summary--disabled & {
      color: $disabledDark;
    }
    &-cost {
      font-size: 24px;
      line-height: 33px;
      font-family: $semibold;
      color: $text;
      margin-left: auto;
      opacity: 0.6;
      @include no-user-select;
      .plan-selector-summary--disabled & {
        color: $disabledDark;
      }
    }
  }
  &__total {
    font-size: 24px;
    line-height: 33px;
    font-family: $bold;
    color: $text;
    padding-right: 24px;
    margin: 52px 0 21px;
    @include no-user-select;
    .plan-selector-summary--disabled & {
      color: $disabledDark;
    }
    &-cost {
      font-size: 32px;
      line-height: 44px;
      font-family: $bold;
      color: $text;
      margin-left: auto;
      @include no-user-select;
      .plan-selector-summary--disabled & {
        color: $disabledDark;
      }
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      height: 1px;
      top: 13px;
      left: 0;
      width: 100%;
      background-color: $tertiaryXDark;
      margin: 0 0 30px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 15px;
  }
  div[role="button"] {
    height: 80px;
    font-size: 24px;
  }
}
