@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

$input-height: 122px;
$input-height-mobile: 98px;
$sidebar-mobile-padding-top: 8px;
$results-mobile-height: 40%;
$results-mobile-height-vh: 40vh;
$sidebar-min-width: 280px;
$sidebar-width: 25vw;

$header-height: (
  "default": 68px,
  "mobile": 57px
);

$zip-search-height-mobile: 98px;

$footer-height: 74px;


.medicaid-lead-map-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.medicaid-lead-map-side-panel {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.map {
  width: 100%;
  height: 100%;

  .map-container {
    
    width: 100%;
    height: 100%;
  }
}

.zip-search {
  display: flex;
}

.result-list-panel {
  padding: 10px;
}