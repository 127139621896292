@import "resources/sass/variables.scss";

.partner-detail {
  &__buttons {
    > * {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
