@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

$input-height: 122px;
$input-height-mobile: 104px;
$sidebar-mobile-padding-top: 8px;
$results-mobile-height: 40%;
$results-mobile-height-vh: 40vh;
$sidebar-min-width: 280px;
$sidebar-width: 25vw;

$header-height: (
  "default": 68px,
  "mobile": 57px
);

$zip-search-height-mobile: 98px;

$footer-height: 74px;


.root {
  height: calc(100%);

  @media (max-width: $md) {
    // we may need to ensure the user can scroll to footer but it doesn't take up too much space
    // 100% - header - enough to tap and scroll
    min-height: calc(100vh - 57px - 48px);
    overflow: hidden;
  }

  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.zip-search {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  z-index: 2;
  align-items: center;
  background-color: $white;
  padding: 16px;
  border-right: solid 1px $primary;

  > * {
    max-width: 500px;
  }

  &--center {
    border: solid 1px $primary;
    box-shadow: 0 0 8px $greyDark;
    width: 80%;
    max-width: 500px;
  }

  &--side {
    width: 25%;
    max-width: 500px;
    min-width: 280px;
    height: $input-height;

    input {
      text-align: left;
    }

    @media (max-width: $md) {
      border-bottom: solid 1px $primary;
      border-right: none;
    }
  }

  @media (max-width: $md) {
    height: $input-height-mobile;
    max-width: none;
    padding: 0;

    &--side {
      width: 100%;
    }

    > * {
      max-width: none;
    }
  }
}

.result-list-panel {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 25%;
  min-width: 280px;
  max-width: 500px;
  padding: $input-height 0 0 0;
  background-color: $white;
  height: 100%;
  z-index: 1;

  &--hidden {
    left: -564px;
  }

  @media (max-width: $md) {
    width: 100%;
    top: auto;
    left: auto;
    bottom: 0;
    padding-top: $sidebar-mobile-padding-top;
    height: $results-mobile-height;
    max-width: none;
    border-top: solid 1px $primary;

    &--hidden {
      bottom: -$results-mobile-height;
      left: 0;
    }
  }
}

// Here calc and vh is used instead of height: 100% since height 100% is broken on iOS < 14.5
.map {
  width: 100%;
  height: 100%;
  transition: height 0.5s;

  .map-container {
    width: 100%;
    height: calc(100vh - #{map-get($header-height, "default")} - #{$footer-height});
  }

  &--results-visible {
    width: calc(100vw - max(#{$sidebar-width}, #{$sidebar-min-width}));
    margin-left: calc(max(#{$sidebar-width}, #{$sidebar-min-width}));
  }

  @media (max-width: $md) {

    .map-container {
      height: calc(100vh - #{map-get($header-height, "mobile")});
    }

    &--results-visible {
      width: 100vw;
      margin-top: $input-height-mobile;
      margin-left: 0;
      height: calc(100% - #{$input-height-mobile} - #{$results-mobile-height});

      .map-container {
        height: calc(100vh - #{map-get($header-height, "mobile")} - #{$results-mobile-height-vh} - #{$sidebar-mobile-padding-top} - #{$input-height-mobile});
      }
    }
  }
}
