@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.price-detail {
  padding-bottom: 16px;

  &__line {
    text-align: center;
    font-size: 0.9em;
  }
}
