@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.login {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  background-color: $page;
  @include no-user-select();
  z-index: 1300;

  &__error {
    font-family: $regular;
    color: $alert;
    margin: -16px 0 32px;
  }

  &__col {
    width: 100%;
    flex-basis: 504px;
    max-width: 504px; //35%
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    box-sizing: border-box;
    background-color: $white;

    @media (max-width: 1240px) {
      flex-basis: 428px;
      max-width: 428px;
    }

    @media (max-width: 767px) {
      flex-basis: 100%;
      max-width: 100%;
    }

    header {
      padding: 12px;
    }

    > svg {
      min-width: 320px;
      background-repeat: no-repeat;
      background-size: 320px 58px;
      background-position: 50%;
      height: 120px;
      background-color: $primaryDark;
      width: 100%;
      margin-top: auto;

      @media (max-width: 767px) {
        background-size: 280px 50px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__container {
    padding: 14px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    flex-grow: 1;
    min-width: 280px;
    display: flex;
    align-items: center;

    @media (max-width: 1110px) {
      padding-left: 48px;
      padding-right: 48px;
    }

    @media (max-height: 960px) {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    @media (max-height: 810px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @media (max-width: 660px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 510px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 360px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:last-child {
      margin-bottom: auto;
    }

    > div {
      width: 100%;

      > svg {
        width: 121px;
        height: 35px;
        margin-bottom: 32px;

        @media (max-height: 930px) {
          margin-bottom: 20px;
        }

        @media (max-height: 812px) {
          margin-bottom: 18px;
        }

        path {
          fill: $primary;
        }
      }
    }
  }

  &__powered-by {
    min-width: 320px;
    height: 120px;
    background-color: $primaryDark;
    width: 100%;
    margin-top: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @media (max-height: 930px) {
      height: 110px;
    }

    @media (max-height: 812px) {
      height: 90px;
    }

    justify-content: center;

    &:last-child {
      margin-bottom: 0;
    }

    > svg {
      flex-shrink: 0;
      width: 233px;
      height: 24px;

      * {
        fill: $white;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    padding: 24px 24px;
    background-color: #ebebeb;
    width: 100%;
    flex-basis: calc(100%);
    max-width: calc(100%);
    height: 100%;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: 1240px) {
      flex-basis: calc(100%);
      max-width: calc(100%);
    }

    a {
      cursor: pointer;
    }

    h2 {
      margin-top: 12px;
      text-align: center;
      color: $black;
      font-size: 32px;
      z-index: 101;
      position: relative;
    }

    form {
      padding: 1px;
    }

    h3 {
      margin-top: 12px;
      text-align: center;
      color: $black;
      font-size: 15px;
      z-index: 101;
      position: relative;
      margin-bottom: 24px;
    }

    &__logo-1 {
      display: block;
      position: relative;
      width: 154px;
      margin: auto;
      pointer-events: none;
      z-index: 100;

      path {
        fill: $primary;
      }

      polygon.aturna-logo-polygon-1 {
        fill: #f1f1f1 !important;
      }

      polygon.aturna-logo-polygon-2 {
        fill: #f1f1f1;
      }
    }

    &__logo-2 {
      position: absolute;
      top: -10%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      pointer-events: none;
      z-index: 99;
      opacity: 0.1;
      transform: scale(2);

      polygon {
        fill: $primaryLight;
      }

    }
  }

  &__form {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    width: 572px;
    padding: 24px;

    label {
      font-size: 12px;
      margin-bottom: 6px;
    }

    margin: auto;

    @media (max-width: 767px) {
      width: 508px;
    }

    @media (max-width: 620px) {
      width: 450px;
    }

    @media (max-width: 570px) {
      width: 350px;
    }

    @media (max-width: 480px) {
      width: 300px;
    }

    @media (max-width: 380px) {
      width: 260px;
    }

    @media (max-width: 340px) {
      width: 200px;
    }
  }

  &__input_container {
    width: 100%;
  }

  &__input_input {
    margin-bottom: 14px;
  }

  &__input_checkbox_div {
    text-align: center;
    margin-bottom: 18px;
  }

  &__input_button_container {
    text-align: center;
  }

  &__submit_button {
    display: block;
    width: 100%;
    height: 48px;
    /* UI Properties */
    background: #281E50 0 0 no-repeat padding-box;
    border: 1px solid #C4C4C4;
    background-color: #490fad!important;
    border-radius: 8px;
    opacity: 1;
    color: white;
    margin: 0 auto;
  }

  &__submit_button:disabled {
    background: lightgray!important;
  }

  &__forgotten_password_confirmation_message {
    color: green;
    height: 24px;
  }

  &__forgotten_password_confirmation_div {
    text-align: center;
    margin-top: 12pt;
  }

  &__error_message {
    color: red;
    font-size: 10pt !important;
  }

  &__error_message_container {
    text-align: center;
    margin-bottom: 6pt;
  }

  &__h3_hasCont {
    margin-bottom: 0 !important;
  }

  &__h3_isCont {
    margin-top: 6pt !important;
  }

  &__input_separator_container {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
  }

  &__input_separator_left {
    width: 40%;
  }

  &__input_separator_middle {
    text-align: center;
    width: 20%;
  }

  &__input_separator_right {
    width: 40%;
  }
}
