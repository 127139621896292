@import "resources/sass/variables.scss";

.DropArea {
  padding: 1em 3em;
  margin: 1em 0;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 2px $primaryLight;
  border-radius: 5px;
  cursor: pointer;

  > p {
    color: $primaryXDark;
    font-size: initial !important;
    text-align: center;
  }
}

.CropWrapper {
  &__actions {
    margin: 0.2em 0 1em;
    text-align: center;
  }

  > p {
    color: $primaryXDark;
    text-align: right;
  }
}

.explainer {
  margin-top: -0.5em;
  text-align: center;
  font-size: 14px;
  color: $greyXXDark;
}
