@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.party-list {
  flex-basis: calc(100% - 8px);
  max-width: calc(100% - 8px);
  margin: 0 4px 25px;
  
  &__list {
    margin-bottom: 16px;
  }

  &__alert {
    margin-bottom: 8px;
  }
}

.add-party-btn-wrapper {
  margin: 10px 0;
}