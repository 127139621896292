@import "resources/sass/variables.scss";

.consumer-medicaid-lead-item {
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin: 0 0 8px;
  background-color: $tertiary;
  border: 1px solid $tertiaryXDark;

  @media (max-width: 1240px) {
    border-radius: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: $primaryXDark;
    font-family: $title;
    
    &__icon {
      height: 64px;
      width: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 0 16px 0 0;
      flex-shrink: 0;
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        height: 48px;
        width: 48px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        height: 40px;
        width: 40px;
        margin: 0 12px 0 0;
      }
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        background-color: $primaryLight;
        z-index: 0;
        opacity: 0.4;
      }
      svg {
        position: relative;
        z-index: 1;
        @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
          height: 26px;
          width: 29px;
        }
        @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
          height: 22px;
          width: 25px;
        }
        > * {
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            fill: $primary;
          }
          &:nth-child(1),
          &:nth-child(3) {
            fill: $primaryLight;
            opacity: 0.4;
          }
          &:nth-child(2) {
            fill: $primaryLight;
            opacity: 0.4;
          }
        }
      }
      .client-dashboard-products-item--complete & {
        &::before {
          background-color: $secondary;
          opacity: 1;
        }
        svg {
          > * {
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
              fill: $primaryXDark;
            }
            &:nth-child(1),
            &:nth-child(3) {
              fill: $primaryLight;
              opacity: 1;
            }
            &:nth-child(2) {
              fill: $white;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  &__progress {
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;

    &__title {
      font-family: $semibold;
    }

    &__value {
      color: $orange;
      font-size: 24px;
      line-height: 33px;
      font-family: $bold;
      color: $primary;
      margin: 0 0 15px;
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        font-size: 20px;
        line-height: 28px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  &__status {
    display: flex;
    flex-direction: column;
    width: 300px;

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__questions-completed {
    margin-right: 80px;
  }

  &__jurisdiction {
    width: 20px;
    height: 20px;
    padding: 1em;
    border-radius: 50%;
    background-color: $white;
    font-family: $bold;
    color: $disabledXDark;
    margin-left: 16px;
  }

  &__description {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: $semibold;
  }

  &__actions {
    margin-top: 10px;
    text-align: center;
  }
}