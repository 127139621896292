@import "resources/sass/variables.scss";

.list {
  &__actions {
    display: flex;
    flex-direction: column;
    text-align: center;

    > :not(:last-child) {
      white-space: nowrap;
    }

    > :not(:first-child) {
      margin-top: 5px;
    }

    &__coupled-label {
      color: $disabled;
    }
  }

  &__small-text-btn {
    font-size: 15px;
  }

  &__links {
    text-align: center;
  }

  &__archived {
    font-weight: bold;
    color: $alert;
  }
}
