:root{
  --color-primary-dark: #00334E;
  --color-primary: #145374;
  --color-primary-light: #5588A3;
  
  --color-link-pressed: #1C3946;
  --color-link-hover: #376472;
  --color-link-focused: #376472;
  --color-link-primary: #5C9EAD;
  
  --color-dark-grey: #333333;
  --color-medium-grey: #C6C6C6;
  --color-light-grey: #E8E8E8;
  --color-white: #FFFFFF;
  
  --color-validation-error: #EB5757;
  --color-validation-success: #27AE60;
}