@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5em;
  
  h1 {
    font-weight: bold;
    color: $success;
  }
  
  h2 {
    color: $success;
  }

  &__next-action {
    margin-top: 1em;
    max-width: 700px;
    text-align: center;
    color: $greyXXXDark;

    &__message {
      margin-bottom: 2em;
    }
  }

}

.mood {

  &__neutral {
    > h1, h2 {
      color: $primary;
    }
  }

  &__success {
    > h1, h2 {
      color: $secondaryDark;
    }
  }

}