@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

$grid-spacing: 8px;

@mixin md {
  @media (min-width: 630px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1050px) {
    @content;
  }
}

.not-found {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  z-index: 99999;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  @include lg {
    text-align: left;
  }

  &__container {
    padding: 20px;
    padding-bottom: 30px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -$grid-spacing;
  }

  &__grid-item {
    padding: $grid-spacing;
    width: 100%;

    @include lg {
      width: auto;
    }

    &--text {
      max-width: 620px;
    }
    &--picture {
      display: flex;
      justify-content: center;

      @include lg {
        max-width: 400px;
      }
    }
  }

  &__logo {
    margin-bottom: 40px;

    * {
      fill: $white;
    }

    svg {
      width: 150px;
      height: 39px;

      @include md {
        width: 200px;
        height: 58px;
      }

      @include lg {
        width: 270px;
        height: 78px;
      }
    }
  }

  &__headline {
    color: $secondary;
    font-size: 7rem;
    font-family: $bold;
    line-height: 1;
    margin-bottom: 30px;

    @include md {
      font-size: 12rem;
    }
    @include lg {
      font-size: 18rem;
      margin-bottom: 5px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 1.5rem;
    color: $secondary;
    font-family: $semibold;
    vertical-align: center;

    @include md {
      font-size: 2rem;
    }
  }

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  &__text-link {
    display: block;
    margin-top: 10px;

    @include md {
      display: inline-block;
      margin-top: -17px;
      margin-bottom: -17px;
      margin-left: 4px;
      vertical-align: middle;
    }

    svg {
      width: 200px;
      height: 51px;
    }
  }

  &__image {
    max-width: 200px;

    @include lg {
      max-width: none;
      margin-left: -27%;
      margin-right: -27%;
    }
  }
}
