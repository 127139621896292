@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.relationship-repeater-item {
  border-style: solid;
  background-color: $tertiary;
  border-color: $greyXLight;
  border-width: 1px 1px 1px 0;
  padding: 16px 16px 16px 24px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  margin: 0 0 8px;
  @media (max-width: 920px) {
    padding-left: 18px;
  }
  fieldset:nth-child(even) & {
    background-color: $white;
  }
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 4px;
    height: calc(100% + 2px);
    background-color: $primary;
  }
  &__buttons {
    margin-left: auto;
    display: flex;
    flex-shrink: 0;
    padding-left: 18px;
    @media (max-width: 920px) {
      padding-left: 0;
      margin-top: 11px;
    }
    > * {
      margin-left: 12px;
      padding: 0 24px;
      &:first-child {
        margin-left: 0;
      }
      @media (max-width: 1072px) {
        padding: 0 14px;
        > svg:not(:only-child) {
          margin-right: 7px;
        }
      }
    }
  }
  &__row {
    display: flex;
    align-items: center;
    @media (max-width: 920px) {
      display: block;
    }
    > .relationship-repeater-item__row {
      @media (max-width: 920px) {
        display: flex;
        min-height: 40px;
        margin: 0;
      }
      @media (max-width: 374px) {
        min-height: 36px;
      }
    }
    > svg {
      height: 46px;
      width: 46px;
      left: 20px;
      top: 11px;
      position: absolute;
      flex-shrink: 0;
      @media (max-width: 920px) {
        height: 40px;
        width: 40px;
        left: auto;
        top: auto;
        position: relative;
      }
      @media (max-width: 374px) {
        height: 36px;
        width: 36px;
      }
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -4px;
    margin-bottom: -5px;
    @media (max-width: 920px) {
      padding-left: 14px;
    }
  }
  &__name {
    font-size: 20px;
    line-height: 27px;
    color: $text;
    font-family: $bold;
    @media (max-width: 1240px) {
      font-size: 19px;
      line-height: 26px;
    }
    @media (max-width: 920px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  &__info {
    font-size: 14px;
    line-height: 20px;
    color: $primary;
    font-family: $bold;
    margin-top: 3px;
    @media (max-width: 920px) {
      font-size: 13px;
      line-height: 18px;
    }
    &:last-child {
      margin-bottom: 3px;
      @media (max-width: 920px) {
        margin-bottom: 0;
      }
    }
  }
  &__label {
    font-family: $semibold;
    color: $greyXXDark;
  }
  &__list {
    margin-top: 16px;
    .relationship-repeater-item {
      background-color: $white;
    }
    fieldset:nth-child(even) & {
      background-color: $white;
      .relationship-repeater-item {
        background-color: $tertiary;
      }
    }
  }
}
