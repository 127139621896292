@import "resources/sass/variables.scss";

.animated-loading-overlay {
  color: $primary;

  &.large, &.medium {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;

    & > span[role="progressbar"] {
      margin-right: 24px;
    }
  }

  &.medium {
    font-size: 16px;
    
    & > span[role="progressbar"] {
      margin-right: 12px;
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}