@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard-products-item-history {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0;
  width: 100%;
  &__row {
    display: flex;
    position: relative;
  }
  &__list {
    margin-top: 24px;
    border: 1px solid $tertiaryXDark;
    border-left: 2px solid $success;
  }
  &__item {
    display: flex;
    background-color: $white;
    box-sizing: border-box;
    min-height: 42px;
    &:not(:last-child) {
      border-bottom: 1px solid $tertiaryXDark;
    }
    &-col {
      padding: 15px 20px;
      text-align: left;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 1210px) {
        padding: 11px 12px;
      }
      &:nth-child(1) {
        padding-left: 23px;
        width: 40%;
        @media (max-width: 1210px) {
          padding-left: 15px;
        }
      }
      &:nth-child(2) {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
    &-title {
      font-family: $bold;
      color: $text;
      margin: 0;
      white-space: nowrap;
      font-size: 15px;
      line-height: 20px;
      @media (max-width: 1210px) {
        font-size: 14px;
      }
    }
  }
}
