@import "resources/sass/variables.scss";
@import "../../index.scss";
@import "../../styles/grid.module.scss";

.footer {
  background-color: $navy;
  color: $white;
  padding-top: 24px;
  z-index: 12;

  &__grid {
    justify-content: space-between !important;
    align-items: center;

    @media (max-width: $lg) {
      flex-direction: column-reverse;
    }
  }

  &__info-container {
    box-sizing: border-box;
    margin-bottom: 1.5rem;

    &__powered-by {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      @media (max-width: 560px) {
        text-align: center;
      }

      &__heading {
        font-size: 1.125rem;
        margin-bottom: 0;

        @media (max-width: 560px) {
          font-size: 1rem;
        }
      }

      &__subheading {
        font-size: 0.875rem;
        line-height: 1.25rem;

        &__partner-display-name {
          font-size: 1rem;
          display: inline-block;
          font-family: "F37Sonic Bold";
        }

        @media (max-width: 560px) {
          font-size: 0.6rem;
        }
      }

      img {
        display: inline-block;
        max-width: 100%;
        height: auto;
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      list-style-type: none;

      @media (max-width: $lg) {
        align-items: center;
        text-align: center;
      }

      &__general {
        padding-left: 0;

        &__heading {
          color: $secondary;
          font-family: "F37Sonic Bold";
          font-size: 1.2rem;

          @media (max-width: 560px) {
            font-size: 1rem;
          }
        }

        &__links {
          margin: -0.25rem;
          margin-top: 0.25rem;

          & > ol {
            display: flex;
          }

          @media (max-width: 560px) {
            font-size: 0.6rem;
          }
        }
      }

      &__law-firm {
        padding-left: 0;

        &__heading {
          color: $secondary;
          font-family: "F37Sonic Bold";
          font-size: 1.2rem;

          @media (max-width: 560px) {
            font-size: 1rem;
          }
        }

        &__links {
          margin: -0.25rem;
          margin-top: 0.25rem;

          & > ol {
            display: flex;
          }

          @media (max-width: 560px) {
            font-size: 0.6rem;
          }
        }
      }
    }

    &__copyright {
      font-size: 0.875rem;
      line-height: 1.25rem;
      opacity: 0.6;
      margin-top: 0.2rem;

      @media (max-width: $lg) {
        text-align: center;
      }
    }
  }

  &__img-container {
    box-sizing: border-box;

    @media (min-width: $sm) {
      width: 50%;
    }

  }

  div[role="button"] {
    margin: 0 10px;
    padding: 0;

    @media (max-width: 560px) {
      margin: 0 5px;
      font-size: 0.8rem;
    }

    @media (max-width: 390px) {
      font-size: 0.6rem;
    }
  }
}

.footer-link {
  padding: 0.5rem;
}

.footer-logo {
  cursor: pointer;
  p {
    color: $white;
    font-family: $bold;
    font-size: 22px;

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }
  svg {
    path {
      fill: $secondary;
    }
  }
}