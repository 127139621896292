@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.control {
  @media (max-width: 767px) {
    padding: 0 16px;
  }
  @media (max-width: 374px) {
    padding: 0 20px;
  }
  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: $greyLight;
    margin: 56px 0;
    @media (max-width: 1240px) {
      margin: 48px 0;
    }
    @media (max-width: 374px) {
      margin: 40px 0;
    }
  }
  &__container {
    display: flex;
    @media (max-width: 920px) {
      flex-direction: column;
    }

    > div[role="button"] {
      @media (max-width: 920px) {
        width: 100%;
        order: 2;
        height: 50px;

        &:not(:first-child) {
          margin-top: 12px;
        }
      }

      @media (min-width: 921px) {
        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }
  &__nav {
    margin-left: auto;
    @include no-user-select;
    display: flex;
    @media (max-width: 920px) {
      order: 1;
      margin: 0 0 12px 0;
      flex-direction: column;
    }
    > * {
      margin-left: 12px;
      @media (max-width: 920px) {
        width: 100%;
        margin: 0;
        height: 50px;
      }
      &:nth-child(1) {
        @media (max-width: 920px) {
          order: 2;
          margin-top: 12px;
        }
      }
      &:last-child {
        @media (max-width: 920px) {
          margin: 0;
        }
      }
    }
  }
}
