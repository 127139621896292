@import "resources/sass/variables.scss";

.GraphTooltip {
  background-color: rgba(255,255,255,.8);
  padding: 12px;
  border: solid 1px $primary;
  border-radius: 3px;
  max-width: 120px;

  > p:not(:first-child) {
    margin-top: 10px;
  }

  &__label {
    text-align: center;
  }

  &__desc {    
    > span {
      font-family: $bold;
      color: $primary
    }
  }
}

.GraphWrapper {
  width: 100%;
  height: 400px;
  background-color: transparent;
}

.Graph__Bottom__tick {
  fill: $alert;
}