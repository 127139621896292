@import "resources/sass/variables.scss";

.Switch {
    width: 56px;
    height: 32px;
    border-radius: 32px;
    display: inline-block;
    border: 1px solid $black;
    box-sizing: border-box;
    transition: border-color 0.5s;
    cursor: pointer;
    position: relative;
    vertical-align: middle;

    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: $primaryDark;
      border-radius: 50%;
      top: 3px;
      left: 3px;
      transform: translateX(0);
      -ms-transform: translateX(0);
      transition: border-color 0.5s, transform 0.5s, -ms-transform 0.5s;
    }

    &--selected {
      border-color: $black;
      &::before {
        background-color: $successDark;
        transform: translateX(24px);
        -ms-transform: translateX(24px);
      }
    }

    &--disabled {
      border-color: $disabledDark;
      &::before {
        background-color: $disabledDark;
      }
    }
  }