@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.planning-disclaimer-short {
  margin: 20px 40px;
  text-align: center;
  font-size: 1rem;

  &__link:hover {
    text-decoration: underline;
  }
}

.disclaimer-modal-content {
  section:not(:last-child) {
    margin-bottom: 1.5em;
  }

  ul,
  ol {
    margin: 1.5em 0;
    padding-left: 2em;
  }

  li {
    margin-bottom: 0.5em;
  }
}
