@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.paginated-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
  @include no-user-select;
  &--grid {
    flex-direction: row;
  }
  @media (max-width: 1240px) {
    margin: 0 -25px;
  }
  @media (max-width: 920px) and (min-width: 768px) {
    margin: 0 -21px;
  }
  @media (max-width: 374px) {
    margin: 0;
  }
  &-empty {
    background-color: $tertiary;
    border: 1px solid $tertiaryXDark;
    border-radius: 0px 4px 4px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    padding: 32px;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    font-family: $bold;
    color: $text;
    @media (max-width: 1240px) {
      border-radius: 0;
    }
    @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
      font-size: 17px;
      line-height: 24px;
    }
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 16px;
      line-height: 22px;
    }
    &::before {
      content: "";
      background-color: $primary;
      width: 3px;
      height: calc(100% + 2px);
      position: absolute;
      top: -1px;
      left: -1px;
      display: block;
    }
  }
  &--white {
    .paginated-list-empty {
      background-color: $white;
    }
  }
  &__table {
    border-spacing: 0;
    width: 100%;
    thead {
      background-color: $primaryXLight;
    }
    th {
      font-size: 14px;
      padding: 0.5em 1em;
      color: $primary;
      height: 2em;
      &:first-child {
        border-radius: 5px 0 0 0;
      }
      &:last-child {
        border-radius: 0 5px 0 0;
      }
    }
    &__th__up, &__th__down, &__th__default, &__th__none, &__th__reset {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center right;
    }
    &__flex_container {
      display: flex;
      align-items: center;
    }
    &__flex_child_left {
      width: auto;
      text-align:center;
    }
    &__flex_child_right {
      width: auto;
      text-align:left;
    }
    &__flex_child_reset {
      text-align: right;
      width: 100%;
    }
    td {
      padding: 0.5em 1em;
    }
    th svg {
        font-size: 25px;
        color: $primaryDark;
    }
    tr:nth-child(even) {
      background-color: $greyXXLight;
    }
  }
}
