@import "resources/sass/variables.scss";

.package-offering {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  padding: 1rem;
  height: 350px;

  .best-deal {
    float: right;
    position: relative;
    color: $black;
    font-size: 16px;
    margin-left: 100px;
    padding: 4px 6px;
    background-color: $secondary;
    white-space: nowrap;
    border-radius: 0.25rem;
  }

  &__description {
    padding: 0.75rem;
  }

  &__items {
    li {
      justify-content: flex-start;
      padding: 0.5rem;
      padding-left: 1rem;
    }

    svg {
      height: 30px;
      width: auto;
    }
  }


  h5 {
    font-size: 1rem;
    font-family: $bold;
    color: $text;
  }

  &__button-container {
    text-align: right;
  }
  
}