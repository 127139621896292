@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard-contacts {
  display: block;
  margin-top: -18px;
  @include no-user-select;
  @media (max-width: 374px) {
    padding: 0 20px;
  }
  &__row {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    &:first-child {
      margin-top: 34px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid $greyXLight;
    margin-bottom: 22px;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      padding-bottom: 15px;
      margin-bottom: 16px;
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
  }
  &__image {
    height: 64px;
    width: 64px;
    margin-right: 16px;
    border: 1px solid $greyXLight;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    background-image: url("/static/images/avatar/placeholder-avatar.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $primaryXDark;
    flex-shrink: 0;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      height: 52px;
      width: 52px;
      margin-right: 12px;
    }
  }
  &__name {
    font-size: 18px;
    line-height: 20px;
    color: $text;
    font-family: $bold;
    margin-bottom: 3px;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  &__job-title {
    font-size: 18px;
    line-height: 20px;
    font-family: $semibold;
    color: $primary;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  &__icon {
    height: 40px;
    width: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px 0 0;
    flex-shrink: 0;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      height: 36px;
      width: 36px;
      margin: 0 12px 0 0;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border-radius: 50%;
      background-color: $primaryLight;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.4;
    }
    svg {
      position: relative;
      z-index: 1;
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        height: 20px;
        width: 20px;
      }
      path {
        fill: $primary;
      }
    }
  }
  &__text {
    font-size: 16px;
    line-height: 20px;
    font-family: $semibold;
    color: $text;
    @include user-select;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    font-family: $semibold;
    color: $primary;
    margin: 16px 0 3px;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 13px;
      line-height: 19px;
      margin-top: 12px;
    }
  }
  &__footer {
    display: flex;
    margin: 15px -6px 0;
    @include no-user-select;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      flex-wrap: wrap;
      margin: 11px 0 0;
    }
    div[role="button"] {
      max-width: calc(50% - 12px);
      flex-basis: calc(50% - 12px);
      margin: 0 6px;
      @media (max-width: 1240px) {
        font-size: 17px;
      }
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        height: 50px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0 0 12px;
        height: 45px;
      }
      &:last-child {
        @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
          margin: 0;
        }
      }
      &:first-child:last-child {
        max-width: none;
        flex-basis: 100%;
      }
    }
  }
}
