@use "sass:color";

// Fonts
$title: "F37Sonic Bold", arial, sans-serif;
$bold: "F37Sonic Medium", arial, sans-serif;
$semibold: "F37Sonic Regular", arial, sans-serif;
$regular: "F37Sonic Light", arial, sans-serif;

// Aturna Colours
$purple: #8200DC;
$navy: #281E50;
$green: #AAF08C;
$grey: #EBEBEB;
$white: #FFFFFF;
$black: #000000;

// Primary Colours
$primary: $purple;
$primaryXLight: color.scale($primary, $lightness: 90%);
$primaryLight: color.scale($primary, $lightness: 75%);
$primaryDark: color.scale($primary, $lightness: -25%);
$primaryXDark: color.scale($primary, $lightness: -33%);

// Secondary Colours
$secondary: $green;
$secondaryLight: color.scale($secondary, $lightness: 75%);
$secondaryDark: color.scale($secondary, $lightness: -40%);
$secondaryXDark: color.scale($secondary, $lightness: -66%);
$secondaryXXDark: color.scale($secondary, $lightness: -75%);

// Tertiary Colours
$tertiary: $grey;
$tertiaryLight: color.scale($tertiary, $lightness: 75%);
$tertiaryDark: color.scale($tertiary, $lightness: -5%);
$tertiaryXDark: color.scale($tertiary, $lightness: -50%);

$navyDark: color.scale($navy, $lightness: -50%);

// Shades
$greyXXXDark: #333333;
$greyXXDark: #999999;
$greyXDark: #a7a7a7;
$greyDark: #a5a5a5;
$greyLight: #ced3dc;
$greyXLight: #e8e8e8;
$greyXXLight: #f6f6f6;

// Product Page Colours
$orange: #eba757;
$maroon: #882323;

// Success Colours
$success: $secondary;
$successDark: $secondaryDark;
$successXDark: $secondaryXDark;
$successXXDark: $secondaryXXDark;

// Alert Colours
$alertXDark: #882323;
$alertDark: #bf3232;
$alert: #eb5757;
$alertPastelDark: rgba($alert, 0.7);
$alertPastel: rgba($alert, 0.5);
$alertPastelLight: rgba($alertDark, 0.1);
$alertPastelXLight: #f5dddd;
$alertPastelXXLight: #ffefef;
$alertPastelXXXLight: #fef7f7;

// Disabled Colours
$disabled: #c6c6c6;
$disabledDarkSolid: #c1c1c1;
$disabledDark: rgba($greyXXXDark, 0.3);
$disabledXDark: rgba($greyXXXDark, 0.5);
$disabledXXDark: rgba($greyXXXDark, 0.7);

// Page Colour
$page: $tertiaryLight;

// Text Colour
$text: $black;

// Modal
$modalOverlay: rgba($primaryXDark, 0.4);

// 2-column layout
$sidebar-width: (
  "default": 230px,
  1240: 222px,
  920: 202px,
);
$sidebar-left-margin: (
  "default": 32px,
  1240: 24px,
  920: 12px,
);

// Breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

// Material UI Breakpoints
$muixs: 0px;
$muism: 600px;
$muimd: 900px;
$muilg: 1200px;
$muixl: 1536px;