@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  color: white;

  &--multiline {
    flex-direction: column;

    > svg {
      margin: 0 0 3px !important;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  svg {
    flex-shrink: 0;
    height: 24px;
    margin-left: auto;
    position: relative;

    @media (max-width: 767px) {
      margin-right: auto;
    }
  }

  &__powered_by {
    width: 100%;
  }

  &__empowered {
    > span {
      font-weight: bold;
    }
  }
}
