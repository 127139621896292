@import "resources/sass/variables.scss";

.back-link {
  cursor: pointer;

  &__icon {
    margin-right: 10px;
    position: relative;
    top: -1px;

    path {
      fill: $primary;
    }
  }
}
