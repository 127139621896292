@import "resources/sass/variables.scss";

.accepted-medicaid-lead-preview-item {
  display: flex;
  position: relative;
  width: 100%;
  padding: 6px 32px;
  background-color: $navy;
  color: $white;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 8px;

  border: solid 1px $greyXXXDark;

  &__date {
    position: absolute;
    bottom: 0;
    right: 0;
    color: $greyDark;
    padding: 8px;
    font-size: 14px;
  }

  &__client-name {
    width: 250px;
  }

  &__action {
    div[role="button"] {
      background-color: $grey;
      color: $primary;
      border-color: $grey;

      &:hover {
        background-color: $white;
        border-color: $white;
      }
    }
  }

  &__client-data {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 16px;
    }

    &__value {
      font-family: $bold;
      color: $secondary;
      font-size: 24px;
    }
  }
}