@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.advisor {
  > h1 {
    text-align: center;
    font-weight: bold;
    margin: 24px 0 48px;

    @media (max-width: 1240px) {
      font-size: 36px;
    }
    @media (max-width: 1024px) {
      font-size: 32px;
    }
    @media (max-width: 920px) {
      font-size: 30px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  > h2 {
    text-align: center;
    padding-bottom: 1rem;

    @media (max-width: 1240px) {
      font-size: 26px;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
    }
    @media (max-width: 920px) {
      font-size: 22px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  > h3 {
    text-align: center;
    font-style: italic;
    padding-bottom: 2rem;
  }
}
