@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.results-list {
  height: 100%;
  overflow-y: auto;
  border-right: solid 1px $primary;

  &--empty {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $grey;
  }

  @media (max-width: $md) {
    border-right: none;
  }

  & > ul {
    padding: 16px;
  }
}

.search-result {
  background-color: white;
  padding: 12px 12px 0 12px;
  margin-bottom: 8px;
  opacity: 1;
  border: solid 1px $greyDark;
  cursor: pointer;
  transition: opacity 1s, border-color 0.15s;
  box-shadow: 0px 0px 10px $greyXLight;

  &:hover,
  &--focused {
    border-color: $primary;
  }

  &--hidden {
    opacity: 0;
  }

  header {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    h1 {
      font-size: 14px;
      font-weight: bold;
      flex: 1;
      margin-right: 5px;
    }
  }

  &__distance {
    font-size: 12px;
    margin-top: 1px;
  }

  &__info {
    display: flex;
    flex-direction: row;

    &__icon {
      margin-right: 8px;
      height: 16px;
      width: 16px;

      path {
        fill: $primary;
      }
    }
  }

  &__address {
    font-size: 14px;
    margin: 8px 0;
  }

  &__contact {
    font-size: 14px;
    margin: 8px 0 12px 0;
  }

  &__actions {
    margin: 12px 0 10px 0;
    display: flex;

    * {
      font-size: 14px;
    }

    .search-result__action {
      margin-left: 16px;
      &:first-child {
        margin-left: 0;
      }

      button {
        text-transform: uppercase;
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 2px;
      }

      button:hover {
        text-decoration: none;
      }

      &__primary {
        button {
          display: flex;
          align-items: center;
        }
      }

      &__icon {
        margin-left: 3px;
        position: relative;
        height: 14px;
        width: 9px;
        top: 1px;

        path {
          fill: $secondary;
        }
      }
    }
  }
}
