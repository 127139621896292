@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.summary {
  > div {
    padding: 20px 32px 32px;
    border-radius: 0;
    border-bottom: none;
    @media (max-width: 1240px) {
      padding: 20px 24px 32px;
    }
    @media (max-width: 767px) {
      margin: 0;
    }
    > div {
      &:first-child {
        margin-bottom: 20px;
        @media (max-width: 374px) {
          padding: 0;
        }
      }
      &:only-child {
        margin-bottom: -12px;
      }
      > h2 {
        font-size: 28px;
        line-height: 33px;
        @media (max-width: 920px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
  > footer {
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    @media (max-width: 374px) {
      padding: 0 20px;
    }
    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background: $greyLight;
      margin: 56px 0;
    }
  }
}

.summary-page {
  margin: 0 -32px;
  position: relative;
  @media (max-width: 1240px) {
    margin: 0 -24px;
  }
  &::before {
    content: "";
    background-color: $greyXLight;
    height: 1px;
    width: calc(100% - 64px);
    position: absolute;
    left: 32px;
  }

  &__section {
    background-color: $white;
    padding: 32px;
    border-bottom: 1px solid $greyXLight;
    @media (max-width: 1240px) {
      padding: 24px;
    }
    
    .summary-page__section {
      background-color: $tertiary;
      > div > div {
        flex-basis: 100%;
        max-width: 100%;
        &:first-child {
          padding: 0;
        }
        &:last-child {
          font-family: $regular;
        }
      }
    }

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      margin-bottom: -32px;
      border: none;
    }

    .summary-page {
      margin: 33px 0 0;
      &::before {
        content: none;
      }
      &__section {
        border: 1px solid $greyXLight;
        border-radius: 4px;
        margin: 0 0 8px;
        &:first-child {
          padding-top: 32px;
        }
      }
    }

    > header {
      display: flex;
      align-items: flex-start;
      margin: 0 0 7px;
      + .summary-page {
        margin-top: 16px;
      }
      &:last-child {
        align-items: center;
        margin: -12px 0;
      }
      > h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: $bold;
        color: $primaryXDark;
        @media (max-width: 920px) {
          font-size: 20px;
          line-height: 27px;
        }
      }
      > div[role="button"] {
        margin-left: auto;
        padding: 0 22px;
        font-family: $semibold;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      margin: 0 0 14px;
      &:last-child {
        margin: 0;
      }
    }

    &__group-label {
      margin-bottom: 1em;
    }

    &__group {
       
      > h3 {
        font-size: 18px;
        font-family: $semibold;
      }

      &:not(:last-child) {
        border-bottom: solid 1px $greyXLight;
        padding-bottom: 1em;
        margin: 0 0 1em;
      }
    }

    &__answer {
      display: flex;
      margin-bottom: .5em;

      > div {
        max-width: 50%;
        flex-basis: 50%;
        font-size: 16px;
        line-height: 24px;
        box-sizing: border-box;
        @media (max-width: 920px) {
          font-size: 15px;
          line-height: 22px;
        }
        &:first-child {
          color: $primaryDark;
          padding-right: 16px;
        }
        &:last-child {
          font-family: $bold;
          color: $primaryXDark;
        }
        > span {
          color: $greyXXDark;
        }
      }
    }
  }
}

.summary-actions {
  background-color: $white;
  border: 1px solid $greyXLight !important;
  border-radius: 4px;
  @media (max-width: 767px) {
    margin: 0 -16px;
  }
  > h2 {
    font-size: 28px;
    line-height: 38px;
    color: $primaryXDark;
    font-family: $bold;
    margin: 5px 0 15px;
  }
  > ul {
    margin: 0 0 21px;
  }

  &__alert {
    margin-top: 24px;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
  }

  &__alert-btn {
    display: flex;
    justify-content: center;
  }

  &__confirm {
    margin-top: 24px;
  }
}
