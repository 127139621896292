@import "resources/sass/variables.scss";

.header-nav {
  display: block;
  height: 100%;
  padding-left: 31px;
  &__list {
    display: flex;
    height: 100%;
  }
  &__link {
    color: $primaryLight;
    font-family: $semibold;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 8px;
    margin: 0 6px 0 0;
    background-color: transparent;
    transition: all ease-out 0.075s;
    cursor: pointer;
    :global(.platform-mobile) & {
      transition: none;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 4px;
      background-color: transparent;
      transition: all ease-out 0.075s;
      :global(.platform-mobile) & {
        transition: none;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 50px;
      top: 9px;
      left: 0;
      right: 0;
      border: 2px solid transparent;
      box-sizing: border-box;
      transition: all ease-out 0.075s;
      :global(.platform-mobile) & {
        transition: none;
      }
    }
    &:focus {
      &::after {
        border: 2px solid $primaryLight;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryDark;
        &::before {
          background-color: $primaryLight;
        }
        &::after {
          content: none;
        }
      }
      &:active {
        background-color: $primaryDark;
        color: $white;
        &::before {
          transition: none;
        }
        &::after {
          content: none;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $primaryDark;
        color: $primaryLight;
        &::before {
          transition: none;
        }
        &::after {
          content: none;
        }
      }
    }
    &--active {
      color: $white;
      &::before {
        background-color: $primaryLight;
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: $primaryDark;
          &::before {
            background-color: $primaryLight;
          }
        }
      }
    }
  }
}
