@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.partners {
  > h1 {
    color: $text;
    font-family: $title;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    font-weight: normal;
    @include no-user-select;
    margin: 5px 0 32px 0;

    @media (max-width: 1240px) {
      font-size: 36px;
      line-height: 40px;
    }
    @media (max-width: 1024px) {
      font-size: 32px;
      line-height: 36px;
    }
    @media (max-width: 920px) {
      font-size: 30px;
      ine-height: 34px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 12px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__card {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: $white;
      border: 1px solid $greyXLight;
      padding: 32px 36px 24px;
      margin: 0 8px 16px;
      border-radius: 4px;
      box-sizing: border-box;

      width: 200px;
      height: 200px;
      transition: border-color 0.1s;

      &:hover {
        border-color: $primary;
      }
    }
  }

  &__registrations {
    display: flex;
    flex-direction: column;
  }
}

.registration {
  cursor: default;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: $white;
  border: 1px solid $greyXLight;
  padding: 16px 36px;
  margin: 0 8px 16px;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 767px) {
    padding: 12px;
  }

  > div:not(:last-child) {
    margin-right: 24px;

    @media (max-width: 767px) {
      margin-right: 12px;
    }
  }

  &__label {
    flex: 1;
  }

  &__actions {
    display: flex;
    align-items: center;

    > * {
      margin: 3px;
    }

    .action__delete {
      color: $alertDark;
    }
  }
}

.registration-state {
  font-family: $bold;

  &.Pending {
    color: $orange;
  }

  &.Rejected {
    color: $alertDark;
  }
}

.registration-form {
  &__alert {
    margin-bottom: 24px;
  }
}
