@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.header {
  flex-shrink: 0;
  height: 68px;
  background-color: transparent;
  @include no-user-select;
  z-index: 13;
  @media (max-width: 767px) {
    height: 57px;
  }
  z-index: 1000;
  &__container {
    width: 100%;
    position: relative;
    max-width: 1240px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: 1240px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media (max-width: 374px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    &__simple {
      &__section {
        flex: 1;

        .menu-link {
          color: white;
          svg {
            height: 9px;
            width: 26px;
          }
          span:last-child {
            margin-left: 12px;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
        &.align-header-center {
          flex: 1;
          text-align: center;
        }
      }
    }
  }

  &.green-border-bottom {
    border-bottom: solid 0.5rem $secondary;
  }
}
