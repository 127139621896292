@import "resources/sass/variables.scss";

.interview-actions {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 767px) {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  
  > span {
    margin-bottom: 8px;
  }

  > button {
    margin-bottom: 8px;
    &:not(:last-child)::after {
      content: "|";
      color: $greyXLight;
      position: relative;
      left: 8px;
    }
  }
}