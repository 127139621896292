@import "resources/sass/variables.scss";

.medicaid-lead-preview-card-container-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.medicaid-lead-preview-card-container {
  display: flex;
  justify-content: space-around;
  background-color: $white;
  width: fit-content;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px $greyXLight;
  padding: 15px;
  opacity: 1;
  transition: all 1s;

  &.loading {
    opacity: 0;
  }
}

.medicaid-lead-preview-actions {
  display: flex;
  justify-content: space-around;
}