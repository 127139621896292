@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.banner {
  padding: 22px 30px 22px 30px;
  border-radius: 4px;
  margin: 0 0 16px;

  &--success {
    background-color: $secondary;
  }

  &--info {
    background-color: $tertiaryXDark;
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-family: $title;
    color: $text;
    margin: 0 0 1px;
    @include no-user-select;
  }
  &__description {
    font-size: 16px;
    line-height: 24px;
    font-family: $semibold;
    color: $text;
    @include no-user-select;
  }
  &__action {
    display: flex;
    flex-direction: column !important;

    &__spinner {
      width: 160px;
    }

    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
  div[role="button"] {
    height: 50px;
  }
}
