.render-sales-receipt-modal-form-content {
  h3 {
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 20px;
  }

  div[role="button"] {
    margin-bottom: 20px;
  }
}