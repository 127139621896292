@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.input {
  position: relative;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  height: auto;
  width: 100%;
  &__clear {
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    top: 13px;
    right: 15px;
    cursor: pointer;
    .input--clear.input--valid & {
      right: 40px;
    }
    .input--clear & {
      display: block;
    }
    .input--autocomplete-open & {
      display: none;
    }
    .input--disabled & {
      * {
        fill: $disabled;
        pointer-events: none;
      }
    }
    .input--invalid & {
      * {
        fill: $alert;
      }
    }
    :global(.platform-desktop) .input--invalid & {
      &:hover {
        * {
          fill: $alertDark;
        }
      }
      &:active {
        * {
          fill: $alertDark;
        }
      }
    }
    :global(.platform-mobile) .input--invalid & {
      &:active {
        * {
          fill: $alertDark;
        }
      }
    }
    * {
      fill: $primary;
      transition: all 0.5s;
    }
    :global(.platform-desktop) & {
      &:hover {
        * {
          fill: $primaryDark;
        }
      }
      &:active {
        fill: $primaryDark;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        fill: $primaryDark;
      }
    }
  }
  input {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 0px 18px 0px 18px;
    margin: 0px 0px 0px 0px;
    width: 100%;
    height: 50px;
    font-family: $regular;
    color: $text;
    font-size: 18px;
    line-height: normal;
    font-weight: normal;
    letter-spacing: normal;
    background: white;
    border: 1px solid $greyLight;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    background-image: none !important; // Remove 3rd party extension icons
    @media (max-width: 920px) {
      font-size: 16px;
    }
    &::-ms-clear {
      display: none;
      width: 0px;
      height: 0px;
    }
    &::-ms-reveal {
      display: none;
      width: 0px;
      height: 0px;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &:-webkit-autofill {
      background: white;
      -webkit-box-shadow: 0px 0px 0px 100px white inset !important;
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: white;
    }
    &:invalid {
      box-shadow: none;
    }
    &[type="number"] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        display: none;
        -webkit-appearance: none;
      }
      -moz-appearance: textfield;
    }
    &[type="date"] {
      text-transform: uppercase;
    }
    &[type="month"] {
      text-transform: capitalize;
    }
    &[type="date"],
    &[type="month"] {
      &::-webkit-calendar-picker-indicator {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: none;
        opacity: 0;
        width: 0px;
        height: 0px;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        display: none;
        -webkit-appearance: none;
      }
      &:invalid::-webkit-datetime-edit,
      &.placeholder {
        color: $greyDark;
      }
      &:focus::-webkit-datetime-edit {
        color: #555555;
      }
    }
    &::-webkit-input-placeholder {
      text-transform: inherit !important;
      color: $greyDark !important;
      @include no-user-select;
    }
    &:-moz-placeholder {
      text-transform: inherit !important;
      color: $greyDark !important;
      opacity: 1 !important;
      @include no-user-select;
    }
    &::-moz-placeholder {
      text-transform: inherit !important;
      color: $greyDark !important;
      opacity: 1 !important;
      @include no-user-select;
    }
    &:-ms-input-placeholder {
      text-transform: inherit !important;
      color: $greyDark !important;
      @include no-user-select;
    }
    &::placeholder {
      text-transform: inherit !important;
      color: $greyDark !important;
      @include no-user-select;
    }
    &:focus {
      background-color: $white;
      border-color: $primary;
      &::-webkit-input-placeholder {
        color: $greyXDark !important;
      }
      &:-moz-placeholder {
        color: $greyXDark !important;
      }
      &::-moz-placeholder {
        color: $greyXDark !important;
      }
      &:-ms-input-placeholder {
        color: $greyXDark !important;
      }
      &::placeholder {
        color: $greyXDark !important;
      }
    }
    &:disabled {
      pointer-events: none;
      border-color: $disabled !important;
      color: $disabled !important;
      @include no-user-select;
      &::-webkit-input-placeholder {
        color: $disabled !important;
      }
      &:-moz-placeholder {
        color: $disabled !important;
      }
      &::-moz-placeholder {
        color: $disabled !important;
      }
      &:-ms-input-placeholder {
        color: $disabled !important;
      }
      &::placeholder {
        color: $disabled !important;
      }
    }
  }
  &::before,
  &::after {
    pointer-events: none;
  }
  &--placeholder {
    input {
      &[type="date"],
      &[type="month"] {
        color: $greyDark;
      }
    }
  }
  &--prefix {
    input {
      width: calc(100% - 175px);
    }
    .prefix {
      display: inline-block;
      box-sizing: border-box;
      text-align: center;
      width: 175px;
      height: 50px;
      line-height: 50px;
    }
  }
  .explainer {
    margin-top: 6px;
    font-size: 14px;
    color: $greyXXDark !important;
  }
  &--disabled {
    pointer-events: none;
    input {
      &[type="month"],
      &[type="date"] {
        color: $disabled !important;
      }
    }
    &.input--date {
      &::before {
        background-image: url("/static/images/form/icons/date-disabled.svg") !important;
      }
    }
    &.input--percentage {
      &::before {
        background-image: url("/static/images/form/icons/percentage-disabled.svg") !important;
      }
    }
    &.input--currency {
      &::before {
        background-image: url("/static/images/form/icons/currency-disabled.svg") !important;
      }
    }
    &.input--email {
      &::before {
        background-image: url("/static/images/form/icons/email-disabled.svg") !important;
      }
    }
    &.input--card {
      &::before {
        background-image: url("/static/images/form/icons/input-card-disabled.svg") !important;
      }
    }
    &.input--phone {
      &::before {
        background-image: url("/static/images/form/icons/phone-disabled.svg") !important;
      }
    }
  }
  &--clear {
    input {
      padding-right: 50px;
    }
  }
  &--valid {
    input {
      padding: 0px 50px 0px 18px;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("/static/images/form/icons/input-valid.svg");
      background-size: cover;
      background-repeat: no-repeat;
      right: 20px;
      top: 17px;
      width: 16px;
      height: 16px;
    }
    &.input--clear {
      input {
        padding-right: 75px;
      }
    }
    &.input--percentage {
      &::after {
        right: 18px;
      }
      &::before {
        right: 46px;
      }
      input {
        padding-right: 67px;
      }
      .input__text {
        padding-right: 69px;
      }
    }
  }
  &--invalid {
    &.input--date {
      &::before {
        background-image: url("/static/images/form/icons/date-invalid.svg");
      }
    }
    &.input--percentage {
      &::before {
        background-image: url("/static/images/form/icons/percentage-invalid.svg");
      }
    }
    &.input--currency {
      &::before {
        background-image: url("/static/images/form/icons/currency-invalid.svg");
      }
    }
    &.input--email {
      &::before {
        background-image: url("/static/images/form/icons/email-invalid.svg");
      }
    }
    &.input--card {
      &::before {
        background-image: url("/static/images/form/icons/input-card-invalid.svg");
      }
    }
    &.input--phone {
      &::before {
        background-image: url("/static/images/form/icons/phone-invalid.svg");
      }
    }
    input {
      border: 2px solid $alert;
      color: $alert;
      &::-webkit-input-placeholder {
        color: $alertPastel !important;
      }
      &:-moz-placeholder {
        color: $alertPastel !important;
      }
      &::-moz-placeholder {
        color: $alertPastel !important;
      }
      &:-ms-input-placeholder {
        color: $alertPastel !important;
      }
      &::placeholder {
        color: $alertPastel !important;
      }
      &:focus {
        color: $alert;
        border-color: $alertDark;
        &::-webkit-input-placeholder {
          color: $alertPastelDark !important;
        }
        &:-moz-placeholder {
          color: $alertPastelDark !important;
        }
        &::-moz-placeholder {
          color: $alertPastelDark !important;
        }
        &:-ms-input-placeholder {
          color: $alertPastelDark !important;
        }
        &::placeholder {
          color: $alertPastelDark !important;
        }
      }
    }
  }
  :global(.platform-desktop) & {
    &.input--invalid {
      &.input--date {
        &:hover {
          &::before {
            background-image: url("/static/images/form/icons/date-invalid-hover.svg");
          }
        }
      }
      &.input--percentage {
        &:hover {
          &::before {
            background-image: url("/static/images/form/icons/percentage-invalid-hover.svg");
          }
        }
      }
      &.input--currency {
        &:hover {
          &::before {
            background-image: url("/static/images/form/icons/currency-invalid-hover.svg");
          }
        }
      }
      &.input--email {
        &:hover {
          &::before {
            background-image: url("/static/images/form/icons/email-invalid-hover.svg");
          }
        }
      }
      &.input--card {
        &:hover {
          &::before {
            background-image: url("/static/images/form/icons/input-card-invalid-hover.svg");
          }
        }
      }
      &.input--phone {
        &:hover {
          &::before {
            background-image: url("/static/images/form/icons/phone-invalid-hover.svg");
          }
        }
      }
      &.input--placeholder {
        input {
          &[type="date"],
          &[type="month"] {
            color: $alertPastel;
            &:hover {
              color: $alertPastelDark;
            }
          }
        }
      }
      input {
        &:hover {
          border-color: $alertDark;
          color: $alertDark;
          &:focus {
            border-color: $alertDark;
          }
          &::-webkit-input-placeholder {
            color: $alertPastelDark !important;
          }
          &:-moz-placeholder {
            color: $alertPastelDark !important;
          }
          &::-moz-placeholder {
            color: $alertPastelDark !important;
          }
          &:-ms-input-placeholder {
            color: $alertPastelDark !important;
          }
          &::placeholder {
            color: $alertPastelDark !important;
          }
        }
        &:focus {
          border-color: $alertDark;
        }
      }
    }
    &.input--placeholder {
      input {
        &[type="date"],
        &[type="month"] {
          &:hover {
            color: $greyXDark;
          }
        }
      }
    }
    input {
      &:hover {
        border-color: $greyXDark;
        &:focus {
          border-color: $primary;
        }
        &::-webkit-input-placeholder {
          color: $greyXDark !important;
        }
        &:-moz-placeholder {
          color: $greyXDark !important;
        }
        &::-moz-placeholder {
          color: $greyXDark !important;
        }
        &:-ms-input-placeholder {
          color: $greyXDark !important;
        }
        &::placeholder {
          color: $greyXDark !important;
        }
      }
    }
  }
  &--phone {
    input {
      padding-left: 53px;
    }
    &::before {
      content: "";
      display: block;
      z-index: 1;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 13px;
      left: 17px;
      background-image: url("/static/images/form/icons/phone.svg");
    }
  }
  &--email {
    input {
      padding-left: 53px;
      @media (max-width: 920px) {
        padding-left: 52px;
      }
    }
    &::before {
      content: "";
      display: block;
      z-index: 1;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 13px;
      left: 17px;
      background-image: url("/static/images/form/icons/email.svg");
      @media (max-width: 920px) {
        width: 22px;
        height: 22px;
        top: 14px;
      }
    }
  }
  &--card {
    input {
      padding-left: 53px;
      @media (max-width: 920px) {
        padding-left: 52px;
      }
    }
    &::before {
      content: "";
      display: block;
      z-index: 1;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50%;
      position: absolute;
      top: 13px;
      left: 17px;
      background-image: url("/static/images/form/icons/input-card.svg");
      @media (max-width: 920px) {
        width: 22px;
        height: 22px;
        top: 14px;
      }
    }
  }
  &--date {
    input {
      padding-left: 53px;
      @media (max-width: 920px) {
        padding-left: 52px;
      }
    }
    &::before {
      content: "";
      display: block;
      z-index: 1;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 13px;
      left: 17px;
      background-image: url("/static/images/form/icons/date.svg");
      @media (max-width: 920px) {
        width: 22px;
        height: 22px;
        top: 13px;
      }
    }
  }
  &--currency {
    input {
      padding-left: 33px;
      @media (max-width: 920px) {
        padding-left: 32px;
      }
    }
    &::before {
      content: "";
      display: block;
      z-index: 1;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 12px;
      left: 14px;
      background-image: url("/static/images/form/icons/currency.svg");
      @media (max-width: 920px) {
        width: 22px;
        height: 22px;
        top: 13px;
      }
    }
  }
  &--percentage {
    width: auto;
    max-width: 100%;
    min-width: 66px;
    display: table;
    min-width: 67px;
    .input__text {
      font-size: 18px;
      font-family: $regular;
      white-space: pre;
      visibility: hidden;
      padding: 0 36px 0 18px;
      height: 50px;
      display: inline-block;
      border: 2px solid transparent;
      box-sizing: border-box;
      @media (max-width: 920px) {
        font-size: 16px;
      }
    }
    input {
      width: calc(100% + 2px);
      position: absolute;
      padding: 0 34px 0 18px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &::before {
      content: "";
      display: block;
      z-index: 1;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 12px;
      right: 13px;
      background-image: url("/static/images/form/icons/percentage.svg");
      @media (max-width: 920px) {
        width: 22px;
        height: 22px;
        top: 13px;
      }
    }
  }
  &--autocomplete {
    &.input--autocomplete-open {
      input {
        padding-right: 50px;
        z-index: 1;
      }
      &::after {
        z-index: 2;
        content: "";
        position: absolute;
        right: 15px;
        top: 13px;
        background-size: cover;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        background-image: url("/static/images/form/icons/chevron.svg");
      }
      &.input--disabled {
        &::after {
          background-image: url("/static/images/form/icons/chevron-disabled.svg");
        }
      }
      &.input--invalid {
        &::after {
          background-image: url("/static/images/form/icons/chevron-invalid.svg");
        }
        :global(.platform-desktop) & {
          &:hover {
            &::after {
              background-image: url("/static/images/form/icons/chevron-invalid-hover.svg");
            }
          }
        }
      }
      &.input--valid {
        &::after {
          background-image: url("/static/images/form/icons/chevron.svg");
        }
      }
    }
    input {
      padding-left: 53px !important;
    }
    &::before {
      content: "";
      display: block;
      z-index: 2;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 15px;
      left: 15px;
      background-image: url("/static/images/form/icons/search2.svg");
    }
    &.input--disabled {
      &::before {
        background-image: url("/static/images/form/icons/search2-disabled.svg");
      }
    }
    &.input--invalid {
      &::before {
        background-image: url("/static/images/form/icons/search2-invalid.svg");
      }
      :global(.platform-desktop) & {
        &:hover {
          &::before {
            background-image: url("/static/images/form/icons/search2-invalid-hover.svg");
          }
        }
      }
    }
    &.input--valid {
      &::after {
        background-image: url("/static/images/form/icons/input-valid.svg");
      }
    }
  }
  &--loading {
    svg[role="button"] {
      display: none;
    }
    input {
      padding-right: 55px;
    }
    &::after {
      z-index: 2;
      content: "";
      position: absolute;
      right: 17px;
      top: 13px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 50%;
      background-image: url("/static/images/form/icons/spinner.gif") !important;
    }
    &.input--disabled {
      &::after {
        background-image: url("/static/images/form/icons/spinner-disabled.gif") !important;
      }
    }
    &.input--invalid {
      &::after {
        background-image: url("/static/images/form/icons/spinner-invalid.gif") !important;
      }
      :global(.platform-desktop) & {
        &:hover {
          &::after {
            background-image: url("/static/images/form/icons/spinner-invalid-hover.gif") !important;
          }
        }
      }
    }
  }
  :global(.platform-android) & {
    &.input--date {
      input {
        &[type="date"],
        &[type="month"] {
          padding-left: 44px;
        }
      }
    }
    input {
      padding-top: 1px;
      &[type="date"],
      &[type="month"] {
        line-height: 46px;
      }
    }
  }
  :global(.platform-ios) & {
    input {
      &[type="date"],
      &[type="month"] {
        line-height: 48px;
      }
    }
  }
}
