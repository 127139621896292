@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.modal-confirm {
  display: block;
  box-sizing: border-box;
  will-change: transform;
  background-color: $white;
  border-radius: 8px 8px 8px 8px;
  @media (max-width: 767px) {
    min-width: 425px;
  }
  @media (max-width: 550px) {
    min-width: initial;
  }
  form > div {
    display: block;
    > div {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  &.modal-confirm-warning {
    background-color: $alertPastelXXXLight;
    border: 2px dashed $alert;
  }
  &::after {
    content: "";
    position: absolute;
    border-radius: 8px 8px 8px 8px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($primaryXDark, 0.4);
    opacity: 0;
    z-index: 3;
    pointer-events: none;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
  &.modal-confirm-busy {
    pointer-events: none;
    @include no-user-select;
  }
  &.modal-confirm-busy::after {
    pointer-events: auto;
    opacity: 0.5;
  }
  &__header {
    padding: 24px 24px 0px 24px;
    @media (max-width: 767px) {
      padding: 16px 16px 0 16px;
    }
    .modal-confirm-warning & {
      padding: 22px 22px 0px 22px;
      @media (max-width: 767px) {
        padding: 20px 16px 0 16px;
      }
    }
  }
  &__title {
    font-family: $title;
    font-size: 28px;
    line-height: 38px;
    color: $text;
    margin: -8px 0px 0px 0px;
    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
      margin-top: -5px;
    }
    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 26px;
      margin-top: -3px;
    }
    .modal-confirm-warning & {
      color: $alert;
    }
  }
  &__content {
    padding: 24px;
    @media (max-width: 767px) {
      padding: 16px;
    }
    .modal-confirm-warning & {
      padding: 24px 22px 24px 22px;
      @media (max-width: 767px) {
        padding: 16px;
      }
    }
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      color: $text;
      font-family: $bold;
      margin: 0 0 16px;
    }
    blockquote {
      color: $text;
      font-family: $regular;
      font-style: italic;
      margin: 0 0 16px;
    }
    a {
      color: $primary;
      text-decoration: underline;
      :global(.platform-desktop) & {
        &:hover {
          text-decoration: none;
        }
      }
    }
    ul,
    ol {
      margin: 0 0 16px;
      list-style-position: outside;
      padding-left: 18px;
    }
    ul {
      list-style: disc;
    }
    ol {
      list-style: decimal;
    }
    li {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 19px;
      }
      color: $text;
    }
    > p {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      color: $text;
      margin: 0 0 16px;
      max-width: 450px;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 19px;
      }
    }
    > *:last-child {
      margin-bottom: 9px;
    }
    > form > div > div > fieldset {
      border-left: none;
      border-right: none;
      padding-left: 0px;
      padding-right: 0px;
      &:first-child {
        border-top: none;
        padding-top: 0px;
      }
      &:last-of-type {
        border-bottom: none;
        padding-bottom: 4px;
      }
      > div > div {
        flex-basis: calc(100% - 8px);
        max-width: calc(100% - 8px);
        input {
          padding-right: 50px;
        }
      }
    }
  }
  &__footer {
    padding: 0px 24px 24px 24px;
    justify-content: space-between;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    @media (max-width: 767px) {
      padding: 0 16px 16px 16px;
    }
    @media (max-width: 550px) {
      flex-direction: column;
    }
    > div[role="button"] {
      @media (max-width: 767px) {
        width: calc(50% - 6px);
      }
      @media (max-width: 550px) {
        width: 100%;
      }
      &:nth-child(2) {
        @media (max-width: 550px) {
          margin-top: 12px;
        }
      }
    }
    &-align-right > div[role="button"]:nth-child(1):not(:last-child) {
      margin-right: 20px;
    }
    &-align-right > div[role="button"]:last-child {
      margin-left: auto;
      @media (max-width: 767px) {
        margin-left: 0;
        width: 100%;
      }
    }
    &__spinner {
      display: flex;
      width: 120px;
      align-items: center;
      justify-content: center;
    }
    .modal-confirm-warning & {
      padding: 0px 22px 22px 22px;
      @media (max-width: 767px) {
        padding: 0 16px 16px 16px;
      }
    }
    .modal-confirm-busy & > div[role="button"]:last-child {
      pointer-events: none;
      color: transparent;
      z-index: 4;
      &:after {
        position: absolute;
        content: "";
        width: 26px;
        height: 26px;
        top: 50%;
        left: 50%;
        margin-top: -13px;
        margin-left: -13px;
        background-repeat: no-repeat;
        background-size: 26px 26px;
        background-position: 50%;
        background-image: url("/static/images/form/icons/spinner-positive.gif") !important;
      }
    }
    .modal-confirm-warning.modal-confirm-busy & > div[role="button"]:last-child:after {
      background-image: url("/static/images/form/icons/spinner-negative.gif") !important;
    }
  }
}
