.life-insurance-policy-value-inputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  & > div {
    width: 30%;
  }

  .life-insurance-policy-value-label {
    margin-bottom: 8px;
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;

    & > div {
      width: 100%;
    }
    
    & input {
      width: 60%;
      margin-bottom: 12px;
    }
  }
}