@import "resources/sass/variables.scss";

.product-offering {
  height: 200px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__icon svg {
    height: 40px;
    width: auto;
    margin-right: 0.5rem;
  }

  h5 {
    font-family: $semibold;
  }

  &__button-container {
    text-align: right;
  }
}