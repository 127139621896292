@import "resources/sass/variables.scss";

.header-profile {
  margin-left: auto;
  display: flex;
  height: 100%;
  align-items: center;
  > div[role="button"] {
    color: $secondary;

    &:hover {
      text-decoration: underline;
      background-color: transparent !important;
      border-color: transparent !important;
    }

    & > svg {
      height: 25px;
      width: auto;
      margin: 0;

      & path {
        fill: $secondary !important;
      }
      
    }
  }
}
