@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.footer {
  flex-shrink: 0;
  width: 100%;
  padding: 19px 0;
  height: 136px;
  box-sizing: border-box;
  background-color: $navy;

  &--short {
    height: 74px;
  }

  @media (max-width: 767px) {
    padding: 44px 0 48px;
    height: auto;
  }
  @media (max-width: 374px) {
    padding: 36px 0 40px;
  }

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1240px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @media (max-width: 1240px) {
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (max-width: 374px) {
      padding: 0 20px;
    }
  }
  &__revision {
    position: absolute;
    bottom: -10px;
    left: 30px;
    font-size: 12px;
    line-height: normal;
    color: $primaryLight;
    @include no-user-select;
    @media (max-width: 767px) {
      bottom: -38px;
      left: 50%;
      transform: translateX(-50%);
    }
    @media (max-width: 374px) {
      bottom: -30px;
    }
  }
  &__highlight {
    color: $alert;
    transition: all ease-out 0.075s;
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      color: darken($alert, 7.5%);
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: darken($alert, 7.5%);
        text-decoration: underline;
      }
      &:active {
        color: darken($alert, 15%);
        text-decoration: none;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: darken($alert, 15%);
        text-decoration: none;
      }
    }
  }
}
