@import "resources/sass/variables.scss";

.header-logo {
  font-size: 0;
  position: relative;
  cursor: pointer;
  svg {
    width: 121px;
    height: 35px;
    @media (max-width: 767px) {
      width: 83px;
      height: 24px;
    }
    * {
      fill: $white;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 52px;
    top: 50%;
    margin-top: -26px;
    left: -7px;
    right: -6px;
    border: 2px solid transparent;
    box-sizing: border-box;
    pointer-events: none;
    transition: all ease-out 0.075s;
    @media (max-width: 767px) {
      height: 43px;
      top: 50%;
      margin-top: -22px;
      left: -6px;
      right: -6px;
    }
    :global(.platform-mobile) & {
      transition: none;
    }
  }
  &:focus {
    &::after {
      border: 2px solid $primaryLight;
    }
  }
}
