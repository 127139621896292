@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.summary {
  position: relative;
  box-sizing: border-box;
  padding: 0px 88px 0px 14px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 50px;
  line-height: 22px;
  display: flex;
  align-items: center;
  font-family: $regular;
  color: $text;
  font-size: 18px;
  font-weight: normal;
  background: white;
  border: 1px solid $grey;
  cursor: pointer;
  white-space: nowrap;
  @include no-user-select;
  @media (max-width: 920px) {
    font-size: 16px;
    line-height: 20px;
  }
  &::before {
    content: "Edit";
    position: absolute;
    right: 43px;
    font-family: $regular;
    color: $text;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: 920px) {
      font-size: 15px;
      line-height: 18px;
    }
  }
  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    right: 18px;
    top: 15px;
    width: 16px;
    height: 16px;
    background-image: url("/static/images/form/icons/input-valid.svg");
  }
  &--disabled {
    padding-right: 14px;
    pointer-events: none;
    color: $disabled;
    &::before,
    &::after {
      content: none;
    }
  }
  &:focus {
    border-color: $primary;
    outline: none;
  }
  :global(.platform-desktop) & {
    &:hover {
      border-color: $greyDark;
      &:focus {
        border-color: $primary;
      }
    }
  }
  &__inner {
    overflow: hidden;
  }
}
