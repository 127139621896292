@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.payment {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  &__col {
    &:first-child {
      flex-basis: calc(100% - 402px);
      max-width: calc(100% - 402px);
      @media (max-width: 1240px) {
        flex-basis: calc(100% - 346px);
        max-width: calc(100% - 346px);
      }
      @media (max-width: 920px) {
        flex-basis: calc(100% - 282px);
        max-width: calc(100% - 282px);
      }
      @media (max-width: 767px) {
        flex-basis: 100%;
        max-width: initial;
        order: 3;
        width: calc(100% + 32px);
        left: -16px;
        position: relative;
      }
      @media (max-width: 374px) {
        width: calc(100% + 40px);
        left: -20px;
      }
      fieldset {
        &:first-child {
          position: relative;
          > legend {
            box-sizing: border-box;
            padding-right: 222px;
            @media (max-width: 767px) {
              padding-right: 162px;
            }
            &::after {
              position: absolute;
              height: 21px;
              right: 32px;
              top: 32px;
              padding-left: 28px;
              display: block;
              font-family: $semibold;
              color: $primary;
              line-height: 26px;
              font-size: 14px;
              content: "Your transaction is secure";
              background-image: url("/static/images/icons/lock.svg");
              background-repeat: no-repeat;
              background-size: contain;
              @media (max-width: 1024px) {
                top: 26px;
              }
              @media (max-width: 400px) {
                height: 25px;
                line-height: 14px;
                top: 24px;
                width: 96px;
                font-size: 13px;
              }
            }
          }
          > div > div > div > div > div {
            width: 100%;
            height: 100%;
            > span {
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
        &:nth-last-child(2) {
          border-bottom: none;
        }
        &:last-child {
          padding-top: 0px;
          &:nth-child(odd) {
            background-color: $tertiary;
          }
          &:nth-child(even) {
            background-color: white;
          }
        }
      }
    }
    &:last-child {
      margin-left: 32px;
      flex-shrink: 0;
      flex-basis: 370px;
      max-width: 370px;
      @media (max-width: 1240px) {
        margin-left: 24px;
        flex-basis: 320px;
        max-width: 320px;
      }
      @media (max-width: 920px) {
        margin-left: 12px;
        flex-basis: 270px;
        max-width: 270px;
      }
      @media (max-width: 767px) {
        order: 1;
        max-width: initial;
        flex-basis: auto;
        width: calc(100% + 32px);
        left: -16px;
        margin: 0 0 8px;
        position: relative;
      }
      @media (max-width: 374px) {
        width: calc(100% + 40px);
        left: -20px;
      }
    }
  }
}
