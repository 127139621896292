@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.coupon {
  position: relative;
  padding: 24px 32px;
  @media (max-width: 1240px) {
    padding: 24px 24px;
  }
  @media (max-width: 1024px) {
    padding: 24px 24px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top: 1px solid $greyXLight;
  }
  &__title {
    position: relative;
    margin-bottom: 24px;
    color: $primary;
    font-family: $title;
    font-size: 24px;
    line-height: 33px;
    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 30px;
    }
    @media (max-width: 920px) {
      font-size: 20px;
      line-height: 27px;
    }
  }
  &__input-wrapper {
    display: flex;
  }
  &__add-button {
    margin-left: 8px;
    padding: 0 16px;
    height: 50px;
    &--loading {
      color: transparent !important;
      &::after {
        content: "";
        z-index: 2;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 50%;
        background-image: url("/static/images/form/icons/spinner-disabled.gif") !important;
      }
    }
  }
  &__disclaimer {
    margin-top: 24px;
    font-size: 14px;
  }
  &__error {
    margin-top: 8px;
    color: $alertDark;
  }
}
