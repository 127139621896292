@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.input-stripe {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0 18px;
  box-sizing: border-box;
  background: white;
  cursor: text;
  &::after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $grey;
    box-sizing: border-box;
    border-radius: 4px;
  }
  :global(.platform-desktop) & {
    &:hover::after {
      border-color: $greyDark;
    }
  }
}

:global(.StripeElement--focus) {
  &::after {
    border-color: $primary;
  }
  :global(.platform-desktop) & {
    &:hover::after {
      border-color: $primary;
    }
  }
}

:global(.StripeElement--invalid) {
  &::after {
    border: 2px solid $alert;
  }
  :global(.platform-desktop) & {
    &:hover {
      &::after {
        border-color: $alertDark;
      }
    }
  }
}

:global(.StripeElement--focus.StripeElement--invalid) {
  &::after {
    border-color: $alertDark;
  }
  :global(.platform-desktop) & {
    &:hover::after {
      border-color: $alertDark;
    }
  }
}

:global(.StripeElement--complete) {
  padding-right: 50px;
  > div::before {
    content: "";
    position: absolute;
    background-image: url("/static/images/form/icons/input-valid.svg");
    background-size: cover;
    background-repeat: no-repeat;
    right: -30px;
    top: 17px;
    width: 16px;
    height: 16px;
  }
}
