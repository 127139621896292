@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.checkbox-link {
  position: relative;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: background-color 0.5s;
  @include no-user-select;
  &__text {
    display: flex;
    align-items: center;
    color: $primary;
    font-family: $regular;
    font-size: 18px;
    line-height: 22px;
    justify-content: center;
    position: relative;
    transition: color 0.5s;
    @media (max-width: 920px) {
      font-size: 16px;
      line-height: 20px;
    }
    &::before,
    &::after {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      position: relative;
      border-radius: 4px;
    }
    &::before {
      background-color: transparent;
      border: 2px solid $primary;
      box-sizing: border-box;
      transition: all 0.5s;
      margin: 0 12px 0 0;
      flex-shrink: 0;
      @media (max-width: 920px) {
        margin: 0 8px 0 0;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 0;
      background-color: $primary;
      background-size: 14px 14px;
      background-repeat: no-repeat;
      background-position: 1px 1px;
      background-image: url("/static/images/form/icons/white-tick.svg");
      opacity: 0;
      transition: all 0.5s;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    display: block;
    box-sizing: border-box;
    border: 1px solid transparent;
    top: -6px;
    left: -6px;
    transition: border-color 0.5s;
    @at-root {
      .checkbox-link--icon#{&} {
        border-radius: 8px;
      }
    }
  }
  &--disabled {
    pointer-events: none;
    background-color: transparent;
    border-color: $disabled;
    @include no-user-select;
    .checkbox-link__text {
      color: $disabledXDark;
      &::before {
        border-color: $disabledDark;
      }
    }
  }
  &:focus {
    .checkbox-link__text {
      text-decoration: underline;
    }
  }
  &--selected {
    &::after {
      opacity: 1;
    }
    .checkbox-link__text {
      &::after {
        opacity: 1;
      }
    }
  }
  &--invalid {
    border-color: $alert;
    &.button-radio--selected {
      background-color: $alertPastelLight;
    }
    &::after {
      content: none;
    }
    .checkbox-link__text {
      color: $alertXDark;
      &::before {
        border-color: $alertXDark;
      }
    }
    &:focus {
      background-color: $alertPastelLight;
      border-color: $alertDark;
      &::before {
        border-color: $alertDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $alertPastelLight;
        border-color: $alertDark;
      }
    }
  }
}
