@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.config-list {
  .config-list__item {
    position: relative;
    background-color: $white;
    border: 1px solid $greyXLight;
    margin: 0 0 8px;
    border-radius: 8px;
    padding: 20px 40px 18px 20px;
    transition: all ease-out 0.075s;
    flex-direction: column;
    align-items: flex-start;

    &:after {
      content: "";
      display: block;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 10px;
      width: 30px;
      height: 30px;
    }

    &:hover {
      background-color: $tertiary;
      border: 1px solid $tertiaryXDark;
    }

    > header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;

      * + * {
        margin-left: 12px;
      }
    }
    
    &-clickable:after {
      background: transparent url("/static/images/form/icons/chevron.svg") no-repeat;
      background-size: contain;
      transform: rotate(-90deg);
    }
    &-clickable:hover {
      cursor: pointer;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 27px;
    font-family: $title;
    color: $primaryXDark;
    @include user-select;
    @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
      font-size: 18px;
      line-height: 25px;
    }
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__subtitle {
    color: $greyXXDark;
  }
}
