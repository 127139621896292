@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.finance {
  color: black;
  .finance-header {
    display: flex;
    justify-content: space-between;
    color: black;
    margin-bottom: 50px;


    .finance-range-toggle {
      max-width: 400px !important;
    }
  }
}