@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.confirm {
  position: relative;
  font-size: 16px;
  color: $text;
  font-family: $regular;
  cursor: pointer;
  @include no-user-select;
  padding-left: 24px;
  display: inline-block;
  
  &::before {
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: 1px solid $primaryXDark;
    top: 1px;
    left: 0;
    box-sizing: border-box;
    position: absolute;
    background-color: transparent;
    transition: all 0.5s;
  }

  &::after {
    content: "";
    height: 16px;
    width: 16px;
    position: absolute;
    top: 1px;
    left: 0;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: 1px 1px;
    background-image: url("/static/images/form/icons/white-tick.svg");
    opacity: 0;
    transition: all 0.5s;
    z-index: 1;
  }

  &--selected {
    &::before {
      background-color: $primaryXDark;
    }
    &::after {
      opacity: 1;
    }
  }

  &__action {
    margin-left: 10px;
  }
}
