@import "resources/sass/variables.scss";

.medicaid-lead-finished {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  text-align: center;
  background-color: $white;
  padding: 32px;
  box-shadow: 0px 0px 10px $greyXLight;


  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }

  &__tick {
    height: 25px;
    width: 25px;
    margin-right: 6px;
    & * {
      fill: $primary;
    }
    
  }

  h2 {
    color: $primary;
    font-family: $bold;
    line-height: 25px;
    vertical-align: middle;
  }

  &__reminder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;
    
  }

  &__analysis {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    margin: 32px 0;

    &-bar {
      width: 100%;
    }

    &-graph {
      width: 100%;
      height: 400px;

      @media (max-width: 300px) {
        display: none;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap-reverse;

    margin-top: 30px;

    & > div {
      margin-top: 20px;
    }
  }
}

.monetary-amount {
  font-weight: $title;
  font-size: 22px;
}