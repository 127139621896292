@import "resources/sass/variables.scss";

.buttons {
  text-align: right;
  margin-left: -10px;
  margin-bottom: -10px;
  > * {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.id {
  overflow-wrap: anywhere;
}
