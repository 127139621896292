@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.product-selector {
  // display: block;
  padding-top: 46px 0 20px;

  @media (max-width: 1240px) {
    padding: 34px 0 12px;
  }
  @media (max-width: 1024px) {
    padding: 24px 0 0;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
  &__title {
    display: block;
    color: $text;
    font-family: $regular;
    font-size: 1.7rem;
    line-height: 2.5rem;
    text-align: center;
    font-weight: 600;
    @include no-user-select;
    margin-bottom: 8px;
  }
  &__title span {
    display: inline-block;
  }
  &__partner {
    color: $primaryXDark;
    font-family: $bold;
    font-size: 2.5rem;
    line-height: 2.25rem;
    text-align: center;
    font-weight: 700;
    @include no-user-select;
    margin-bottom: 32px;
  }
  &__list {
    margin: 0 -8px -16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 767px) {
      margin: 0 0 -12px;
    }
  }
  &__help {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 64px 0;

    > p {
      font-family: $bold;
      font-size: 40px;

      @media (max-width: 1240px) {
        font-size: 36px;
      }
      @media (max-width: 920px) {
        font-size: 30px;
      }
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }

    // margin: 32px 0 0;
    // padding: 20px 10%;
  }

  > div[role="button"] {
    background-color: $secondary;
  }
}

.product-selector-box {
  background-color: white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2.75rem;
  padding-top: 2.75rem;
  margin-bottom: 2rem;

  font-size: 1.25rem;

  &__title {
    color: $primaryXDark;
    font-family: $bold;
    font-size: 2.125rem;
    line-height: 2.25rem;
    font-weight: 700;
    @include no-user-select;
    margin-bottom: 32px;
  }

  &__subtitle {
    font-family: $bold;
    font-weight: 700;
    @include no-user-select;
    margin-bottom: 1.25rem;
  }

  &__cols {
    @media (max-width: 767px) {
      display: block;
    }
    @media (min-width: 768px) {
      display: flex;
    }
  }

  &__cols > div {
    @media (max-width: 767px) {
      flex-basis: 100%;
      max-width: 100%;
      padding: 24px;
      margin: 0 0 12px;
    }
    @media (min-width: 768px) {
      flex-basis: calc(50%);
    }
  }

  p {
    margin-bottom: 10px;
  }

  &__list {
    color: black;
  }

  &__list li {
    margin-bottom: 10px;
  }

  &__list svg {
    width: 16px;
    height: 16px;
  }

  &__list svg path {
    fill: $secondary !important;
  }

  > div[role="button"] {
    font-size: 1rem;
    float: right;
    background-color: $secondary;
    border: none;
    color: black;
    border-radius: 9999px;
    text-transform: uppercase;
    padding-right: 38px;

    @media (max-width: 767px) {
      width: 100%;
      font-size: 0.6rem;
    }
  }

  > div[role="button"]::after {
    background-image: url("/static/images/form/icons/chevron-black.svg");
    color: black;
    content: "";
    position: absolute;
    right: 10px;
    top: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
    transform: rotate(-90deg);
  }

  > div[role="button"]:hover {
    background-color: $secondaryDark;
  }
}

.product-selector-box-additional {
  padding-bottom: 4rem;
}
