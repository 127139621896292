@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.client-dashboard-products-item-breakdown-item {
  display: flex;
  background-color: $white;
  border-bottom: 1px solid $tertiaryXDark;
  box-sizing: border-box;
  @media (max-width: 1240px) {
    flex-wrap: wrap;
    position: relative;
    padding: 16px 0;
  }
  &:last-child {
    border-bottom: 0;
  }
  &:nth-child(even) {
    background-color: $tertiaryDark;
  }
  &__col {
    padding: 15px 20px;
    text-align: left;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1240px) {
      padding: 0;
      box-sizing: border-box;
    }
    &:nth-child(1) {
      flex-grow: 1;
      flex-shrink: 1;
      padding-left: 24px;
      @media (max-width: 1240px) {
        padding-left: 16px;
        order: 1;
        flex-basis: calc(100% - 56px);
        max-width: calc(100% - 56px);
      }
    }
    &:nth-child(2) {
      width: 160px;
      @media (max-width: 1240px) {
        width: 100%; // 136px
        order: 3;
        padding: 12px 16px 0;
      }
    }
    &:nth-child(3) {
      width: 94px;
      @media (max-width: 1240px) {
        order: 4;
        position: absolute;
        bottom: 16px;
        right: 16px;
        width: auto;
      }
    }
    &:nth-child(4) {
      width: 40px;
      padding-right: 24px;
      @media (max-width: 1240px) {
        width: 56px;
        padding-right: 16px;
        order: 2;
      }
    }
  }
  &__title {
    font-size: 15px;
    line-height: 20px;
    font-family: $semibold;
    color: $text;
    margin: 0 0 2px;
    @media (max-width: 1210px) {
      font-size: 14px;
      margin: 0 0 -1px;
    }
  }
  &__remaining {
    font-size: 13px;
    line-height: 18px;
    color: $tertiaryXDark;
    font-family: $regular;
  }
  &__progress-bar {
    height: 10px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid transparent;
    box-sizing: border-box;
    position: relative;
    margin: 4px 0 6px;
    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      border-radius: 8px;
      border: 2px solid $grey;
      box-sizing: border-box;
      opacity: 0.5;
    }
    &-current {
      border-radius: 8px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;
      transition: background-color 0.5s, width 0.5s;
      .client-dashboard-products-item-breakdown-item--in-progress & {
        background-color: $orange;
      }
      .client-dashboard-products-item-breakdown-item--complete & {
        background-color: $successDark;
      }
    }
  }
  &__complete {
    font-size: 12px;
    line-height: 18px;
    color: $greyXXDark;
    font-family: $bold;
    .client-dashboard-products-item-breakdown-item--in-progress & {
      color: $orange;
    }
    .client-dashboard-products-item-breakdown-item--complete & {
      color: $successDark;
    }
  }
  &__status {
    font-size: 14px;
    line-height: 19px;
    color: $greyXXDark;
    font-family: $bold;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    padding-left: 25px;
    @media (max-width: 1240px) {
      font-size: 12px;
      line-height: 18px;
    }
    &::before {
      flex-shrink: 0;
      content: "";
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $greyXXDark;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      @media (max-width: 1240px) {
        left: 11px;
      }
    }
    span {
      height: 18px;
    }
    .client-dashboard-products-item-breakdown-item--in-progress & {
      color: $orange;
      &::before {
        left: -2px;
        height: 19px;
        width: 19px;
        background-color: $white;
        border: 2px solid $orange;
        box-sizing: border-box;
        @media (max-width: 1240px) {
          left: 0;
        }
      }
      &::after,
      > span::before,
      > span::after {
        content: "";
        position: absolute;
        background-color: $orange;
        width: 3px;
        height: 3px;
        top: 8px;
        z-index: 2;
        border-radius: 50%;
        left: 0;
        transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        animation: pulse 0.66s ease-in-out infinite alternate;
        -webkit-animation: pulse 0.66s ease-in-out infinite alternate;
        @media (max-width: 1240px) {
          left: 2px;
        }
      }
      > span::before {
        left: 2px;
        @media (max-width: 1240px) {
          left: 4px;
        }
      }
      &::after {
        left: 6px;
        animation-delay: 0.22s;
        -webkit-animation-delay: 0.22s;
        @media (max-width: 1240px) {
          left: 8px;
        }
      }
      > span::after {
        left: 10px;
        animation-delay: 0.44s;
        -webkit-animation-delay: 0.44s;
        @media (max-width: 1240px) {
          left: 12px;
        }
      }
    }
    .client-dashboard-products-item-breakdown-item--complete & {
      color: $successDark;
      &::before {
        left: 0;
        height: 16px;
        width: 16px;
        margin: 0 8px 0 0;
        background-image: url("/static/images/form/icons/input-valid.svg");
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: 1240px) {
          left: 3px;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    -ms-transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    -ms-transform: scale(1);
    opacity: 0.75;
  }
}
