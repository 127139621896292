@import "resources/sass/variables.scss";

.promoCodeConfig {
  display: flex;
  flex-direction: row;
  margin-top: 2em;

  h2 {
    font-size: 20px;
    font-family: $title;
    margin-bottom: 20px;
  }

  &__active {
    flex: 1;
    margin-right: 40px;
  }

  &__new {
    flex: 1;
    padding-left: 40px;
    border-left: solid 1px $greyXLight;

    &__fields {
      display: flex;
      flex-direction: row;

      input[name="code"] {
        text-transform: uppercase;
      }
      
      > div:first-child {
        margin-left: 0
      }
    }
  }
  
}

.promoCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 8px 0;
  
  &__action {
    &__icon {
      * {
        fill: $greyXXDark;
      }

      &:hover {
        * {
          fill: $alertDark;
        }
      }
    }
  }

  &__discount {
    font-weight: bold !important;
    color: $primary !important;
    width: 3em;
    text-align: right;
    margin: 0 30px;
  }

  &__code {
    text-transform: uppercase;
    flex: 1;
  }

}