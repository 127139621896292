@import "resources/sass/variables.scss";
@import "../../styles/grid.module.scss";
@import "../../index.scss";

.nearest-law-firm {
  padding: 24px 0;
  background-color: $white;

  @media (max-width: $lg) {
    padding-top: 150px;
  }

  @media (max-width: 520px) {
    padding-top: 110px;
  }

  & > div {
    @media (max-width: 520px) {
      padding-left: 0;
    }
  }

  div[role="button"] {
    font-size: 24px;
    height: 60px;
    margin: 36px 0;
    background-color: $navy;
    border-color: $navy;
    color: $secondary;

    &:hover {
      background-color: $navyDark !important;
      border-color: $navyDark !important;
    }

    @media (max-width: 520px) {
      margin: 18px 0;
      height: 40px;
      font-size: 18px;
    }

    @media (max-width: 360px) {
      margin: 9px 0;
      height: 30px;
      font-size: 14px;
    }
  }

  @media (max-width: $lg) {
    text-align: center;
  }
}