.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    margin: 12px;
    min-width: 250px;
  }
}