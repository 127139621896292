@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.question small {
  position: relative;
  display: block;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  width: auto;
  height: auto;
  text-align: left;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  font-size: 10px;
  @include no-user-select;
  display: none;
}

.question {
  flex-direction: column;
  margin: 0 4px;
  position: relative;
  display: flex;
  align-items: flex-start;
  &--child {
    margin-bottom: -20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--small {
    flex-basis: calc(33.33% - 8px);
    max-width: calc(33.33% - 8px);
  }
  &--medium {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
  &--large {
    flex-basis: calc(66.667% - 8px);
    max-width: calc(66.667% - 8px);
  }
  &--fullwidth {
    flex-basis: calc(100% - 8px);
    max-width: calc(100% - 8px);
  }
  @media (max-width: 1024px) {
    &--small {
      flex-basis: 100%;
      max-width: 100%;
    }
    &--medium {
      flex-basis: 100%;
      max-width: 100%;
    }
    &--large {
      flex-basis: 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }
  &__inner {
    width: 100%;
    margin: 0 0 25px;
    + .question__error {
      margin-top: -17px;
    }
    + .question__info {
      margin-top: -9px;
    }
    + .question__help {
      margin-top: -8px;
    }
    &-buttons {
      display: flex;
      margin: 0 -4px -8px;
      flex-wrap: wrap;
      .question--stacked-buttons & {
        flex-direction: column;
      }
    }
  }
  &__error {
    color: $alert;
    font-size: 16px;
    line-height: 20px;
    font-family: $regular;
    margin: 0 0 20px;
    display: none;
    width: 100%;
    @include no-user-select;
    @media (max-width: 920px) {
      font-size: 15px;
      line-height: 18px;
    }
    .question--invalid & {
      display: block;
    }
    + .question__info {
      margin-top: -8px;
    }
    + .question__help {
      margin-top: -8px;
    }
  }
  &__help {
    position: relative;
    padding-left: 16px;
    margin: 0 0 20px;
    display: none;
    width: 100%;
    box-sizing: border-box;
    .question--show-help-text & {
      display: block;
    }
    + .question__info {
      margin-top: -8px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: calc(100% - 6px);
      background-color: $primary;
      top: 2px;
      left: 0;
    }
    &-title {
      font-size: 16px;
      line-height: 20px;
      font-family: $bold;
      margin: 0 0 7px;
      @media (max-width: 920px) {
        font-size: 15px;
        line-height: 18px;
      }
      &:last-child {
        margin: 0;
      }
    }
    &-text {
      font-size: 16px;
      line-height: 20px;
      font-family: $regular;
      margin: 0 0 15px;
      @media (max-width: 920px) {
        font-size: 15px;
        line-height: 18px;
      }
      &:last-child {
        margin: 0;
      }
    }
    &-tooltip {
      height: 25px;
      width: 25px;
      position: absolute;
      right: 0px;
      top: -2px;
      cursor: pointer;
      .question--disabled & {
        pointer-events: none;
        *:nth-child(2),
        *:nth-child(3) {
          fill: $disabled;
        }
        *:nth-child(1) {
          fill: transparent;
        }
      }
      *:nth-child(2),
      *:nth-child(3) {
        fill: $primary;
        transition: all 0.5s;
      }
      *:nth-child(1) {
        fill: transparent;
        transition: all 0.5s;
      }
      &:focus {
        *:nth-child(2),
        *:nth-child(3) {
          fill: $primaryXDark;
        }
        *:nth-child(1) {
          fill: transparent;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          *:nth-child(2),
          *:nth-child(3) {
            fill: $primaryXDark;
          }
          *:nth-child(1) {
            fill: transparent;
          }
        }
      }
      .question--show-help-text & {
        &:focus {
          *:nth-child(3) {
            fill: $white;
          }
          *:nth-child(1),
          *:nth-child(2) {
            fill: $primaryXDark;
          }
        }
        *:nth-child(3) {
          fill: $white;
        }
        *:nth-child(1),
        *:nth-child(2) {
          fill: $primary;
        }
      }
      :global(.platform-desktop) .question--show-help-text & {
        &:hover {
          *:nth-child(3) {
            fill: $white;
          }
          *:nth-child(1),
          *:nth-child(2) {
            fill: $primaryXDark;
          }
        }
      }
    }
  }
  &__info {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 20px;
    width: 100%;
    @media (max-width: 920px) {
      font-size: 15px;
      line-height: 18px;
    }
    .question--disabled & {
      display: none;
    }
    + .question__help {
      margin-top: -8px;
    }
    &-title {
      font-size: 16px;
      line-height: 20px;
      font-family: $bold;
      margin: 0 0 7px;
      @media (max-width: 920px) {
        font-size: 15px;
        line-height: 18px;
      }
      &:last-child {
        margin: 0;
      }
    }
    &-text {
      font-size: 16px;
      line-height: 20px;
      font-family: $regular;
      margin: 0 0 15px;
      @media (max-width: 920px) {
        font-size: 15px;
        line-height: 18px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  &__other {
    &__label {
      padding-top: 1em;
    }
  }
  &__empty {
    position: relative;
    box-sizing: border-box;
    padding: 0px 50px 0px 18px;
    margin: 0px 0px 0px 0px;
    width: 100%;
    height: 50px;
    line-height: 22px;
    display: flex;
    align-items: center;
    font-family: $regular;
    color: $greyXDark;
    font-size: 18px;
    font-weight: normal;
    background: white;
    border-radius: 4px;
    border: 1px solid $grey;
    white-space: nowrap;
    max-width: calc(50% - 4px);
    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      right: 18px;
      top: 15px;
      background-size: cover;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      background-image: url("/static/images/form/icons/input-warning.svg");
    }
    &-inner {
      overflow: hidden;
    }
  }
}
