@import "resources/sass/variables.scss";

.InterviewQuestionnaireSidebar {
    margin-left: map-get($sidebar-left-margin, "default");
    flex-shrink: 0;
    flex-basis: map-get($sidebar-width, "default");
    max-width: map-get($sidebar-width, "default");
    @media (max-width: 1240px) {
      margin-left: map-get($sidebar-left-margin, 1240);
      flex-basis: map-get($sidebar-width, 1240);
      max-width: map-get($sidebar-width, 1240);
    }
    @media (max-width: 920px) {
      margin-left: map-get($sidebar-left-margin, 920);
      flex-basis: map-get($sidebar-width, 920);
      max-width: map-get($sidebar-width, 920);
    }
    @media (max-width: 767px) {
      order: 1;
      max-width: 100%;
      flex-basis: auto;
      margin: 0;
    }
  }