@import "resources/sass/variables.scss";
@import "../../styles/grid.module.scss";
@import "../../index.scss";

.options {
  position: relative;
  z-index: 15;
  background-color: $white;
  padding: 24px 0;

  h2 {
    font-family: $bold;
    text-align: center;
    font-size: 2rem;
    color: $navy;
    line-height: 2rem;
    

    @media (max-width: $sm) {
      font-size: 30px;
      line-height: 30px;
    }

    @media (max-width: 520px) {
      font-size: 25px;
      line-height: 25px;
    }
  }

  h3 {
    color: $navy;
    font-family: $bold;
    font-size: 30px;
    margin: 24px 0;

    @media (max-width: 520px) {
      text-align: center;
      font-size: 22px;
    }
  }

  h4 {
    color: $primary;
    font-family: $bold;
    font-size: 24px;
    padding: 6px 0;

    &.options__product__essentials::after {
      content: "Best Deal";
      color: $black;
      font-size: 16px;
      margin-left: 20px;
      padding: 4px 6px;
      background-color: $secondary;
      white-space: nowrap;

      @media (max-width: 520px) {
        display: none;
      }
    }

    &.options__product__essentials::before {
      display: none;
      content: "Best Deal";
      color: $black;
      font-size: 12px;
      width: fit-content;
      height: 12px;
      line-height: 12px;
      padding: 4px 6px;
      background-color: $secondary;
      white-space: nowrap;

      @media (max-width: 520px) {
        display: block;
      }
    }

    @media (max-width: 520px) {
      font-size: 20px;
    }

    @media (max-width: 400px) {
      font-size: 18px;
    }
  }

  p {
    font-family: $semibold;

    @media (max-width: 520px) {
      font-size: 16px;
    }

    @media (max-width: 400px) {
      font-size: 14px;
    }
  }

  hr {
    width: 65%;
    height: 3px;
    margin-top: 16px;
    background-color: $secondary;
    border: none;
  }

  &__product {
    padding-right: 12px;
  }

  div[role="button"] {
    background-color: $navy;
    color: $secondary;
    border-color: $navy !important;
    font-size: 24px;

    &:hover {
      background-color: $navyDark !important;
    }

    @media (max-width: 400px) {
      font-size: 18px;
      height: 36px;
    }
  }

  ol.indent {
    padding-left: 48px;

    & > li {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    @media (max-width: 520px) {
      padding-left: 0;
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
  }

  li.desktop {
    @media (max-width: 520px) {
      display: none;
    }
  }

  li.mobile {
    display: none;
    flex-direction: column;

    & > div.options__product {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
    }

    @media (max-width: 520px) {
      display: flex;
    }
  }
}

