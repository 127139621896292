@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.plan-selector-addons {
  display: flex;
  flex-direction: column;
  &__title {
    font-size: 22px;
    line-height: 30px;
    font-family: $semibold;
    color: $text;
    text-align: center;
    display: inline-block;
    position: relative;
    margin: 0 0 42px;
    @include no-user-select;
    span {
      position: relative;
      background-color: $page;
      padding: 0 20px;
      z-index: 1;
    }
    &::after {
      content: "";
      height: 2px;
      width: 100%;
      position: absolute;
      background-color: $greyXLight;
      top: 50%;
      left: 0;
      margin-top: -1px;
      z-index: 0;
    }
  }
  &__wrapper {
    background-color: $white;
    border: 1px solid $greyXLight;
    padding: 0 0 67px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-width: 0;
    text-align: center;
  }
  &__list {
    display: block;
    text-align: left;
  }
  &__item-heading {
    display: flex;
    align-items: center;
    height: 70px;
    background-color: $white;
    padding: 0 80px;
    border-bottom: 1px solid $greyXLight;
    font-size: 16px;
    line-height: 22px;
    font-family: $semibold;
    color: $text;
    .plan-selector-addons--disabled & {
      color: $disabledDark;
    }
    @media (max-width: 1150px) {
      padding: 0 60px;
    }
    &-addon {
      flex-grow: 1;
      padding-left: 47px;
      @include no-user-select;
    }
    &-add {
      width: 165px;
      text-align: center;
      margin-right: 50px;
      flex-shrink: 0;
      @include no-user-select;
      @media (max-width: 1150px) {
        margin-right: 8px;
      }
    }
    &-total {
      width: 135px;
      text-align: center;
      @include no-user-select;
      flex-shrink: 0;
    }
  }
}
