.rendered-medicaid-lead-documents {
  margin: 0;
  padding: 0;
}

.rendered-medicaid-lead-document {
  list-style-type: none;
  display: flex;

  &__title {
    margin-right: 16px;
  }
}