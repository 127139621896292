html {
  line-height: 1.25;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  height: 100%;
  font-family: $regular;
  color: $greyXXXDark;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  &.scroll-lock {
    overflow: hidden;
  }
}

body {
  height: 100%;
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  background-color: $primary;

  .scroll-lock & {
    overflow: hidden;
  }

  overflow-x: hidden;
}

#root {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  &.platform-mac {
    overflow: auto;
  }
}

// Uncomment to see what fields are masked for Clarity
/*
[data-clarity-mask="true"] {
  color: red !important;
  font-family: monospace, Courier !important;
}
*/