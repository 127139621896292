@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.button-radio {
  position: relative;
  height: 50px;
  padding: 0 32px;
  min-width: 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid $primary;
  background-color: inherit;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  margin: 0 4px 8px;
  transition: background-color 0.5s;
  @include no-user-select;
  &--icon {
    padding: 12px 15px 11px;
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 135px;
    height: auto;
    justify-content: center;
    border-radius: 4px;
    .button-radio__text {
      width: 100%;
      text-align: center;
      display: block;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    color: $text;
    font-family: $semibold;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    justify-content: center;
    position: relative;
    transition: color 0.5s;
    .button-radio--icon & {
      margin-top: 8px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    display: block;
    box-sizing: border-box;
    border: 1px solid transparent;
    top: -6px;
    left: -6px;
    transition: border-color 0.5s;
    @at-root {
      .button-radio--icon#{&} {
        border-radius: 8px;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    display: block;
    top: -2px;
    right: -2px;
    background-image: url("/static/images/form/icons/button-tick.svg");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    opacity: 0;
    @at-root {
      .button-radio--icon#{&} {
        top: -6px;
        right: -6px;
      }
    }
  }
  &--disabled {
    pointer-events: none;
    border-color: $disabled;
    @include no-user-select;
    .button-radio__text {
      color: $disabledXDark;
    }
  }
  &:focus {
    background-color: $primaryXLight;
    &::before {
      border-color: $primary;
    }
    &::after {
      border: 2px solid $white;
      top: -4px;
      right: -4px;
      @at-root {
        .button-radio--icon#{&} {
          top: -8px;
          right: -8px;
        }
      }
    }
    .button-radio__text {
      text-decoration: underline;
    }
  }
  :global(.platform-desktop) & {
    &:hover {
      background-color: $primaryXLight;
    }
  }
  &--selected {
    background-color: $primaryLight;
    &::after {
      opacity: 1;
    }
    &:focus {
      background-color: $primaryLight;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryLight;
      }
    }
  }
  &--invalid {
    border-color: $alert;
    &.button-radio--selected {
      background-color: $alertPastelLight;
    }
    &::after {
      content: none;
    }
    .button-radio__text {
      color: $alertXDark;
    }
    &:focus {
      background-color: $alertPastelLight;
      border-color: $alertDark;
      &::before {
        border-color: $alertDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $alertPastelLight;
        border-color: $alertDark;
      }
    }
  }
}
