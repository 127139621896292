@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.autocomplete {
  position: absolute;
  visibility: hidden;
  display: block;
  width: 100%;
  z-index: 3;
  top: 54px;
  left: 0;
  &--open {
    visibility: visible;
  }
  &--above {
    bottom: 54px;
    top: auto;
  }
  &__container {
    position: relative;
    max-height: 197px;
    overflow: hidden;
    border-color: $primary;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    background-color: $white;
    .autocomplete--open & {
      box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
    }
    .autocomplete--above & {
      box-shadow: 0px -2px 5px 2px rgba(0, 0, 0, 0.1);
    }
  }
  &__list {
    // Override styles of a confirm modal
    list-style: none !important;
    padding: 0 !important;
    margin: 0 !important;

    display: block;
    position: relative;
    max-height: 195px;
    top: -2px;
    margin-bottom: -2px;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    scrollbar-color: $primary $primaryXLight;
    scrollbar-width: thin;
    :global(.platform-desktop) & {
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 8px;
        margin-right: 10px;
      }
      &::-webkit-scrollbar-track-piece {
        background-color: $primaryXLight;
      }
      &::-webkit-scrollbar-thumb:vertical {
        background-color: $primary;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-clip: padding-box;
      }
    }

    &-item {
      font-family: $regular;
      color: $text;
      width: 100%;
      background-color: $white;
      border-top: 1px solid $primary;
      box-sizing: border-box;
      cursor: pointer;
      @include no-user-select;
      transition: background-color 0.075s;
      position: relative;
      &:first-child {
        border-color: transparent;
      }
      &::after {
        content: "";
        position: absolute;
        background-image: url("/static/images/form/icons/input-valid.svg");
        background-size: cover;
        background-repeat: no-repeat;
        right: 20px;
        top: 50%;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        opacity: 0;
      }
      :global(.platform-desktop) & {
        &:global(.Mui-focused),
        &[data-hover="true"] {
          background-color: $primaryXLight;
        }
        &[aria-selected="true"] {
          color: $successDark;
          font-family: $bold;
          &::after {
            opacity: 1;
          }
        }
      }
      &[data-focus="true"] {
        background-color: $primaryXLight;
      }
      &:active {
        background-color: $greyXLight;
      }
      &[aria-selected="true"] {
        color: $successDark;
        font-family: $bold;
        &::after {
          opacity: 1;
        }
      }
      .autocomplete--small & {
        font-size: 16px;
        line-height: 20px;
        padding: 9px 14px;
      }
      .autocomplete--large & {
        font-size: 18px;
        line-height: 22px;
        padding: 8px 14px;
      }
    }
  }
}
