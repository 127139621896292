@import "resources/sass/variables.scss";
@import "../../styles/grid.module.scss";
@import "../../index.scss";

.legal-needs {
  background-color: $secondary;
  padding: 24px 0;

  @media (max-width: $lg) {
    &.national {
      padding-bottom: 150px;
    }
    
  }

  @media (max-width: 520px) {
    &.national {
      padding-bottom: 75px;
    }

    
    & > div {
      padding-left: 0;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $lg) {
      flex-direction: column;
      align-items: center;
    }
    
  }

  &__info {
    display: inline-block;
    flex: 1;
    
    @media (max-width: $lg) {
      max-width: 600px;
    }
  }

  @media (max-width: $lg) {
    text-align: center;
  }

  h2 {
    font-family: $bold;
    color: $navy;
    margin-bottom: 5px;
  }

  p {
    color: $primary;
    font-family: $semibold;
    margin: 20px 0;
  }

  &__map-container {
    position: relative;
    flex: 1;

    @media (max-width: $lg) {
      width: 500px;
    }

    @media (max-width: 520px) {
      width: 300px;
    }
  }

  &__map-container svg {
    left: unset;
    right: 0;
    height: auto;
    width: 500px;
    pointer-events: none;

    & path {
      fill: $primary;
    }

    @media (max-width: 520px) {
      width: 300px;
    }
  }

  &__partner-details-container {
    display: flex;
    padding-left: 24px;

    @media (max-width: 520px) {
      padding-left: 0;
    }
  }

  &__partner-details {
    display: flex;
    flex-direction: column;
    background-color: $navy;
    border-radius: 4px;
    padding: 16px 48px;

    @media (max-width: 520px) {
      padding: 12px 36px;
    }

    @media (max-width: 380px) {
      padding: 6px 18px;
    }

    &__heading {
      display: flex;
      flex-direction: column;
      width: 100%;

      h3 {
        color: $secondary;
        font-family: $bold;
        font-size: 26px;

        @media (max-width: 520px) {
          font-size: 20px;
        }
      }
  
      h4 {
        color: $secondary;
        font-family: $semibold;
        font-size: 20px;

        @media (max-width: 520px) {
          font-size: 16px;
        }
      }
    }

    &__body {
      display: flex;
      align-items: center;

      p, a {
        color: $white;
        margin: 8px 0;
        font-family: $semibold;

        @media (max-width: 520px) {
          margin: 0;
          font-size: 14px;
        }
      }
    }

    svg {
      width: 150px;
      height: auto;
      margin-left: -50px;

      polygon {
        fill: $primary;
      }

      @media (max-width: 380px) {
        width: 100px;
        margin-left: -20px;
      }
    }
  }
}