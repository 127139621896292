@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.coupled-workflows {
  padding: 16px;
  background-color: $tertiaryDark;
  border: 1px solid $tertiaryXDark;
  margin: 0 0 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  > div {
    background-color: $tertiary;
  }
}

.client-dashboard-products {
  &__actions {
    margin-top: 24px;
    margin-bottom: 24px;
    @media (max-width: 374px) {
      padding: 0 20px;
    }
    div[role="button"] {
      @media (max-width: 1024px) {
        width: 100%;
        height: 45px;
      }
    }
  }
  &__empty {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0px;
    margin-top: 24px;
    padding: 32px;
    background-color: $tertiary;
    border: 1px solid $tertiaryXDark;
    border-radius: 0px 8px 8px 0px;
    @media (max-width: 1240px) {
      border-radius: 0;
    }
    @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
      padding: 24px;
      margin: 0 -25px;
    }
    div[role="button"] {
      @media (max-width: 1240px) {
        width: 100%;
      }
    }
    &::before {
      content: "";
      background-color: $primary;
      width: 3px;
      height: calc(100% + 2px);
      position: absolute;
      top: -1px;
      left: -1px;
      display: block;
    }
    &__text {
      font-size: 18px;
      line-height: 25px;
      font-family: $bold;
      color: $text;
      margin: 0 0 18px;
      @include no-user-select;
      @media (max-width: 1240px) and (min-width: 921px), (max-width: 767px) and (min-width: 375px) {
        font-size: 17px;
        line-height: 24px;
      }
      @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  &__partner-inactive {
    p {
      font-size: 18px;
      padding-bottom: 12pt;
    }
  }
}
