.information-box {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 120px;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    margin-bottom: 10px;
    text-decoration: underline;
    font-weight: bold;
  }

  .information-box-info {
    font-size: 22px;
  }
}