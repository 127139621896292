@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.select {
  position: relative;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  width: auto;
  height: auto;
  width: 100%;
  &::before,
  &::after {
    content: "";
    display: block;
    z-index: 1;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 13px;
    right: 15px;
    pointer-events: none;
  }
  &::after {
    background-image: url("/static/images/form/icons/chevron.svg");
  }
  &::before {
    z-index: 2;
    visibility: hidden;
    background-image: url("/static/images/form/icons/chevron-invalid-hover.svg");
  }
  select {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 0px 50px 0px 18px;
    margin: 0px 0px 0px 0px;
    width: 100%;
    height: 50px;
    font-family: $regular;
    color: $text;
    cursor: pointer;
    font-size: 18px;
    line-height: normal;
    font-weight: normal;
    letter-spacing: normal;
    background: white;
    border: 1px solid $grey;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    @media (max-width: 920px) {
      font-size: 16px;
    }
    &::-ms-clear {
      display: none;
      width: 0px;
      height: 0px;
    }
    &::-ms-reveal {
      display: none;
      width: 0px;
      height: 0px;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
    &:-webkit-autofill {
      background: white;
      -webkit-box-shadow: 0px 0px 0px 20px white inset !important;
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: white;
    }
    &:-moz-focusring,
    &::-moz-focus-inner {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important;
      background-image: none !important;
    }
    &::-ms-expand {
      display: none;
    }
    &:invalid {
      box-shadow: none;
    }
    &:focus {
      background-color: $white;
      border-color: $primary;
      &::-ms-value {
        color: $text;
        background-color: transparent;
      }
    }
    &:disabled {
      border-color: $disabled !important;
      color: $disabled !important;
      pointer-events: none;
      opacity: 1;
      @include no-user-select;
      &::-ms-value {
        color: $disabled !important;
      }
    }
  }
  &--disabled {
    pointer-events: none;
    &::after {
      background-image: url("/static/images/form/icons/chevron-disabled.svg") !important;
    }
  }
  :global(.platform-desktop) & {
    select {
      &:hover {
        border-color: $greyDark;
        &:focus {
          border-color: $primary;
        }
      }
    }
  }
  &--placeholder {
    :global(.platform-desktop) & {
      select {
        &:hover {
          color: $greyXDark;
          &:focus {
            border-color: $primary;
          }
        }
      }
    }
    select {
      color: $grey;
      &:focus {
        color: $greyXDark;
        &::-ms-value {
          color: $greyXDark;
        }
      }
    }
  }
  &--valid {
    &.select--placeholder {
      select {
        padding-right: 73px;
      }
      &::after {
        right: 14px;
      }
      &::before {
        content: none;
      }
    }
    select {
      padding: 0px 73px 0px 18px;
    }
    &::after {
      right: 39px;
    }
    &::before {
      content: "";
      position: absolute;
      visibility: visible;
      background-image: url("/static/images/form/icons/input-valid.svg");
      background-size: cover;
      background-repeat: no-repeat;
      right: 20px;
      top: 17px;
      width: 16px;
      height: 16px;
    }
  }
  &--invalid {
    &::after {
      background-image: url("/static/images/form/icons/chevron-invalid.svg");
    }
    &.select--placeholder {
      select {
        color: $alertPastel;
        &:focus {
          color: $alertPastelDark;
          &::-ms-value {
            color: $alertPastelDark;
          }
        }
      }
    }
    select {
      border: 2px solid $alert;
      color: $alert;
      &:focus {
        color: $alert;
        border-color: $alertDark;
        &::-ms-value {
          color: $alert;
        }
      }
    }
  }
  :global(.platform-desktop) & {
    &.select--invalid {
      &.select--placeholder {
        &:hover {
          &::before {
            visibility: visible;
          }
        }
        select {
          color: $alertPastel;
          &:focus {
            color: $alertPastelDark;
            &::-ms-value {
              color: $alertPastelDark;
            }
          }
          &:hover {
            color: $alertPastelDark;
          }
        }
      }
      select {
        &:hover {
          border-color: $alertDark;
          color: $alertDark;
        }
        &:focus {
          border-color: $alertDark;
          &::-ms-value {
            color: $alertDark;
          }
        }
      }
    }
  }
  &--loading {
    select {
      padding-right: 55px;
    }
    &::before {
      display: none;
    }
    &::after {
      z-index: 2;
      content: "";
      position: absolute;
      right: 17px;
      top: 13px;
      width: 24px;
      height: 24px;
      visibility: visible;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 50%;
      background-image: url("/static/images/form/icons/spinner.gif") !important;
    }
    &.select--disabled {
      &::after {
        background-image: url("/static/images/form/icons/spinner-disabled.gif") !important;
      }
    }
    &.select--invalid {
      &::after {
        background-image: url("/static/images/form/icons/spinner-invalid.gif") !important;
      }
      :global(.platform-desktop) & {
        &:hover {
          &::after {
            background-image: url("/static/images/form/icons/spinner-invalid-hover.gif") !important;
          }
        }
      }
    }
  }
  :global(.platform-android) & {
    select {
      padding-top: 1px;
    }
  }
}
