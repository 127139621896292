@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.modal-page {
  display: block;
  box-sizing: border-box;
  padding: 60px 40px 40px 40px;
  will-change: transform;
  background-color: rgba(255,255,255,0.9);
  backdrop-filter: blur(4px);
  border-radius: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  @media (max-width: 500px) {
    padding: 20px;
  }

  @media (max-width: 375px) {
    padding: 0;
  }

  > section {
    display: flex;
    max-width: 1200px;
    width: 100%;
    justify-content: center;
  }

  .icon-link {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 5px;

      > * {
        fill: $primary;
      }
    }
  }

  &__top-actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 24px;
  }
  
  &__header {
    padding: 24px 24px 0px 24px;
    @media (max-width: 767px) {
      padding: 16px 16px 0 16px;
    }
    .modal-page-warning & {
      padding: 22px 22px 0px 22px;
      @media (max-width: 767px) {
        padding: 20px 16px 0 16px;
      }
    }
  }
  &__title {
    font-family: $title;
    font-size: 28px;
    line-height: 38px;
    color: $text;
    margin: -8px 0px 0px 0px;
    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
      margin-top: -5px;
    }
    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 26px;
      margin-top: -3px;
    }
    .modal-page-warning & {
      color: $alert;
    }
  }
  &__content {
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      color: $text;
      font-family: $bold;
      margin: 0 0 16px;
    }
    blockquote {
      color: $text;
      font-family: $regular;
      font-style: italic;
      margin: 0 0 16px;
    }
    a {
      color: $primary;
      text-decoration: underline;
      :global(.platform-desktop) & {
        &:hover {
          text-decoration: none;
        }
      }
    }
    ul,
    ol {
      margin: 0 0 16px;
      list-style-position: outside;
      padding-left: 18px;
    }
    ul {
      list-style: disc;
    }
    ol {
      list-style: decimal;
    }
    li {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 19px;
      }
      color: $text;
    }
    > p {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      color: $text;
      margin: 0 0 16px;
      max-width: 450px;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 19px;
      }
    }
    > *:last-child {
      margin-bottom: 9px;
    }
    > form > div > div > fieldset {
      border-left: none;
      border-right: none;
      padding-left: 0px;
      padding-right: 0px;
      &:first-child {
        border-top: none;
        padding-top: 0px;
      }
      &:last-of-type {
        border-bottom: none;
        padding-bottom: 4px;
      }
      > div > div {
        flex-basis: calc(100% - 8px);
        max-width: calc(100% - 8px);
        input {
          padding-right: 50px;
        }
      }
    }
  }
}
