.overview {
  &__buttons {
    text-align: right;
    > * {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.__react_component_tooltip {
  font-size: 16pt!important;
}
