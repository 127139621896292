@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.addon-with-choices {
  display: flex;
  margin: 0 -8px;
  @media (max-width: 920px) {
    flex-direction: column;
    margin: 0;
  }
  &__item {
    background-color: $white;
    border: 1px solid $greyXLight;
    border-radius: 4px;
    margin: 0 8px;
    padding: 30px 24px 24px;
    flex-basis: 50%;
    max-width: 50%;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    @media (max-width: 920px) {
      flex-basis: 100%;
      max-width: 100%;
      margin: 0 0 8px;
      &:last-child {
        margin: 0;
      }
    }
    &--recommended {
      border: 2px solid $primary;
      position: relative;
      &::after {
        display: block;
        content: "Strongly Recommended";
        font-family: $bold;
        font-size: 14px;
        line-height: 19px;
        color: $white;
        width: 190px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: 1;
        background-color: $primary;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        @media (max-width: 1240px) {
          width: 184px;
          height: 28px;
        }
      }
    }
    > div:first-child {
      display: flex;
      margin: 0 0 15px;
      > svg {
        flex-shrink: 0;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 0 16px;
        > h3 {
          font-family: $bold;
          font-size: 24px;
          line-height: 33px;
          color: $primaryXDark;
          @media (max-width: 1240px) {
            font-size: 22px;
            line-height: 28px;
            margin: 2px 0 0;
          }
        }
        > p {
          font-size: 16px;
          line-height: 22px;
          color: $primaryXDark;
          opacity: 0.5;
          font-family: $semibold;
        }
      }
    }
    > p {
      font-size: 15px;
      line-height: 24px;
      color: $primaryXDark;
      opacity: 0.7;
      margin: 0 0 24px;
    }
    > ul {
      margin: 0 -24px 24px;
      border-top: 1px solid $greyXLight;
      > li {
        padding: 12px 24px;
        border-bottom: 1px solid $greyXLight;
        background: $white;
        font-size: 16px;
        line-height: 22px;
        font-family: $semibold;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          height: 20px;
          width: 20px;
          position: relative;
          background-image: url("/static/images/icons/tick.svg");
          background-repeat: no-repeat;
          background-size: cover;
          margin: 0 12px 0 0;
          flex-shrink: 0;
        }
        &:nth-child(odd) {
          background-color: $tertiary;
        }
      }
    }
    &__actions {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      
      > *:not(:first-child) {
        margin-top: 10px;
      }
    }
    &__warning {
      margin-bottom: 24px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
