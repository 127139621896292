@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.selector-feature-list {
  flex-grow: 1;
  margin: 0 -16px 24px;
  overflow: hidden;
  position: relative;
  border-top: 1px solid $greyXLight;
  @media (max-width: 767px) {
    margin: 0 -24px 24px;
  }
}

.selector-feature {
  position: relative;
  display: flex;
  background-color: $white;
  border-bottom: 1px solid $greyXLight;
  align-items: center;
  padding: 17px 56px 17px 54px;
  box-sizing: border-box;
  @include no-user-select;
  z-index: 1;
  @media (max-width: 767px) {
    padding: 17px 49px 17px 46px;
  }
  &:nth-child(odd) {
    background-color: $tertiary;
  }
  .selector-feature-list--recommended & {
    width: calc(100% - 2px);
    left: 1px;
    padding: 17px 56px 17px 54px;
    @media (max-width: 767px) {
      padding: 17px 48px 17px 45px;
    }
  }
  &__title {
    z-index: 1;
    font-size: 18px;
    line-height: 25px;
    font-family: $semibold;
    color: $text;
    @media (max-width: 920px) {
      font-size: 16px;
      line-height: 22px;
    }
    .selector-feature-list--disabled & {
      color: $disabledDark;
    }
  }
  &__note {
    color: $greyXXXDark;
    font-size: smaller;
    font-family: $regular;
  }
  &__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -12px;
    height: 24px;
    width: 24px;
    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
      margin-top: -10px;
    }
    .selector-feature-list--disabled & {
      pointer-events: none;
    }
    &--tick .selector-feature-list--recommended & {
      path {
        fill: $white;
        stroke: $white;
      }
      circle {
        fill: $success;
      }
    }
    .selector-feature-list--disabled & {
      path {
        fill: $disabled;
        stroke: $disabled;
      }
      circle {
        stroke: $disabled;
      }
    }
  }
  &__tooltip {
    height: 24px;
    width: 24px;
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -12px;
    z-index: 1;
    cursor: pointer;
    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
      margin-top: -10px;
    }
    .selector-feature-list--recommended & {
      left: 14px;
    }
    .selector-feature-list--disabled & {
      pointer-events: none;
      *:nth-child(2),
      *:nth-child(3) {
        fill: $disabled;
      }
      *:nth-child(1) {
        fill: transparent;
      }
    }
    *:nth-child(2),
    *:nth-child(3) {
      fill: $primary;
      transition: all 0.5s;
    }
    *:nth-child(1) {
      fill: transparent;
      transition: all 0.5s;
    }
    &:focus {
      *:nth-child(2),
      *:nth-child(3) {
        fill: $primaryXDark;
      }
      *:nth-child(1) {
        fill: transparent;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        *:nth-child(2),
        *:nth-child(3) {
          fill: $primaryXDark;
        }
        *:nth-child(1) {
          fill: transparent;
        }
      }
    }
  }
}
