@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.page-header-wrapper {
  max-width: calc(100% - #{map-get($sidebar-left-margin, "default") + map-get($sidebar-width, "default")});
  @media (max-width: 1240px) {
    max-width: calc(100% - #{map-get($sidebar-left-margin, 1240) + map-get($sidebar-width, 1240)});
  }
  @media (max-width: 920px) {
    max-width: calc(100% - #{map-get($sidebar-left-margin, 920) + map-get($sidebar-width, 920)});
  }
  @media (max-width: 767px) {
    max-width: initial;
  }
}

.page-header {
  display: block;
  margin-bottom: 32px;
  @media (max-width: 1240px) {
    margin-bottom: 24px;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  &__title {
    color: $primary;
    font-family: $title;
    font-size: 40px;
    line-height: 44px;
    font-weight: normal;
    @include no-user-select;
    margin: 0 0 9px;
    @media (max-width: 1240px) {
      margin: 0 0 5px;
      font-size: 36px;
      line-height: 40px;
    }
    @media (max-width: 1024px) {
      font-size: 32px;
      line-height: 36px;
    }
    @media (max-width: 920px) {
      font-size: 30px;
      line-height: 34px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 30px;
      margin: 0 0 -1px;
    }
    &:first-child {
      margin-top: -6px;
    }
    &:last-child {
      margin-bottom: -9px;
      @media (max-width: 767px) {
        margin-bottom: -8px;
      }
    }
    span {
      display: block;
      font-size: 28px;
      line-height: 36px;
      color: $text;
      font-family: $semibold;
      margin: 3px 0 0;
    }
  }
  &__description {
    color: rgba($text, 0.7);
    font-family: $regular;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 10px;
    @media (max-width: 1240px) {
      font-size: 17px;
      line-height: 25px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 23px;
    }
    &:first-child {
      margin-top: -6px;
    }
    &:last-child {
      margin-bottom: -7px;
    }
  }
}

.page-actions {
  display: flex;
  margin-bottom: 8px;
}
