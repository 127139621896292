@import "resources/sass/variables.scss";
@import "resources/sass/mixins.scss";

.footer-nav {
  display: block;
  height: 100%;
  &__list {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 22px;
    }

    @media (max-width: 374px) {
      margin-bottom: 14px;
    }
    > li {
      @media (max-width: 767px) {
        margin: 0 0 21px;
      }
    }
  }
  &__link {
    color: $white;
    font-family: $regular;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0;
    margin: 0 21px 0 0;
    background-color: transparent;
    transition: all ease-out 0.075s;
    cursor: pointer;
    @include no-user-select;
    @media (max-width: 920px) and (min-width: 768px), (max-width: 374px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      margin: 0;
    }
    :global(.platform-mobile) & {
      transition: none;
    }
    &:focus {
      color: darken($white, 7.5%);
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: darken($white, 7.5%);
        text-decoration: underline;
      }
      &:active {
        color: darken($white, 15%);
        text-decoration: none;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: darken($white, 15%);
        text-decoration: none;
      }
    }
  }

  &__item.divider {
    border-right: solid 1px $white;
  }
}
