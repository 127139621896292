@import "resources/sass/variables.scss";

.header-account {
  display: flex;
  margin: 0;
  height: 100%;
  &__button {
    font-size: 18px;
    line-height: 22px;
    color: $white;
    font-family: $regular;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: visible;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 8px;
    background-color: transparent;
    transition: all ease-out 0.075s;
    :global(.platform-mobile) & {
      transition: none;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 4px;
      background-color: transparent;
      transition: all ease-out 0.075s;
      :global(.platform-mobile) & {
        transition: none;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 50px;
      top: 9px;
      left: 0;
      right: 0;
      pointer-events: none;
      border: 2px solid transparent;
      box-sizing: border-box;
      transition: all ease-out 0.075s;
      :global(.platform-mobile) & {
        transition: none;
      }
    }
    &:focus {
      &::after {
        border: 2px solid $primaryLight;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryDark;
        &::before {
          background-color: $primaryLight;
        }
        &:after {
          content: none;
        }
      }
      &:active {
        background-color: $primaryDark;
        color: $primaryLight;
        path {
          fill: $primaryLight;
        }
        &::after {
          content: none;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $primaryDark;
        color: $primaryLight;
        path {
          fill: $primaryLight;
        }
        &::after {
          content: none;
        }
      }
    }
  }
  &__name {
    display: block;
    padding-right: 10px;
    pointer-events: none;
  }
  &__avatar {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    display: block;
    background-image: url("/static/images/avatar/placeholder-avatar2.svg");
    background-repeat: no-repeat;
    pointer-events: none;
  }
}
