@import "resources/sass/variables.scss";

.landing-screen {
  width: 100%;
  overflow: visible;
  line-height: 27px;
  font-size: 18px;
  color: $black;
}

.your-attorney-section {
  background-color: $grey;
}

.getting-started-section {
  background-color: $white;
}

.documents-section {
  background-color: $grey;
}

.guide-you-section {
  background-color: $primary;
}

.options-section {
  background-color: $grey;
}